import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select, TextField,
    Typography, InputAdornment,
    Stack,
    Chip,
    Card,
    FormControl,
    OutlinedInput,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Backdrop,
    Menu,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { completeTask, deleteTaskbyId, getDependentTaskByTaskIdAndProjectId, getDependentTaskNames, getTasksByProjectId, reassignTeamMember } from '../../services/TaskService';
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from "@mui/icons-material/Search";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, DateTimePicker, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import { getRecentStateChangeHistory, projectReschedulewithDate, taskReschedule, updateProjectByProjectId } from '../../services/ProjectService';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { getProjectMembers } from '../../services/ProjectMembersService';
import { getResourceByDateRange } from '../../services/ResourceService';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useHandleUnauthorized, UserContext } from '../../hooks/UserSession';
import { useContext } from 'react';
import { getConfiguredDataByKey } from '../../services/TradeConfigurationService';
import { convertUTCToLocalTime, weekdays } from '../../utils/Dateutils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddTaskDialog from '../bidDetails/AddTaskDialog';
import CompleteWarningDialog from './CompleteWarningDialog';
import { gpsTaskTypes, invoiceTaskGroup, nonDeletableTasks, poTaskGroup } from '../../utils/TaskConstants';
import EstimateWarning from '../bidDetails/EstimateWarning';
import ErrorWarning from '../bidDetails/ErrorWarning';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TaskWarningDialog from './TaskWarningDialog';
import CompleteTaskWarning from './CompleteTaskWarning';
import ProfitMarginSettings from '../../pages/ProfitMarginSettings';


interface Data {
    id: number;
    taskid: number;
    taskname: string;
    status: string;
    start: string;
    end: string;
    duration: number;
    resource: string;
}

function createData(
    id: number,
    taskid: number,
    taskname: string,
    status: string,
    start: string,
    end: string,
    duration: number,
    resource: string,
): Data {
    return {
        id,
        taskid,
        taskname,
        status,
        start,
        end,
        duration,
        resource
    };
}
const rows = [
    createData(1, 0, '', '', '', '', 0, ''),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'taskid',
        numeric: false,
        disablePadding: true,
        label: 'Task Id',
    },
    {
        id: 'taskname',
        numeric: true,
        disablePadding: false,
        label: 'Task Name',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'start',
        numeric: true,
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: 'end',
        numeric: true,
        disablePadding: false,
        label: 'End Date',
    },
    {
        id: 'duration',
        numeric: true,
        disablePadding: false,
        label: 'Duration',
    },
    {
        id: 'resource',
        numeric: true,
        disablePadding: false,
        label: 'Resource',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    role: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, role } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        {/* <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        /> */}
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    {role === "admin" && <TableCell >action</TableCell>}
                    {/* <TableCell className='tablerows '>ACTION</TableCell> */}
                </TableRow>
            </TableHead>
        </>
    );
}

const TaskList: React.FC<any> = (props) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('taskid');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [taskList, setTaskList] = React.useState([] as any[]);
    const [memberList, setMemberList] = React.useState<any>([] as any[]);
    const [resourceList, setResourceList] = React.useState<any>([] as any[]);
    const [deletedIndex, setDeletedIndex] = React.useState<any>([]);
    const [updatedData, setUpdatedData] = React.useState<any>({
        'projectId': props.projectId,
        'projectMembers': [],
        'tasks': []
    });
    const [warning, setWarning] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [teamMemberData, setTeamMemberData] = React.useState<any>();
    const [memberIndex, setMemberIndex] = React.useState<any>();
    const taskStatus = ['active', 'scheduled'];
    const [editedTask, setEditedTask] = React.useState<any>();
    const [isEndDate, setIsEndDate] = React.useState<any>(false);
    const [isChanged, setIsChanged] = React.useState<any>(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openHoldDialog, setOpenHoldDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [holdSuccess, setHoldSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [deleteWarning, setDeleteWarning] = React.useState(false);
    const [role, setRole] = React.useState<any>();
    const { userSessionDetails } = useContext(UserContext);
    const admin = "admin";
    const [workingDays, setWorkingDays] = React.useState<any>([]);
    const [startTime, setStartTime] = React.useState<any>();
    const [endTime, setEndTime] = React.useState<any>();
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [openRow, setOpenRow] = React.useState<string | null>(null);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const [taskId, setTaskId] = React.useState<any>();
    const [phase, setPhase] = React.useState<any>();
    const [tasks, setTasks] = React.useState<any>();
    const [editView, setEditView] = React.useState<any>(false);
    const [wbs, setWbs] = React.useState<any>();
    const [openTaskEditorDialogs, setOpenTaskEditorDialogs] = React.useState(false);
    const [openDependenciesView, setOpenDependenciesView] = React.useState(false);
    const [dependentTask, setDependentTask] = React.useState<any>();
    const [deletedTaskData, setDeletedTaskData] = React.useState<any>();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState(false);
    const awaitingEstimation = "awaitingEstimation";
    const contractApproval = "Contract Approval";
    const projectActive = "Project Active";
    const onHold = "On Hold";
    const [openCalendar, setOpenCalendar] = React.useState<any>(false);
    const [selectedDate, setSelectedDate] = React.useState<any>();
    const [openReasonDialog, setOpenReasonDialog] = React.useState(false);
    const [openCompleteWarningDialog, setOpenCompleteWarningDialog] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [statusHistory, setStatusHistory] = React.useState<any>();
    const [taskNames, setTaskNames] = React.useState<any>([]);
    const [groupTasks, setGroupTasks] = React.useState<any>([]);
    const [openEstimateWarningDialogs, setOpenEstimateWarningDialogs] = React.useState(false);
    const [openErrorWarningDialogs, setOpenErrorWarningDialogs] = React.useState(false);
    const [isGroupTask, setIsGroupTask] = React.useState<any>(false);
    const [pendingTasks, setPendingTasks] = React.useState<any>([]);
    const [progressingTasks, setProgressingTasks] = React.useState<any>([]);
    const [validEndDate, setValidEndDate] = React.useState<any>(false);
    const [showTaskWarning, setShowTaskWarning] = React.useState(false);
    const [completeTaskWarning, setCompleteTaskWarning] = React.useState(false);
    let [taskNamesList, setTaskNamesList] = React.useState<any>();
    const { handleUnauthorized } = useHandleUnauthorized();
    const [openSuccessDialog, setOpenSuccessDialog] = React.useState<any>(false);
    const [createTaskSuccess, setCreateTaskSuccess] = React.useState(false);
    const [createTaskError, setCreateTaskError] = React.useState(false);
    const [showCompleteTaskWarning, setShowCompleteTaskWarning] = React.useState(false);
    const [skipTaskData, setSkipTaskData] = React.useState<any>();
    // const completedProjectStatus = ["Project Complete", "Project Closed", "Construction Complete", "Project Active", "On Hold"];

    const handleCloseErrorDialog = () => {
        setOpenErrorWarningDialogs(false);
        setPendingTasks([]);
    }

    const showWarning = (data: any, memberListIndex: any) => {
        setDeleteWarning(true);
        setTeamMemberData(data);
        setMemberIndex(memberListIndex);
    }
    const removeResource = () => {
        let memberListArray = [...memberList];
        memberListArray.splice(memberIndex, 1);
        setMemberList(memberListArray);
        (updatedData.projectMembers).push(teamMemberData);
        let taskListData = [...taskList];
        taskListData.map(tasksData => {
            (tasksData.tasks).map((dataList, taskIndex) => {
                let arr = tasksData.tasks[taskIndex].resource;
                let assignees = tasksData.tasks[taskIndex].assignees;
                let number = arr.findIndex(obj => obj.id === teamMemberData.resourceId)
                if (dataList?.status === "scheduled") {
                    if (number >= 0) {
                        assignees.splice(assignees.indexOf(arr[number].resourceUid), 1);
                        arr.splice(number, 1);
                        deletedIndex.push(dataList?.taskId);
                    }
                }
            }
            )
        })
        setTaskList(taskListData);
        onCloseDialog();
    }

    const getProjectMembersByProjectId = async () => {
        let result = await getProjectMembers(props?.projectId, accessToken);
        handleUnauthorized(result);
        setMemberList(result?.data);
    }
    const handleChange = (event: any, index: any, row: any) => {
        deletedIndex.splice(deletedIndex.indexOf(row?.taskId), 1);
        const {
            target: { value },
        } = event;

        let taskArray = [...taskList];
        let newArr: any = []
        taskArray.map(tasks => {
            if (tasks?.phase === row?.phase) {
                newArr = tasks?.tasks;
            }
        }
        );
        let difference = [];

        if (value.length > newArr[index]["resource"].length) {
            difference = value.filter((element: any) => !newArr[index]["resource"].includes(element));
        } else {
            difference = newArr[index]["resource"].filter((element: any) => !value.includes(element));
        }
        const taskIndex = (updatedData.tasks).findIndex((value: any) => value.id === newArr[index]['id']);

        let task = Object.assign({}, newArr[index]);

        if (taskIndex >= 0) {
            if ((updatedData.tasks)[taskIndex]["resource"].includes(difference[0]) || task['assignees'].includes(difference[0]["resourceUid"])) {
                (updatedData.tasks)[taskIndex]["resource"].splice((updatedData.tasks)[taskIndex]["resource"].indexOf(difference), 1);
                task['assignees'].splice(task['assignees'].indexOf(difference[0]["resourceUid"]), 1);
                if ((updatedData.tasks)[taskIndex]["resource"].length === 0) {
                    (updatedData.tasks).splice(taskIndex, 1);
                }
            } else {
                (updatedData.tasks)[taskIndex]["resource"].push(difference[0]);
            }
        } else {
            if (difference.length !== 0 && !task['assignees'].includes(difference[0]["resourceUid"])) {
                task["resource"] = difference;
                task['assignees'].push(difference[0]["resourceUid"]);
                (updatedData.tasks).push(task);
            } else {
                task["assignees"].splice(task["assignees"].indexOf(difference[0]["resourceUid"]), 1);
            }
        }

        newArr[index]["resource"] = value;
        taskArray.map(tasks => {
            if (tasks?.phase === row?.phase) {
                tasks['tasks'] = newArr;
            }
        }
        );
        setTaskList(taskArray);
    };
    const handleOpen = async (e: any, index: any, row: any) => {
        let result = await getResourceByDateRange(row.startTime, row.endTime, props.projectId, accessToken);
        setResourceList(result.data);
    }
    const onCloseDialog = () => {
        setDeleteWarning(false);
    }
    const fetData = async () => {
        const response = await getTasksByProjectId(props.projectId, accessToken);
        handleUnauthorized(response);
        if (response.status === 200 || response.status === 201) {
            if (editedTask !== undefined) {
                setEditedTask(undefined);
                setSelectedRowId(undefined);
                setIsLoading(false);
                setWarning(false);
                setOpenDialog(true);
                setSuccess(true);
            }
            setTaskList(response?.data);
            console.log(response?.data);
            response.data.map((parentTask: any) => {
                parentTask.tasks.map((childTask: any) => {
                    if (childTask.status === "completed" || childTask.status === "active") {
                        progressingTasks.push(childTask.taskId);
                    }
                })
            })
        }
    }

    const workSchedule = async () => {
        const result = await getConfiguredDataByKey("workingHours", accessToken);
        handleUnauthorized(result);

        if (result.data !== undefined && (result.status === 200 || result.status === 201)) {
            var object = JSON.parse(result.data);
            setStartTime(dayjs(object.startTime, 'HH:mm'));
            setEndTime(dayjs(object.endTime, 'HH:mm'));
        }
        const response = await getConfiguredDataByKey("workingDays", accessToken);
        handleUnauthorized(response);

        if (response.data !== undefined && (response.status === 200 || response.status === 201)) {
            let tenantWokringDays = response.data.split(",");
            weekdays.map((day: any) => {
                if (tenantWokringDays.includes(day)) {
                    workingDays.push(weekdays.indexOf(day));
                }
            })
            //setWorkingDays(response.data.split(",").map((num:any) => parseInt(num)));
        }
    }

    const getDependentTask = async (row: any) => {
        let result = await getDependentTaskByTaskIdAndProjectId(row?.project, row?.taskId, accessToken);
        handleUnauthorized(result);

        if ((result.data).length !== 0) {
            setAnchorEl(null);
            setDeletedTaskData(row);
            setDependentTask(result?.data);
            setOpenDependenciesView(true);
        }
        else {
            setAnchorEl(null);
            deleteTaskById(row);
        }
    }
    const deleteTaskById = async (row: any) => {
        setOpenDependenciesView(false);
        setIsLoading(true);
        let result = await deleteTaskbyId(row, accessToken);
        if (result.status === 200 || result.status === 201) {
            setIsLoading(false);
            setDeleteSuccess(true);
            setOpenDeleteDialog(true);
            setOpenDependenciesView(false)
            fetData();
        }
        else {
            setIsLoading(false);
            setOpenDeleteDialog(true);
            setDeleteError(true);
        }
    }

    const getProjectInformationData = async (name: any) => {
        let reason = await getRecentStateChangeHistory(props.projectId, accessToken);
        handleUnauthorized(reason);

        if (reason.status === 200 || reason.status === 201) {
            setStatusHistory(reason.data);
        }
    }

    const checkDependentTaskCompleted = async (projectId: any, dependencies: any) => {
        let result = await getDependentTaskNames(projectId, dependencies.split(";"), accessToken);
        if (result.status === 200 || result.status === 201) {
            if ((result.data).length !== 0) {
                setTaskNamesList(result?.data);
                setShowTaskWarning(true);
                return false;
            }
            else {
                setShowTaskWarning(false);
                setCompleteTaskWarning(false);
                return true;
            }
        }
    }
    const skipTask = async (row: any) => {
        setSkipTaskData(row);
        if (row?.project != null) {
            if (row?.dependencies !== "") {
                let isPreviousTaskCompleted = await checkDependentTaskCompleted(row?.project, row?.dependencies);
                if (isPreviousTaskCompleted) {
                    setAnchorEl(null);
                    setShowCompleteTaskWarning(true)
                }
                else {
                    setAnchorEl(null);
                    setShowTaskWarning(true);
                    setCompleteTaskWarning(false);
                }
            }
            else {
                setAnchorEl(null);
                setShowCompleteTaskWarning(true)
            }
        }
    }

    React.useEffect(() => {
        fetData();
        getProjectMembersByProjectId();
        workSchedule();
        setIsChanged(false);
        getProjectInformationData("projectView");

        // getUserSession(accessToken).then(res => {
        setRole(userSessionDetails?.role.name);
        // });
    }, [props.project, holdSuccess])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const reschedule = async () => {
        setWarning(false);
        setIsLoading(true);
        const wbs = (editedTask.wbs).split(".")
        wbs.pop();
        let result;
        if (isEndDate === true) {
            result = await taskReschedule(editedTask.project, editedTask.taskId, wbs.join("."), editedTask.startTime, editedTask.endTime, accessToken);
        }
        else {
            result = await taskReschedule(editedTask.project, editedTask.taskId, wbs.join("."), editedTask.startTime, null, accessToken);
        }
        if (result?.status === 200 || result?.status === 201) {
            fetData();
            getProjectMembersByProjectId();
            props?.func("projectView");
        } else {
            setWarning(false);
            setIsLoading(false);
            setOpenDialog(true);
            setError(true);
        }
        setIsChanged(false);
    }

    const closeAlter = () => {
        setWarning(false);
    }

    const handleLoadingClose = () => {
        setIsLoading(false);
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
        props?.func("projectView");
    }

    const closeHoldDialog = () => {
        setHoldSuccess(false);
        setError(false);
        setOpenHoldDialog(false);
        props?.func("projectView");
    }

    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeleteError(false);
        setDeleteSuccess(false);
    }

    const handleRowChange = async (event: any, row: any, field: any, index: any, parentIndex: any) => {
        if (new Date(row[field].replace(/Z/g, '')).toString() !== (event.$d).toString()) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
        // var task = Object.assign('', row);
        var task = { ...row };
        // task[field] = dateFormatter(event.$d);

        let Arr = [...taskList];
        let estimatedHours = 0;

        if (field === 'endTime') {
            // setStartTime(dayjs.utc(row.startTime).format('YYYY-MM-DD HH:mm:ss[Z]'))
            // const  rowStartTime  = dayjs.utc(row.startTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
            const rowStartTime = dayjs((row?.startTime)?.replace(/Z/g, ''));
            //const estimatedHours =dayjs(event.$d).diff(startTime === undefined ? rowStartTime : startTime, 'hour', true); 
            estimatedHours = calculateWorkingHours(rowStartTime, dayjs(event.$d));
            Arr[parentIndex]['tasks'][index]["endTime"] = dayjs(event.$d).format('YYYY-MM-DDTHH:mm:ss[Z]');
        } else {
            const rowEndTime = dayjs((row?.endTime)?.replace(/Z/g, ''));
            //const estimatedHours =dayjs(event.$d).diff(startTime === undefined ? rowStartTime : startTime, 'hour', true); 
            estimatedHours = calculateWorkingHours(dayjs(event.$d), rowEndTime);
            Arr[parentIndex]['tasks'][index]["startTime"] = dayjs(event.$d).format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        if (dayjs((Arr[parentIndex]['tasks'][index]["startTime"])?.replace(/Z/g, '')) >= dayjs((Arr[parentIndex]['tasks'][index]["endTime"])?.replace(/Z/g, ''))) {
            setValidEndDate(true);
        } else {
            setValidEndDate(false);
        }
        Arr[parentIndex]['tasks'][index]["estimatedHours"] = estimatedHours.toFixed(2);

        setTaskList(Arr);
        setIsEndDate(true);
        setEditedTask(Arr[parentIndex]['tasks'][index]);

    }


    // dayjs.extend(isSameOrAfter);
    // dayjs.extend(isSameOrBefore);

    const WORK_START_HOUR = 9; // 9 AM
    const WORK_END_HOUR = 17; // 5 PM

    const calculateWorkingHours = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
        let start = startDate;
        let end = endDate;

        if (start.isAfter(end)) return 0;

        let totalWorkingHours = 0;

        while (start.isBefore(end)) {
            if (start.day() !== 0 && start.day() !== 6) { // Check if it's a weekday
                const startOfWorkday = start.hour(startTime.hour()).minute(0).second(0);
                const endOfWorkday = start.hour(endTime.hour()).minute(0).second(0);

                if ((start.isSame(startOfWorkday) || start.isAfter(startOfWorkday)) && (start.isSame(endOfWorkday) || start.isBefore(endOfWorkday))) {
                    totalWorkingHours += Math.min(endOfWorkday.diff(start, 'hour', true), end.diff(start, 'hour', true));
                } else if (start.isBefore(startOfWorkday)) {
                    totalWorkingHours += Math.min(endOfWorkday.diff(startOfWorkday, 'hour', true), end.diff(startOfWorkday, 'hour', true));
                }
            }
            start = start.add(1, 'day').startOf('day');
        }

        return totalWorkingHours;
    };



    const shouldDisableTime: any = (value: any, view: any, row: any) => {
        if (row != undefined) {
            return (view === 'minutes' && value.minute() <= dayjs((row?.startTime)?.replace(/Z/g, '')).minute() && value.hour() === dayjs((row?.startTime)?.replace(/Z/g, '')).hour()
                && value.date() === dayjs((row?.startTime)?.replace(/Z/g, '')).date() && value.month() === dayjs((row?.startTime)?.replace(/Z/g, '')).month() && value.year() === dayjs((row?.startTime)?.replace(/Z/g, '')).year())
                || (value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
        } else {
            return (view === 'minutes' && value.minute() < startTime.minute()) || (value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
        }
    };

    const shouldDisableStartTime: any = (value: any, view: any) => {
        return (view === 'minutes' && value.hour() === startTime.hour() && value.minute() < startTime.minute()) || (value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
    }


    const shouldDisableDates: any = (value: any, view: any, row: any) => {
        return !workingDays.includes(value.day());
    };

    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const reassignResource = async () => {
        if (deletedIndex.length === 0) {
            let result = await reassignTeamMember(updatedData, accessToken);
            if ((result?.status === 200 || result?.status === 201) && result?.data !== null) {
                setOpenDialogs(true);
                setCreateSuccess(true);
                fetData();
                getProjectMembersByProjectId();
                setUpdatedData({ ...updatedData, projectMembers: [], tasks: [] });
            }
            else {
                setCreateError(true);
            }
        }

    }

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [selectedRowId, setSelectedRowId] = React.useState<any>();

    const handleSave = (id) => {
        if (!validEndDate) {
            setWarning(true);
        }
    };

    const handleclose = () => {
        setSelectedRowId(undefined);
    };

    const handleClickEdit = (id) => {
        setSelectedRowId(id);
    };

    const handleRowClick = (rowIndex) => {
        setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
        setOpenRow(openRow === rowIndex ? null : rowIndex);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleClickMenu = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const addTask = (taskId: any, phase: any, wbs: any) => {
        setTaskId(taskId);
        setPhase(phase);
        setEditView(false);
        setWbs(wbs);
        setOpenTaskEditorDialogs(true);
        handleClose();
    }
    const handleClose = () => {
        setAnchorEl(null);
        setOpenReasonDialog(false);
    };
    const closeAddTaskDialog = () => {
        setOpenTaskEditorDialogs(false);
        setEditView(false);
    }

    const validatePOGroupTask = () => {
        var notValid = false;
        var groupTaskCount = 0;
        const count = {};
        poTaskGroup.map(group => {
            taskList.map(data => {
                data?.tasks?.map(task => {
                    if (task?.taskType === group) {
                        if (count[group]) {
                            count[group] += 1;
                        }
                        else {
                            count[group] = 1;
                        }
                        if (groupTaskCount < count[group]) {
                            groupTaskCount = count[group]
                        }
                    }
                }
                )
            }
            )
        })

        Object.entries(count).map((value: any) => {
            if (value[1] < groupTaskCount) {
                pendingTasks.push(value[0]);
                notValid = true;
            }
        });

        return notValid;
    }

    const validateInvoiceTask = () => {
        const count = {};
        invoiceTaskGroup.map((group: any) => {
            taskList.map((data: any) => {
                data?.tasks?.map((task: any) => {
                    if (task.taskType === group) {
                        if (count[group]) {
                            count[group] += 1;
                        }
                        else {
                            count[group] = 1;
                        }
                    }
                }
                )
            }
            )
        })
        console.log(count)
        if ((count["Create Invoice Task"] === count["Approve Invoice Task"]) && (count["Approve Invoice Task"] === count["Payment Task"]) &&
            (count["Payment Task"] === count["Remainder Invoice Task"])) {
            return false;
        }
        else {
            return true;
        }

    }
      

    const showNextAvailableDate = () => {
        setOpenEstimateWarningDialogs(false);
    }

    const showCompleteTaskWarningDialog = () => {
        setShowCompleteTaskWarning(false)
    }

    const validateBomAndProjectTool = () => {
        let taskName: any = [];
        // let taskList: any = [];
        taskList.map(data => {
            data?.tasks?.map(task => {
                // if (task.taskType === "Create BOM Task") {
                //     if ((task?.boms).length === 0 && ((task?.projectTools).length === 0)) {
                //         if (!taskName.includes(task?.taskName)) {
                //             taskName.push(task?.taskName);
                //         }
                //     }
                // }
                if (gpsTaskTypes.includes(task.taskType)) {
                    if (task?.resources === "null" || task?.resources === null) {
                        if (!taskName.includes(task?.taskName)) {
                            taskName.push(task?.taskName);
                        }
                    }
                }
                if (task?.taskType === "Install Task" || task?.taskType === "Remove Task") {
                    if (task?.allocatedUnit === null || task?.allocatedUnit === "null") {
                        if (!taskName.includes(task?.taskName)) {
                            taskName.push(task?.taskName);
                        }
                    }
                }
            })
        }
        )
        setTaskNames(taskName);
        if (taskName?.length === 0) {
            // setOpenEstimateWarningDialogs(true);
            let validatePoTasks = validatePOGroupTask();
            if (validatePoTasks === false) {
                let validateInvoiceTasks = validateInvoiceTask();
                if (validateInvoiceTasks === false) {
                    setOpenEstimateWarningDialogs(true);
                }
                else {
                    setIsGroupTask(true);
                    setOpenErrorWarningDialogs(true);
                }
            }
            else {
                setIsGroupTask(true);
                setOpenErrorWarningDialogs(true);
            }
        }
        else if (taskName?.length !== 0) {
            setOpenErrorWarningDialogs(true);
        }
        // const groupedTask = {};
        // console.log(taskList);
        // taskList.forEach((tasks) => {
        //     const dependencies = (tasks.dependencies)?.split(";")[0];
        //     if (!groupedTask[dependencies]) {
        //         groupedTask[dependencies] = [];
        //     }
        //     groupedTask[dependencies].push(tasks);
        // });
        // const resultArray = Object.values(groupedTask);
        // resultArray?.map((result: any) => {
        //     if(result?.length !== poTaskGroup.length) {
        //         let data = result;
        //         data?.map(d => {
        //             if (!taskName.includes(d?.taskName)) {
        //                 taskName.push(d?.taskName);
        //             }
        //         }
        //         )
        //     }
        // }

        // )
        // console.log(resultArray);
        console.log(taskNames, groupTasks)

    }

    const updateProjectStatus = async (status: any) => {
        let res = await updateProjectByProjectId(props?.projectId, props.user.firstname + ' ' + props.user.lastname, "rescheduled", status, accessToken);
    }

    const updateProject = async (status: any) => {
        let result = await updateProjectByProjectId(props?.projectId, props.user.firstname + ' ' + props.user.lastname, reason, status, accessToken);
        if (result.status === 200 || result.status === 201) {
            setOpenReasonDialog(false);
            setReason('');
            setMessage("Status Changed");
            setOpenHoldDialog(true);
            setHoldSuccess(true);

        } else {
            setOpenReasonDialog(false);
            setMessage("change Status");
            setOpenHoldDialog(true);
            setError(true);
        }
    }

    const isDateDisabled = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        // Disable dates up to and including today


        if (date.isBefore(today, 'day') || date.isSame(today, 'day')) {
            return true;
        }

        return date.day() === 6 || date.day() === 0;
    };

    const resumeProject = async (event: any) => {
        if (role === "sales") {
            updateProjectStatus(statusHistory.oldState);
        } else {
            setSelectedDate(event.$d);
        }
    }

    const handleHold = () => {
        setOpenReasonDialog(true)
    };

    const handleComplete = () => {
        setOpenCompleteWarningDialog(true)
    };

    const rescheduleProject = async () => {
        setOpenCalendar(false);
        setIsLoading(true);
        const result = await projectReschedulewithDate(props?.projectId, dayjs(selectedDate).hour(9).minute(0).format('YYYY-MM-DDTHH:mm') + 'Z', "resume", accessToken);
        if ((result.status === 200 || result.status === 201) && result.data === "Successful") {
            setMessage("Project Rescheduled");
            setIsLoading(false);
            setOpenDialog(true);
            setSuccess(true);
            updateProjectStatus(projectActive);
        } else {
            setMessage("Reschedule");
            setIsLoading(false);
            setOpenDialog(true);
            setError(true);
        }
    }

    const holdProject = () => {
        updateProject(onHold);
        // getProjectInformationData();
        // setOpenReasonDialog(true);
    }

    const handleReasonChange = (event: any) => {
        setReason(event.target.value);
    }

    const SearchBar = () => {

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClicks = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className='searchBar'>
                <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                    {/* <Grid item xs={2} mt={2}>
                        <Select
                            className='filter'
                            fullWidth
                            value=""
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            size='small'
                        >
                            <MenuItem value="" disabled>
                                Batch actions
                            </MenuItem>
                            <MenuItem value="1">Option 1</MenuItem>
                            <MenuItem value="2">Option 2</MenuItem>
                            <MenuItem value="3">Option 3</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3} mt={2}>

                    </Grid>
                    <Grid item xs={1}>  </Grid>
                    <Grid item xs={4}>  </Grid>
                    <Grid item xs={2} className='createInvoice'>
                        <Grid item xs={12} mt={2}>
                            <Tooltip title="Print">
                                <IconButton>
                                    <PrintIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Share">
                                <IconButton>
                                    <ShareIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Settings">
                                <IconButton>
                                    <SettingsIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={6} mt={2}>
                        <TextField
                            label="Search "
                            size='small'
                            name="materialName"
                            // onChange={(e) => handleTextChange(e)}
                            // value={value}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </div>
        )
    };
    return (
        <><Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            // onClick={handleLoadingClose}
        >
            <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
        </Backdrop> <>
                <Grid container p={1}>
                    <Grid xs={4}></Grid>
                    <Grid xs={4}>
                        {(statusHistory !== undefined && props.project?.status === onHold) &&
                            <Typography textAlign={'center'} sx={{ backgroundColor: "#efef8d", borderRadius: 10 }}>*Reason For Hold: {statusHistory.changeReason}</Typography>
                        }</Grid>
                    <Grid xs={4}>
                        {role === "admin" &&
                            <><Stack direction="row" justifyContent={"flex-end"}>

                            </Stack>

                                <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                    {(props.project?.status === projectActive || props.project?.status === onHold) && <Box>
                                        {props.project?.status === onHold ? <Button variant='contained' color="primary" onClick={() => setOpenCalendar(true)}>Resume</Button>
                                            :
                                            <Stack direction="row" spacing={1}>
                                                <Button variant='contained' sx={{ backgroundColor: "orange" }} onClick={() => handleHold()} size='medium'>Hold</Button>
                                                <Button variant='contained' color="error" onClick={() => handleComplete()} size='medium' >Force Complete</Button>
                                            </Stack>}

                                    </Box>}
                                </Stack>
                                <Stack direction="row" justifyContent={"flex-end"}>

                                </Stack></>
                        }
                        {role === "sales" &&
                            <><Stack direction="row" justifyContent={"flex-end"}>

                            </Stack>
                                <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                    {(props.project?.status !== projectActive && props.project?.status === onHold) && <Box>
                                        {<Button variant='contained' color="primary" onClick={() => validateBomAndProjectTool()}>Resume</Button>}
                                    </Box>}
                                </Stack>
                                <Stack direction="row" justifyContent={"flex-end"}>

                                </Stack></>
                        }
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                        <Grid item xs={12} p={2}>
                            <Card sx={{ p: 2 }}>
                                <Grid container>
                                    <Grid item xs={4} p={2}>
                                        <Stack direction={'row'} justifyContent="space-between" marginBottom={2}>
                                            <Typography sx={{ fontSize: '18px', fontWeight: 500, paddingBottom: 2 }}>Project Members</Typography>
                                            {/* {deletedIndex.length === 0 ? null : */}

                                            {/* } */}
                                        </Stack>
                                        {role === admin ?
                                            <Stack direction={'row'} spacing={1} flexWrap="wrap" useFlexGap>
                                                {memberList?.map((data: any, index: any) =>
                                                    <Chip label={data?.name} variant="outlined"
                                                        onDelete={() => showWarning(data, index)}
                                                        disabled={data?.isComplete}
                                                    />
                                                )
                                                }
                                            </Stack> :
                                            <Stack direction={'row'} spacing={1} flexWrap="wrap" useFlexGap>
                                                {memberList?.map((data: any, index: any) =>
                                                    <Chip label={data?.name} variant="outlined" />
                                                )
                                                }
                                            </Stack>}
                                    </Grid>
                                    {/* {((role === admin || role === "sales") && (!completedProjectStatus.includes(props.project?.status)))   ?
                                        <Grid item xs={4} p={2}>
                                            <ProfitMarginSettings projectId={props?.projectId} />
                                        </Grid>
                                        :
                                        <Grid item xs={4} p={2}>
                                        </Grid>
                                    } */}
                                    <Grid item xs={4} p={2}>
                                    </Grid>
                                    <Grid item xs={4} p={2}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {role === admin && <Button variant='contained' disabled={updatedData.tasks.length === 0} onClick={reassignResource}>Update</Button>}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Box sx={{ width: '100%', }}>

                                {/* <SearchBar /> */}
                                <Paper>
                                    <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                                        <Table
                                            sx={{ minWidth: 750, border: 'none', }}
                                            aria-labelledby="tableTitle"
                                        // size={dense ? 'small' : 'medium'}
                                        >
                                            <TableHead className='tableHead'>
                                                <TableRow    >
                                                    <TableCell className='tableTimeSheet'>Task ID</TableCell>
                                                    <TableCell className='tableTimeSheet'>Task Name</TableCell>
                                                    <TableCell className='tableTimeSheet'>Status</TableCell>
                                                    <TableCell className='tableTimeSheet'>Start Date</TableCell>
                                                    <TableCell className='tableTimeSheet'>End Date</TableCell>
                                                    <TableCell className='tableTimeSheet'>Duration </TableCell>
                                                    <TableCell className='tableTimeSheet'>Resource </TableCell>
                                                    <TableCell >action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(taskList).length === 0 ? (<TableRow>
                                                    <TableCell colSpan={10}>
                                                        <Typography align="center">No Data</Typography>
                                                    </TableCell>
                                                </TableRow>)
                                                    :
                                                    (<>
                                                        {taskList?.map((items, parentIndex) => (
                                                            <React.Fragment>
                                                                <TableRow onClick={() => handleRowClick(items?.phase)}
                                                                    style={{
                                                                        backgroundColor: items?.phase === "sales" ? "#AEBBFF" : items?.phase === "activeConstruction" ? "#D9DFFE" :
                                                                            items?.phase === "preConstruction" ? "#C2CCFF" : items?.phase === "postConstruction" ? "#F1F3FF" : "white"
                                                                    }}
                                                                >
                                                                    <TableCell >
                                                                        {(items?.phase).split(/(?=[A-Z])/).join("-").replace(/^./, items?.phase[0].toUpperCase())}
                                                                    </TableCell>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell>
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            sx={{ fontWeight: 500 }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setOpen(!open);
                                                                                handleRowClick(items?.phase);
                                                                            }}
                                                                            style={{
                                                                                backgroundColor: items?.phase === "sales" ? "#AEBBFF" : items?.phase === "activeConstruction" ? "#D9DFFE" :
                                                                                    items?.phase === "preConstruction" ? "#C2CCFF" : items?.phase === "postConstruction" ? "#F1F3FF" : "white"
                                                                            }}
                                                                        >
                                                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {openRow === items?.phase && (
                                                                    <>
                                                                        {(items?.tasks)?.map((row, index) => (
                                                                            <>
                                                                                <TableRow
                                                                                >
                                                                                    <TableCell
                                                                                    >
                                                                                        {row?.taskId}
                                                                                    </TableCell>
                                                                                    <TableCell >{row?.taskName}</TableCell>
                                                                                    <TableCell>{row?.status}</TableCell>
                                                                                    <TableCell>
                                                                                        {row?.status === "completed" ? dayjs((row?.startTime)?.replace(/Z/g, '')).format('MMM DD, YYYY h:mm A')
                                                                                            :
                                                                                            (selectedRowId === row?.id) ? <FormControl>

                                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                    <DateTimePicker
                                                                                                        // format='DD MMM YYYY'
                                                                                                        label="start Date"
                                                                                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                                                        sx={{ maxWidth: "220px" }}
                                                                                                        disablePast
                                                                                                        closeOnSelect={false}
                                                                                                        name="startTime"
                                                                                                        //disabled={ids.includes(index) ? true : false}
                                                                                                        shouldDisableDate={shouldDisableDates}
                                                                                                        shouldDisableTime={(value, view) => shouldDisableStartTime(value, view)}
                                                                                                        value={dayjs((row?.startTime)?.replace(/Z/g, ''))}
                                                                                                        onChange={(e) => handleRowChange(e, row, "startTime", index, parentIndex)} />
                                                                                                </LocalizationProvider>
                                                                                            </FormControl> : row?.startTime === null ? null : dayjs((row?.startTime)?.replace(/Z/g, '')).format('MMM DD, YYYY h:mm A')}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {row?.status === "completed" ? dayjs((row?.endTime)?.replace(/Z/g, '')).format('MMM DD, YYYY h:mm A')
                                                                                            :
                                                                                            (selectedRowId === row?.id) ? <FormControl>

                                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                    <DateTimePicker
                                                                                                        // format='DD MMM YYYY'
                                                                                                        label="end Date"
                                                                                                        slotProps={{ textField: { fullWidth: true, size: "small", error: validEndDate } }}
                                                                                                        sx={{ maxWidth: "220px" }}
                                                                                                        minDateTime={dayjs((row?.startTime)?.replace(/Z/g, ''))}
                                                                                                        shouldDisableTime={(value, view) => shouldDisableTime(value, view, row)}
                                                                                                        shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                                                                                                        closeOnSelect={false}
                                                                                                        //disablePast
                                                                                                        name="endTime"
                                                                                                        //disabled={ids.includes(index) ? true : false}
                                                                                                        value={dayjs((row?.endTime)?.replace(/Z/g, ''))}
                                                                                                        onChange={(e) => handleRowChange(e, row, "endTime", index, parentIndex)} />
                                                                                                </LocalizationProvider>
                                                                                            </FormControl> : row?.endTime === null ? null : dayjs((row?.endTime)?.replace(/Z/g, '')).format('MMM DD, YYYY h:mm A')}
                                                                                    </TableCell>
                                                                                    <TableCell>{(Number(row?.estimatedHours).toFixed(2))}</TableCell>

                                                                                    <TableCell>
                                                                                        {(row?.wbs).split('.').length === 2 ? null :
                                                                                            <FormControl fullWidth size='small'>
                                                                                                <InputLabel id="demo-multiple-chip-label">Resource</InputLabel>
                                                                                                <Select
                                                                                                    labelId="demo-multiple-chip-label"
                                                                                                    id="demo-multiple-chip"
                                                                                                    multiple
                                                                                                    size='small'
                                                                                                    name='resourceName'
                                                                                                    value={row?.resource}
                                                                                                    disabled={(row?.status === "scheduled" && role === admin && (props?.project?.status !== "On Hold" && props?.project?.status !== "Project Inactive")) ? false : true}
                                                                                                    error={deletedIndex.includes(row?.taskId) ? true : false}
                                                                                                    onChange={(e) => handleChange(e, index, row)}
                                                                                                    // onClick={click}
                                                                                                    onOpen={(e) => handleOpen(e, index, row)}
                                                                                                    input={<OutlinedInput id="select-multiple-chip" label="Resource" />}
                                                                                                    renderValue={(selected) => (
                                                                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                                            {selected.map((value) => (
                                                                                                                <Chip key={value.name} label={value.name} />
                                                                                                            ))}
                                                                                                        </Box>
                                                                                                    )}
                                                                                                >
                                                                                                    {row?.resource?.map((name) => (
                                                                                                        <MenuItem
                                                                                                            key={name?.name}
                                                                                                            value={name}
                                                                                                        >
                                                                                                            <Checkbox checked={row?.resource?.indexOf(name) > -1} />
                                                                                                            {name?.name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                    {resourceList?.map((name) => (
                                                                                                        <MenuItem
                                                                                                            key={name?.name}
                                                                                                            value={name}
                                                                                                        >
                                                                                                            <Checkbox checked={row?.resource?.indexOf(name) > -1} />
                                                                                                            {name?.name}({name?.designation})
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        }

                                                                                        {/* } */}
                                                                                    </TableCell>
                                                                                    {<TableCell align="center">
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                                alignItems: 'center',
                                                                                                '& > *': {
                                                                                                    m: 1,
                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            {(row?.status !== "completed" && row?.status !== "active") && <Stack direction="row" spacing={2}>
                                                                                                <div>
                                                                                                    {row?.wbs.split(".").length > 2 && (selectedRowId === row.id ? (
                                                                                                        isChanged ?
                                                                                                            <Tooltip title="save" arrow>
                                                                                                                <IconButton size="small" onClick={() => handleSave(row.id)}>
                                                                                                                    <SaveIcon />
                                                                                                                </IconButton>
                                                                                                            </Tooltip> :
                                                                                                            <Tooltip title="close" arrow>
                                                                                                                <IconButton size="small" onClick={() => handleclose()}>
                                                                                                                    <CloseIcon />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                    ) : (
                                                                                                        <Tooltip title="Edit" arrow>
                                                                                                            <IconButton disabled={props?.project?.status === "On Hold" || props?.project?.status === "Project Inactive"} size="small" onClick={() => handleClickEdit(row.id)}>
                                                                                                                <EditIcon />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </Stack>}
                                                                                            <IconButton
                                                                                                id="demo-positioned-button"
                                                                                                aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                                                                                aria-haspopup="true"
                                                                                                aria-expanded={openMenu ? 'true' : undefined}
                                                                                                onClick={(e) => handleClickMenu(e, index)}
                                                                                            >
                                                                                                <MoreVertIcon />
                                                                                            </IconButton>
                                                                                            <Menu
                                                                                                id="demo-positioned-menu"
                                                                                                aria-labelledby="demo-positioned-button"
                                                                                                anchorEl={anchorEl}
                                                                                                open={openMenu === true && menuIndex === index ? true : false}
                                                                                                onClose={handleCloseMenu}
                                                                                                anchorOrigin={{
                                                                                                    vertical: 'top',
                                                                                                    horizontal: 'left',
                                                                                                }}
                                                                                                transformOrigin={{
                                                                                                    vertical: 'top',
                                                                                                    horizontal: 'left',
                                                                                                }}
                                                                                            >
                                                                                                {/* <MenuItem
                                                                                                // onClick={() => editTask(row)}
                                                                                                >
                                                                                                    <Stack direction={'row'} spacing={1}>
                                                                                                        <VisibilityIcon fontSize='small' />
                                                                                                        <Typography>View</Typography>
                                                                                                    </Stack>
                                                                                                </MenuItem> */}

                                                                                                {/* <MenuItem
                                                                                                    // onClick={() => editTask(row)}
                                                                                                    disabled={row?.wbs.split(".").length === 2 ? true : false}
                                                                                                >
                                                                                                    <Stack direction={'row'} spacing={1}>
                                                                                                        <EditIcon fontSize='small'
                                                                                                        />
                                                                                                        <Typography>Edit</Typography>
                                                                                                    </Stack>
                                                                                                </MenuItem> */} 
                                                                                                <MenuItem
                                                                                                    onClick={() => getDependentTask(row)}
                                                                                                    // disabled={items?.phase === "sales" || row?.wbs.split(".").length === 2 ? true : false}
                                                                                                    disabled={row?.wbs.split(".").length === 2 || row?.status === "completed" || props?.project?.status === "On Hold" || props?.project?.status === "Project Inactive"  || row?.status === "cancelled" || nonDeletableTasks.includes(row?.taskType) ? true : false}
                                                                                                >
                                                                                                    <Stack direction={'row'} spacing={1}>
                                                                                                        <DeleteIcon fontSize='small' />
                                                                                                        <Typography>Delete</Typography>
                                                                                                    </Stack>
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    onClick={() => skipTask(row)}
                                                                                                    // disabled={items?.phase === "sales" || row?.wbs.split(".").length === 2 ? true : false}
                                                                                                    disabled={row?.wbs.split(".").length === 2 || row?.status === "completed" || props?.project?.status === "On Hold" || props?.project?.status === "Project Inactive" || row?.status === "cancelled" ? true : false}
                                                                                                >
                                                                                                    <Stack direction={'row'} spacing={1}>
                                                                                                        <TaskAltIcon fontSize='small' />
                                                                                                        <Typography>Complete Task</Typography>
                                                                                                    </Stack>
                                                                                                </MenuItem>
                                                                                                {parseInt(row?.taskId) >= Math.max(...progressingTasks) && 
                                                                                                <MenuItem
                                                                                                    onClick={() => addTask(row?.taskId, items?.phase, row?.wbs)}
                                                                                                    disabled={props?.project?.status === "Project Closed" ? true : false}
                                                                                                >
                                                                                                    <Stack direction={'row'} spacing={1}>
                                                                                                        <AddIcon fontSize='small' />
                                                                                                        <Typography>Add Below</Typography>
                                                                                                    </Stack>
                                                                                                </MenuItem>}
                                                                                                {/* <MenuItem onClick={addTask}>
                                                                                            <Stack direction={'row'} spacing={1}>
                                                                                                <AddIcon fontSize='small' />
                                                                                                <Typography>Add Above</Typography>
                                                                                            </Stack>
                                                                                        </MenuItem> */}
                                                                                            </Menu>

                                                                                        </Box>
                                                                                    </TableCell>}
                                                                                </TableRow>

                                                                                {/* } */}
                                                                            </>
                                                                        ))}

                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </>)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <Dialog open={deleteWarning} >
                                    <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                                        <Box paddingLeft={8} paddingRight={8}>
                                            <Typography textAlign={'center'}>
                                                <ReportProblemOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                            {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                                        </Box>
                                    </DialogTitle>
                                    <Box paddingLeft={8} paddingRight={8}>
                                        <DialogContent >
                                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Do you want to remove this resource?</DialogContentText>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: "center" }}>
                                            <Button variant='contained' className='success' onClick={removeResource}>Yes</Button>
                                            <Button variant='contained' className="error" onClick={onCloseDialog}>No</Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>
                                <CustomDialog
                                    open={openDialogs}
                                    onClose={() => setOpenDialogs(false)}
                                    success={createSuccess}
                                    error={createError}
                                    Content={createSuccess ? "Resource Assigned" : "Error Couldnot Assigned"} />
                                <CustomDialog
                                    open={openSuccessDialog}
                                    onClose={() => setOpenSuccessDialog(false)}
                                    success={createTaskSuccess}
                                    error={createTaskError}
                                    Content={createTaskSuccess ? "Task Completed" : "Error Couldnot Completed"} />
                                <AddTaskDialog open={openTaskEditorDialogs} onClose={closeAddTaskDialog}
                                    projectId={props?.projectId} id={taskId} phase={phase} wbs={wbs}
                                    state={"Project Information"}
                                    getTasks={fetData}
                                    tasks={tasks} editView={editView} />
                                <React.Fragment>
                                    <Dialog
                                        open={openDependenciesView}
                                        fullWidth={true}
                                        maxWidth={"sm"}
                                        scroll={'paper'}
                                    >
                                        <DialogTitle >
                                            {"Warning! This tasks have the following dependency"}
                                        </DialogTitle>
                                        <DialogContent dividers>
                                            {dependentTask?.map((task: any, index: any) => {
                                                return (
                                                    <DialogContentText marginBottom={1}>
                                                        <Stack direction={'row'} spacing={1}>
                                                            <Typography>{index + 1}.</Typography>
                                                            <Typography> {task?.taskName}</Typography>
                                                        </Stack>
                                                    </DialogContentText>
                                                )
                                            })}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button className='sucessButton' variant="contained" size="small" onClick={() => deleteTaskById(deletedTaskData)}>Proceed</Button>
                                            <Button className='dangerButton' variant="contained" size="medium" onClick={() => setOpenDependenciesView(false)} autoFocus>
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>

                                {/* <TablePagination
                                    sx={{ border: 'none', }}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={taskList?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}


                            </Box>
                        </Grid>
                    </Paper>
                </Grid>

                <Dialog open={warning}>
                    <DialogTitle className='warning'>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <WarningAmberIcon sx={{ fontSize: "50px", color: "white" }} />
                            </Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'}>
                                Warning
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent>
                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Remaining tasks will reschedule</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button onClick={() => closeAlter()} variant='contained' sx={{ backgroundColor: "#df3333ed" }}>Close</Button>
                            <Button variant='contained' color="primary" onClick={reschedule}>Ok</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openCalendar}
                >
                    <DialogTitle>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography className='cartheader'>Select Date When You Want to Start Work</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={() => setOpenCalendar(false)}>
                                    <CloseIcon sx={{ color: "red" }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: "#f8f7fa" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                shouldDisableDate={isDateDisabled} //disable properties
                                disablePast
                                disableHighlightToday
                                value={dayjs(selectedDate)}
                                onChange={resumeProject} />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenCalendar(false)} color="error">Close</Button>
                        <Button color="primary" onClick={rescheduleProject}>Reschedule</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openReasonDialog}
                    onClose={handleClose}
                >
                    <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
                        <DialogContent >
                            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                Give a Reason To Status Change
                            </Typography>
                            <Grid container>
                                <Grid xs={3}></Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="outlined-number"
                                        type="text"
                                        value={reason !== '' ? reason : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleReasonChange(e)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={3}></Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button onClick={handleClose} className="dangerButton">Close</Button>
                        <Button disabled={reason === ''} onClick={holdProject} autoFocus className={reason !== '' ? "primaryTonalButton" : ""}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <CompleteWarningDialog open={openCompleteWarningDialog} onClose={() => setOpenCompleteWarningDialog(false)} projectId={props.projectId} />
                <EstimateWarning open={openEstimateWarningDialogs} onClose={() => setOpenEstimateWarningDialogs(false)} openDatePicker={showNextAvailableDate} projectId={props?.props?.id} />
                <ErrorWarning taskNames={taskNames} open={openErrorWarningDialogs} onClose={handleCloseErrorDialog}
                    isGroupTask={isGroupTask} pendingJobs={pendingTasks} />
                <CustomDialog
                    open={openDeleteDialog}
                    onClose={() => closeDeleteDialog()}
                    success={deleteSuccess}
                    error={deleteError}
                    Content={deleteSuccess ? "Deleted" : "Not Deleted"}
                />
                <CustomDialog
                    open={openHoldDialog}
                    onClose={closeHoldDialog}
                    success={holdSuccess}
                    error={error}
                    Content={holdSuccess ? "Project On Hold Successfully" : "Failed To On Hold"} />
                <CustomDialog
                    open={openDialog}
                    onClose={closeDialog}
                    success={success}
                    error={error}
                    Content={success ? "Project Rescheduled Successfully" : "Failed To Reschedule"} />
                <TaskWarningDialog open={showTaskWarning} onClose={() => setShowTaskWarning(false)} taskNames={taskNamesList} isCompleteWarning={completeTaskWarning} />
                <CompleteTaskWarning open={showCompleteTaskWarning} onClose={showCompleteTaskWarningDialog} tasks={skipTaskData} func={fetData} />
            </>

        </>

    );
}

export default TaskList;