/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for adding employee
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*                19/03/2025     asish         rich text editor for showing contract template for contractor         
*  
*
*
*/
import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getUserSession } from '../../hooks/UserSession';
import './AddressModule.scss';

interface EditorProps {
  value: string;
  readOnly: boolean;
  onChange: (content: string) => void;
}

const AddressModule: React.FC<EditorProps> = ({ value, readOnly, onChange }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);
  const { accessToken } = useOidcAccessToken();

  const [showTableDialog, setShowTableDialog] = useState(false);

  const [tableRows, setTableRows] = useState(2);
  const [tableCols, setTableCols] = useState(3);
  const [attachments, setAttachments] = useState<any>([]); // Store uploaded files
  const fileInputRef = useRef<any>(null);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const newAttachment = { name: file.name, url, type: file.type };
      setAttachments([...attachments, newAttachment]);

      // Insert a link to the file inside the Quill editor
      // setContent((prevContent) =>
      //   prevContent + `<p><a href="${url}" target="_blank">${file.name}</a></p>`
      // );
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['tableButton'], // Custom table button
        // ['fileUploadButton'], // Custom file upload button
        ['imageButton']
      ],
      handlers: {
        image: () => handleImageUpload(),
      },
    },
  };
  useEffect(() => {
    if (!editorRef.current) return;

    if (!quillRef.current) {
      // Initialize Quill only once
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: readOnly ? { toolbar: false } : {
          toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline'],
            ['blockquote', 'code-block'],
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        },
        readOnly: readOnly,
      });

      // Handle text changes
      quillRef.current.on('text-change', () => {
        if (quillRef.current) {
          const content = quillRef.current.root.innerHTML;
          onChange(content);
        }
      });
    }
  }, [readOnly]); // Only run when `readOnly` changes

  useEffect(() => {
    if (quillRef.current) {
      const currentHTML = quillRef.current.root.innerHTML;
      if (currentHTML !== value) {
        quillRef.current.root.innerHTML = ''; // Clear old content
        quillRef.current.clipboard.dangerouslyPasteHTML(0, value); // Insert new content
      }
    }
  }, [value]); // Update only when `value` changes
  const insertTable = () => {
    if (!quillRef.current) return;
    let tableHTML = `<table border="none !important" style="width: 100%; border-collapse: collapse;">`;

    for (let r = 1; r <= tableRows; r++) {
      tableHTML += '<tr>';
      for (let c = 1; c <= tableCols; c++) {
        tableHTML += `<td>Row ${r} Col ${c}</td>`;
      }
      tableHTML += '</tr>';
    }
    tableHTML += '</table>';

    const range = quillRef.current.getSelection();
    quillRef.current.clipboard.dangerouslyPasteHTML(range?.index || 0, tableHTML);
    setShowTableDialog(false);
  };

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          if (quillRef.current) {
            const range = quillRef.current.getSelection();
            quillRef.current.insertEmbed(range?.index || 0, 'image', reader.result);
            setTimeout(() => makeImagesResizable(), 100); // Enable resizing after insertion
          }
        };
        reader.readAsDataURL(file);
      }
    };
  }; const makeImagesResizable = () => {
    const editorContainer = editorRef.current;
    if (!editorContainer) return;

    const images = editorContainer.querySelectorAll('img');
    images.forEach((img) => {
      img.style.maxWidth = '100%'; // Ensure it fits within the editor
      img.style.cursor = 'pointer';
      img.setAttribute('draggable', 'true');

      img.onmousedown = (event) => {
        event.preventDefault();
        const startX = event.clientX;
        const startWidth = img.width;

        const onMouseMove = (moveEvent: MouseEvent) => {
          const newWidth = startWidth + (moveEvent.clientX - startX);
          img.style.width = newWidth + 'px';
        };

        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      };
    });
  };
  // const handleFileUpload = () => {
  //   const input = document.createElement("input");
  //   input.type = "file";
  //   input.accept = ".pdf, .doc, .docx, .xlsx, .ppt, .pptx"; // Allowed formats
  //   input.click();

  //   input.onchange = async () => {
  //     const file = input.files?.[0];
  //     if (file) {
  //       const fileName = file.name;
  //       const fileUrl = URL.createObjectURL(file);

  //       if (quillRef.current) {
  //         const range = quillRef.current.getSelection();

  //         // If PDF, embed it directly
  //         if (file.type === "application/pdf") {
  //           const pdfEmbed = `
  //             <p>${fileName}</p>
  //             <iframe src="${fileUrl}" width="100%" height="400px" style="border: none;"></iframe>
  //           `;
  //           quillRef.current.clipboard.dangerouslyPasteHTML(range?.index || 0, pdfEmbed);
  //         } else {
  //           // For other files, provide a link
  //           const fileLinkHTML = `
  //             <p>
  //               📂 <a href="${fileUrl}" download="${fileName}" target="_blank" rel="noopener noreferrer"
  //                 style="color:blue; text-decoration:underline; cursor:pointer;">
  //                 ${fileName}
  //               </a>
  //             </p>
  //           `;
  //           quillRef.current.clipboard.dangerouslyPasteHTML(range?.index || 0, fileLinkHTML);
  //         }
  //       }
  //     }
  //   };
  // };





  return (
    <div id='AddressModule'>
      <div ref={editorRef} style={{ height: '200%', width: '100%' }} />
      {showTableDialog && (
        <div id="quillAddress"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            zIndex: 1000,
          }}
        >
          <h3>Create Table</h3>
          <label>
            Rows:
            <input type="number" min="1" value={tableRows} onChange={(e) => setTableRows(Number(e.target.value))} />
          </label>
          <br />
          <label>
            Columns:
            <input type="number" min="1" value={tableCols} onChange={(e) => setTableCols(Number(e.target.value))} />
          </label>
          <br />
          <button onClick={insertTable}>Insert Table</button>
          <button onClick={() => setShowTableDialog(false)}>Cancel</button>
        </div>
      )}
      {/* Attach File Button */}
      {/* <div style={{ paddingTop: "10px" }}> <button onClick={() => fileInputRef.current.click()}>
        📎 Attach File
      </button>
      </div>
      <input
        type="file"
        accept=".pdf,.docx,.xlsx"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {attachments.length > 0 && (
        <div>
          <h3>Attachments:</h3>
          <ul>
            {attachments.map((file, index) => (
              <li key={index}>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </div>
  );
};

export default AddressModule;
