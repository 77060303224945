/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-May-2024
* @author      : Vijaykumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Tool List 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE     |    AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2437           | 31-07-2024 | shiyam kumar |  Tool cost update issue fixed
*      2399      | 06-08-2024 | shiyam kumar | made changes to fix pagination issue
*/
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Alert, Button, Divider, FormControl, FormControlLabel, Grid, InputAdornment, InputBase, InputLabel, Menu, MenuItem, OutlinedInput, Select, SelectChangeEvent, Snackbar, Switch, Tab, Tabs, TextField, colors, useMediaQuery,
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, Paper, Checkbox, IconButton,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import CustomDialog from '../../pages/Dialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addTool, getToolCount, getToolsCost, searchTools, updateToolCost } from '../../services/ToolService';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { isDOMComponent } from 'react-dom/test-utils';
import { getAllVendors } from '../../services/VendorService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddToolForm from './AddToolForm';
import { validQuestionTrade } from '../../services/TradeConfigurationService';
import { useHandleUnauthorized } from '../../hooks/UserSession';

interface Data {
    id: number;
    VendorId: number;
    Trade: string;
    VendorName: string;
    ToolName: string;
    VersionHistory:string;
    VersionStartDate: string;
    ItemCode: string;
    Unit: string,
    UnitCost: number;
    Action: string;
}

function createData(
    id: number,
    VendorId: number,
    Trade: string,
    VendorName: string,
    ToolName: string,
    VersionHistory:string,
    VersionStartDate: string,
    ItemCode: string,
    Unit: string,
    UnitCost: number,
    Action: string,
): Data {
    return {
        id,
        VendorId,
        Trade,
        VendorName,
        ToolName,
        VersionHistory,
        VersionStartDate,
        ItemCode,
        Unit,
        UnitCost,
        Action

    };
}

// const rows = [
//     createData(1, 'Cupcake', 305, 3.7, 67, 4.3),
//     createData(2, 'Donut', 452, 25.0, 51, 4.9),
//     createData(3, 'Eclair', 262, 16.0, 24, 6.0),
//     createData(4, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData(5, 'Gingerbread', 356, 16.0, 49, 3.9),
//     createData(6, 'Honeycomb', 408, 3.2, 87, 6.5),
//     createData(7, 'Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData(8, 'Jelly Bean', 375, 0.0, 94, 0.0),
//     createData(9, 'KitKat', 518, 26.0, 65, 7.0),
//     createData(10, 'Lollipop', 392, 0.2, 98, 0.0),
//     createData(11, 'Marshmallow', 318, 0, 81, 2.0),
//     createData(12, 'Nougat', 360, 19.0, 9, 37.0),
//     createData(13, 'Oreo', 437, 18.0, 63, 4.0),
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    // {
    //     id: 'VendorId',
    //     numeric: false,
    //     disablePadding: true,
    //     label: 'VendorId',
    // },
    {
        id: 'VendorName',
        numeric: false,
        disablePadding: true,
        label: 'VendorName',
    },
    {
        id: 'Trade',
        numeric: false,
        disablePadding: true,
        label: 'Trade',
    },
    {
        id: 'ToolName',
        numeric: true,
        disablePadding: false,
        label: 'ToolName',
    },
    {
        id: 'ItemCode',
        numeric: true,
        disablePadding: false,
        label: 'ItemCode',
    },
    {
        id: 'VersionHistory',
        numeric: true,
        disablePadding: false,
        label: 'VersionHistory',
    },
    {
        id: 'VersionStartDate',
        numeric: true,
        disablePadding: false,
        label: 'VersionStartDate',
    },
    {
        id: 'Unit',
        numeric: true,
        disablePadding: false,
        label: 'Unit',
    },
    {
        id: 'UnitCost',
        numeric: true,
        disablePadding: false,
        label: 'UnitCost',
    },
    {
        id: 'Action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead className='materialTableHead'>
            <TableRow className='tableCustomerRows tableHead'>
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell className='tableCustomerRows '
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const ToolList: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('Trade');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const navigate = useNavigate();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = toolData.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const customerForm = () => {
        navigate("/customerregistration"); // Navigate to the "/task" route
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [trade, setTrade] = React.useState(null);
    const [toolName, setToolName] = React.useState(null);
    const [toolData, setToolData] = React.useState<any>([] as any);
    const [updatedToolData, setUpdatedToolData] = React.useState<any>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveError, setSaveError] = React.useState(false);
    const [trades, setTrades] = React.useState<any>([]);
    const tools = React.useRef([]);
    const [newToolData, setNewToolData] = React.useState<any>();

    const [vendorList, setVendorList] = React.useState<any>([]);
    const [tradeList, setTradeList] = React.useState<any[]>([]);

    const [count, setCount] = React.useState(0);
    const [vendor, setVendor] = React.useState<any>();
    const [ids, setIds] = React.useState<any>([]);
    const [number, setNumber] = React.useState(0);
    const [updatedRows, setUpdatedRows] = React.useState<any>({});
    const [updatedId, setUpdateId] = React.useState<any>([]);
    const [tradeName, setTradeName] = React.useState<string[]>([]);
    const [noChange, setNoChange] = React.useState(false);
    const filter = createFilterOptions<any>();
    const [updateCount, setUpdateCount] = React.useState(0);
    const [toolCount, setToolCount] = React.useState(Number);
    const [openAddToolForm, setOpenAddToolForm] = React.useState<boolean>(false);
  const { logout } = useOidc();

  const { handleUnauthorized } = useHandleUnauthorized();

    const handleChangeTrade = (event: any) => {
        setTrade(event.target.value);
        getTool(event.target.value, toolName);
    };
    const onVendorRegPage = () => {
        navigate("/admin/vendorregistration") 
    };

    const handleTrades = () => {
        validQuestionTrade(accessToken)
            .then(res => {
                handleUnauthorized(res);
                setTradeList(res.data)
            })
    
    }
    React.useEffect(() => {
        (async () => {
            await getTool(null, null);
            tools.current.map((value: any) => {
                (value.tags).split(";").map(trade => {
                    if (!trades.includes(trade)) {
                        trades.push(trade)
                    }
                })
            })
        })()
        setIds([]);
    }, [page, rowsPerPage]);
    React.useEffect(() => {
        handleTrades();
    }, [])
    const updateTool = async (row:any) => {
        let indexNumber = toolData.findIndex(obj => obj.id === row.id);
        let selectedrow = updatedToolData.findIndex(obj => obj.id === row.id);
        let selectedData = updatedToolData[selectedrow];
        setUpdateCount(count + 1);
        let validate = validation(newToolData);
        if (validate === true) {
            if (newToolData?.length !== 0) {
                setOpenDialog(true);
                let result = await updateToolCost(accessToken, newToolData);
                if (result.status === 201) {
                    setUpdateSuccess(true);
                    setIds([]);
                    updatedId.pop(indexNumber);
                    delete updatedRows[indexNumber];
                    getTool(trade, toolName);
                    updatedToolData.pop(selectedData);
                }
                else {
                    setUpdateError(true);
                }
            }
            else {
                setUpdateError(true);
            }
        }
    }

    const addToolItem = () => {
        setOpenAddToolForm(true);
        // ids.push(0 + number);
        // setNumber(number + 1);
        // ids.push(0);
        // getVendors();
        // const tool = {
        //     vendorId: '',
        //     companyName: '',
        //     name: "",
        //     itemCode: "",
        //     tags: '',
        //     unit: "Hour",
        //     versionStartdate: "",
        //     cost: "",
        // };
        // const updateTools = [
        //     tool,
        //     ...toolData
        // ];
        // setToolData(updateTools);
    };

    const inputchange = (e: any) => {
        if ((e.target.value).length === 0) {
            setToolName(e.target.value);
            getTool(trade, null);
        }
        else {
            setToolName(e.target.value);
            getTool(trade, e.target.value);
        }
    };

    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const month = date.getMonth().toString();
        return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
    }
    const onChangeDate = (e: any, row: any, index: any) => {

        let updatedRowIndex = Object.entries(updatedRows).findIndex(updatedrow => updatedrow[0] === index.toString());

        let oldDate = dayjs(row.versionStartdate).format('YYYY-MM-DD')+ "T00:00:00.000Z";
        let newDate = dayjs(e.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z";

        if(updatedRowIndex >= 0){
            if("date" in updatedRows[index]){
                oldDate = updatedRows[index]['date'];
                if(newDate === oldDate){
                    updatedId.pop(index);
                }else{
                    updatedRows[index]['date'] = oldDate;
                    if("cost" in updatedRows[index]){
                        if(!updatedId.includes(index)){
                            updatedId.push(index);
                        }
                    }
                }
            }else{
                updatedRows[index]['date'] = oldDate;
                if("cost" in updatedRows[index]){
                    if(!updatedId.includes(index)){
                        updatedId.push(index);
                    }
                }
            } 
        }else{
            Object.assign(updatedRows, {[index]:{date: newDate}});
        }
        console.log(updatedId, updatedRows);
        //updateId.push(index);
        // setUpdateId([index]);
        row.versionStartdate = dayjs(e.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z";
        let newArr = [...toolData];
        let indexNumber = newArr.findIndex(obj => obj.id === row.id);
        newArr[indexNumber] = row;
        setToolData(newArr);
        let newData = row;
        setNewToolData(newData);
        let updatedIndex = updatedToolData.findIndex(obj => obj.id === row.id);
        if (updatedIndex === -1) {
            updatedToolData.push(row);
        }
        else {
            updatedToolData[updatedIndex] = row;
        }

        // let updatedRow = Object.entries(updateId).map((key:any, value:any)=>{
        //     console.log(key);
        // });
    }
    const onChangeVersionDate = (e: any, index: any) => {
        let newArr = [...toolData];
        newArr[index]["versionStartdate"] = dayjs(e.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z";
        setToolData(newArr);
    }
    const onEdit = (e: any, row: any, index: any) => {
        // updateId.push(index);
        // setUpdateId([index]);

        let updatedRowIndex = Object.entries(updatedRows).findIndex(updatedrow => updatedrow[0] === index.toString());

        let oldCost = row.cost.toString();
        let newCost = e.target.value.toString();

        console.log(updatedRowIndex);
        if(updatedRowIndex >= 0){
            if("cost" in updatedRows[index]){

                oldCost = updatedRows[index]['cost'];

                if(newCost === oldCost){
                    updatedId.pop(index);
                }else{
                    if("date" in updatedRows[index]){
                        if(!updatedId.includes(index)){
                            updatedId.push(index);
                        }
                    }
                }
            }else{
                updatedRows[index]['cost'] = oldCost;
                if("date" in updatedRows[index]){
                    if(!updatedId.includes(index)){
                        updatedId.push(index);
                    }
                }
            } 
        }else{
            Object.assign(updatedRows, {[index]:{cost:oldCost}});
        }
        console.log(updatedId, updatedRows);


        row.cost = e.target.value;
        let newArr = [...toolData];
        let indexNumber = newArr.findIndex(obj => obj.id === row.id);
        newArr[indexNumber] = row;
        setToolData(newArr);
        let newData = row;
        setNewToolData(newData);
        let updatedIndex = updatedToolData.findIndex(obj => obj.id === row.id);
        if (updatedIndex === -1) {
            updatedToolData.push(row);
        }
        else {
            updatedToolData[updatedIndex] = row;
        }
    }
    const toolInputChange = (e: any, index: any) => {
        if (e.target.name === "companyName") {
            let newArray = [...toolData];
            newArray[index][e.target.name] = (e.target.value)?.companyName;
            newArray[index]['vendorId'] = (e.target.value)?.id;
            setVendor(e.target.value);
            setToolData(newArray);
        }
        else if(e.target.name === "cost"){
            let newArray = [...toolData];
            newArray[index][e.target.name] = e.target.value;
            setToolData(newArray);
        }
        else {
            let newArray = [...toolData];
            newArray[index][e.target.name] = e.target.value;
            setToolData(newArray);
        }

    };
    const validation = (newTool: any) => {
        let validated: any = true;
        if (newTool?.companyName === '' || newTool?.tags === '' || newTool?.name === '' || newTool?.itemCode === ''
            || newTool?.cost === '' || newTool?.vendorId === '' || newTool?.unit === '' || newTool?.versionStartdate === ''
        ) {
            validated = false;
        }
        return validated;
    }

    const addTools = async (index) => {
        setCount(count + 1);
        let newTool = toolData[index];
        newTool['tags'] = tradeName.join(';');
        let validate = validation(newTool);
        if (validate === true && count === 1) {
            let result = await addTool(accessToken, [newTool]);
            if (result.status === 201) {
                setOpenSaveDialog(true);
                setSaveSuccess(true);
                setIds([]);
                setCount(0);
                setTradeName([]);
                getTool(null, null);
            }
            else {
                setOpenSaveDialog(true);
                setSaveError(true);
            }
        }
        else{
            console.log("not validated or more " + validate + count)
        }
    }

    const getVendors = async () => {
        let result = await getAllVendors(accessToken);
        setVendorList(result.data);
    }


    const getTool = async (trade: any, toolName: any) => {
        const response = await getToolCount(accessToken, toolName, trade);
        handleUnauthorized(response);
        setToolCount(response.data);
        let result = await searchTools(accessToken, toolName, trade, page, rowsPerPage);
        if((result.data).length !== 0){
            setToolData(result.data);
            tools.current = result.data;
        } 
    }

    const handleTradeNameChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setTradeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const getToolCostBySelectedDateAndMapId = async (selectedDate: any, vendorToolMapId: any, index: any) => {
        let result = await getToolsCost(selectedDate, vendorToolMapId, accessToken);
        handleUnauthorized(result);
        let ToolCostData = result.data;
        if (ToolCostData !== '') {
            let newArr = [...toolData];
            newArr[index]["cost"] = ToolCostData.cost;
            newArr[index]['versionStartdate'] = ToolCostData.versionStartdate;
            setToolData(newArr);
        }
        else {
            setNoChange(true);
        }
    }
    const onChangeVersionHistory = (e: any, row: any, index: any) => {
        getToolCostBySelectedDateAndMapId(dayjs(e.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z", row.vendorToolMapId, index);
    }

    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setNoChange(false)
    }

    const setVendorNameAndId = (e: any, value: any, index: any) => {
        let newArray = [...toolData];
        newArray[index]["companyName"] = value?.companyName;
        newArray[index]['vendorId'] = value?.id;
        // setVendor(e.target.value);
        setToolData(newArray);
    };

    const onCloseDialog = () =>{
        setOpenSaveDialog(false);
        setOpenDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);
        setSaveSuccess(false);
        setSaveError(false);
    }

    const closeForm = () => {
        getTool(null,null);
        setOpenAddToolForm(false);

    }

    return (
        <>
            <Grid container  rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                            <div className='px-3 py-3'>
                                <Grid
                                    container
                                    direction="row"
                                    // justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={12}>
                                        <Typography className='filterHeader'>Search Filters</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-select-small-label">Trade</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={trade}
                                                label="Trade"
                                                onChange={handleChangeTrade}
                                            >
                                                <MenuItem value={"null"}>
                                                    <em>None</em>
                                                </MenuItem>
                                                {trades?.map((trade: any) => {
                                                    return (
                                                        <MenuItem value={trade}>{trade}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} className='alignEnd'>
                                        <CustomDialog
                                            open={openDialog}
                                            onClose={onCloseDialog}
                                            success={updateSuccess}
                                            error={updateError}
                                            Content={updateSuccess ? "Updated Successfully" : "Not Updated"}
                                        />
                                        <CustomDialog
                                            open={openSaveDialog}
                                            onClose={onCloseDialog}
                                            success={saveSuccess}
                                            error={saveError}
                                            Content={saveSuccess ? "Saved" : "Not Saved"}
                                        />
                                    </Grid>

                                </Grid>
                            </div>
                            <Grid item xs={12}><Divider></Divider></Grid>
                            <div className='px-3 py-3'>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <TextField
                                            placeholder="Search by tool name"
                                            size='small'
                                            name="materialName"
                                            value={toolName}
                                            onChange={(e) => inputchange(e)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Typography pl={2}>
                                            <Button variant="contained" size="medium" startIcon={<AddOutlinedIcon fontSize='small' />} sx={{ padding: 1 }}
                                                onClick={addToolItem} 
                                                disabled = {ids.includes(0) ? true : false}>
                                                Add New Tool
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={toolCount}
                                    />
                                    {toolData?.length === 0 ?
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography align="center">No Data</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        :
                                        <TableBody>
                                            {toolData?.map((row, index) => {
                                                const isItemSelected = isSelected(row?.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow

                                                    >
                                                        <TableCell padding="checkbox" size='small'>
                                                        </TableCell>
                                                        {/* <TableCell size='small'>
                                                            {row?.vendorId === '' || ids.includes(index) ?
                                                                <FormControl>
                                                                    <TextField
                                                                        name="vendorId"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        disabled
                                                                        value={row?.vendorId}
                                                                        error={(row?.vendorId === '' && count !== 0)}
                                                                        onChange={(e) => toolInputChange(e, index)}
                                                                    />
                                                                </FormControl> :
                                                                <FormControl>{row?.vendorId} </FormControl>
                                                            }
                                                        </TableCell> */}
                                                        <TableCell size='small'>
                                                            {row?.companyName === '' || ids.includes(index) ?
                                                               <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
                                                               <Autocomplete
                                                                   value={row?.companyName}
                                                                   onChange={(e, newValue) => {
                                                                       if (typeof newValue === 'string') {
                                                                           setVendorNameAndId(e, newValue, index);
                                                                       } else if (newValue && newValue.inputValue) {


                                                                           onVendorRegPage();


                                                                       } else {
                                                                           setVendorNameAndId(e, newValue, index);
                                                                       }
                                                                   }}
                                                                   // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                                                                   filterOptions={(options, params) => {
                                                                       const filtered = filter(options, params);

                                                                       const { inputValue } = params;
                                                                       // Suggest the creation of a new value
                                                                       const isExisting = options.some((option) => inputValue === option.companyName);
                                                                       if (inputValue !== '' && !isExisting) {
                                                                           filtered.push({
                                                                               inputValue,
                                                                               companyName: `Add Vendor`,
                                                                           });
                                                                       }

                                                                       return filtered;
                                                                   }}
                                                                   selectOnFocus
                                                                   clearOnBlur
                                                                   handleHomeEndKeys
                                                                   id="free-solo-with-text-demo"
                                                                   options={vendorList}
                                                                   getOptionLabel={(option) => {
                                                                       // Value selected with enter, right from the input
                                                                       if (typeof option === 'string') {
                                                                           return option;
                                                                       }
                                                                       // Add "xxx" option created dynamically
                                                                       if (option.inputValue) {
                                                                           return option.inputValue;
                                                                       }
                                                                       // Regular option
                                                                       return option.companyName;
                                                                   }}
                                                                   renderOption={(props, option) => <li {...props}>{option.companyName}</li>}

                                                                   freeSolo
                                                                   renderInput={(params) => (
                                                                       <TextField {...params} label="Vendor Name" size='small'
                                                                           error={(row?.companyName === '' && count !== 0)} />
                                                                   )}
                                                               />
                                                           </FormControl>
                                                           :
                                                           <FormControl>{row?.companyName}</FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.tags === '' || ids.includes(index) ?
                                                                <>
                                                                    <FormControl size='small' sx={{ minWidth: 90 }}>
                                                                        <InputLabel id="demo-multiple-checkbox-label">Trade</InputLabel>
                                                                        <Select
                                                                            labelId="demo-multiple-checkbox-label"
                                                                            id="demo-multiple-checkbox"
                                                                            multiple
                                                                            error={tradeName.length === 0 && count !== 0}
                                                                            value={tradeName}
                                                                            onChange={handleTradeNameChange}
                                                                            input={<OutlinedInput label="Trade" />}
                                                                            renderValue={(selected) => selected.join(', ')}
                                                                        //   MenuProps={MenuProps}
                                                                        >
                                                                            {tradeList.map((name) => (
                                                                                <MenuItem key={name} value={name}>
                                                                                    <Checkbox checked={tradeName.indexOf(name) > -1} />
                                                                                    <ListItemText primary={name} />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </> :
                                                                <FormControl>{row?.tags} </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.name === '' || ids.includes(index) ?

                                                                <FormControl>
                                                                    <TextField
                                                                        name="name"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        value={row?.name}
                                                                        error={(row?.name === '' && count !== 0)}
                                                                        onChange={(e) => toolInputChange(e, index)}
                                                                    />

                                                                </FormControl> :
                                                                <FormControl>{row?.name} </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.itemCode === ''  ||  ids.includes(index) ?

                                                                <FormControl>
                                                                    <TextField
                                                                        name="itemCode"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        value={row?.itemCode}
                                                                        error={(row?.itemCode === '' && count !== 0)}
                                                                        onChange={(e) => toolInputChange(e, index)}
                                                                    />
                                                                </FormControl>
                                                                :
                                                                <FormControl>{row?.itemCode} </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>

                                                            <FormControl>

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        // format='DD MMM YYYY'
                                                                        label="Version History"
                                                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                        // disablePast
                                                                        name="versionStartdate"
                                                                        disabled={ids.includes(index) ? true : false}
                                                                        // value={dayjs(row?.versionStartdate)}
                                                                        onChange={(e) => onChangeVersionHistory(e, row, index)}
                                                                    // disableHighlightToday   
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>

                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.versionStartdate === '' || ids.includes(index) ?
                                                                <FormControl>

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            format='MMM DD, YYYY'
                                                                            label="Version Start Date"
                                                                            slotProps={{ textField: { fullWidth: true, size: "small", error: row?.versionStartdate === '' && count !== 0 } }}
                                                                            // disablePast
                                                                            name="versionStartdate"
                                                                            value={row?.versionStartdate === '' ? null : dayjs(row?.versionStartdate)}
                                                                            onChange={(e) => onChangeVersionDate(e, index)}

                                                                        // disableHighlightToday   
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl> :
                                                                <FormControl>

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            format='MMM DD, YYYY'
                                                                            label="Version Start Date"
                                                                            slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                            // disablePast
                                                                            name="versionStartdate"
                                                                            value={dayjs(row?.versionStartdate)}
                                                                            onChange={(e) => onChangeDate(e, row, index)}
                                                                        // disableHighlightToday   
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.unit === '' || ids.includes(index) ?

                                                                <FormControl>
                                                                    <TextField
                                                                        name="unit"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        disabled
                                                                        value={row?.unit}
                                                                        onChange={(e) => toolInputChange(e, index)}
                                                                    />
                                                                </FormControl>
                                                                :
                                                                <FormControl>
                                                                    {row?.unit}
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small' >
                                                            {row?.cost === '' || ids.includes(index) ?
                                                                <FormControl sx ={{minWidth:80}}> 
                                                                    <TextField
                                                                        name="cost"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        type="number"
                                                                        value={row?.cost}
                                                                        InputProps={{
                                                                            inputProps: { min: 0 }
                                                                          }}
                                                                        error={row?.cost === '' && count !== 0}
                                                                        onChange={(e) => toolInputChange(e, index)}
                                                                    />
                                                                </FormControl> :
                                                                <FormControl sx ={{minWidth:80}}>
                                                                    <TextField
                                                                        name="cost"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        type="number"
                                                                        value={row?.cost}
                                                                        error={row?.cost === '' && updateCount !== 0}
                                                                        onChange={(e) => onEdit(e, row, index)}
                                                                    />                                     
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {!ids.includes(index) ?
                                                                <Button variant="contained" size="small" startIcon={<FileUploadOutlinedIcon />}
                                                                    onClick={() => updateTool(row)}
                                                                    disabled={updatedId.includes(index) ? false : true}
                                                                >
                                                                    Update
                                                                </Button>
                                                                :
                                                                <Button variant="contained" size="small" startIcon={<FileUploadOutlinedIcon />}
                                                                    onClick={() => addTools(index)}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                            <AddToolForm open={openAddToolForm} onClose={closeForm}/>
                            <Dialog open={noChange} >
                                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                                    <Box paddingLeft={8} paddingRight={8}>
                                        <Typography textAlign={'center'}>
                                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                        {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                                    </Box>
                                </DialogTitle>
                                <Box paddingLeft={8} paddingRight={8}>
                                    <DialogContent >
                                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Data Found</DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ justifyContent: "center" }}>
                                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                </Grid>
                                <Grid item xs={6} className='px-2'>
                                    <TablePagination
                                        sx={{ border: 'none' }}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={toolCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    ></TablePagination>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>

    );
}

export default ToolList;