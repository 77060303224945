/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-Mar-2025
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : For sales projectmanagement dashboard (sales/Estimate)
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*/

import React from 'react';
import { DialogTitle, Typography, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@mui/material';
import { Box } from '@mui/system';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const PaymentTermWarning = ({ open, onClose, Content }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Dialog open={open} >
                <DialogTitle className='warning'>
                    <Box paddingLeft={4} paddingRight={4}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={4} paddingRight={4}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            {Content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center", marginBottom: '5px' }}>
                        <Button variant='contained' onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default PaymentTermWarning;