import * as React from 'react';
import {
  Box, Card, Button, Typography, TextField, OutlinedInput, InputAdornment, Grid,
  InputLabel, Table, TableHead, TableBody, TableRow, TableCell, FormControl, Select, SelectChangeEvent,
  Stack, IconButton, MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import CustomDialog from './Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getProjectTax, updateProjectTax } from '../services/ProjectService';
import { useNavigate } from 'react-router-dom';

const Taxsettings: React.FC<any> = (props) => {

  const [selectedOption, setSelectedOption] = React.useState<string>("invoice");
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [tenantData, setTenantData] = React.useState<any>(
    {
      invoice: [{
        name: "",
        percentage: "",
        value: "",
      }],
      po: [{
        name: "",
        percentage: "",
        value: "",
      }],
    }
  );
  const [data, setData] = React.useState<any>(
    {
      invoice: [{
        name: "",
        percentage: "",
        value: "",
      }],
      po: [{
        name: "",
        percentage: "",
        value: "",
      }],
    }
  );
  const [oldData, setOldData] = React.useState<any>();
  const [selectedData, setSelectedData] = React.useState<any>([]);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [noChange, setNoChange] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorTab, setErrorTab] = React.useState<any>([]);
  const [readOnly, setReadOnly] = React.useState<any>(false);
  const navigate = useNavigate();


  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value, data[event.target.value]);
    setSelectedOption(event.target.value as string);
    setSelectedData(data[event.target.value]);
  };


  const addItem = () => {
    const newTax = {
      name: "",
      percentage: "",
      value: "",
    };

    const updatePayments = [
      // copy the current users state
      ...data[selectedOption],
      // now you can add a new object to add to the array
      newTax,
    ];
    console.log(data)
    // update the state to the updatedUsers
    setSelectedData(updatePayments);
    setData({ ...data, [selectedOption]: updatePayments });
  };

  const validation = () => {
    let validated: any = true;
    if ((data[selectedOption]).length === 0) {
      validated = false;
    }
    Object.entries(data).map((object: any) => {
      object[1].map(d => {
        if (d.name === "" || d.percentage === "") {
          setError(true);
          if (!errorTab.includes(object[0])) {
            errorTab.push(object[0]);
          }
          validated = false;
        } else {
          errorTab.splice(errorTab.indexOf(object[0]), 1)
        }
      })
    })
    console.log(errorTab)
    return validated;
  }

  const handleInputChange = (e: any, index: any) => {
    if (e.target.name === "percentage") {
      if (e.target.value >= 0 || e.target.value === '') {
        let newArray = [...data[selectedOption]];
        newArray[index][e.target.name] = e.target.value;
        if (error) {
          validation();
        }
        setData({ ...data, [selectedOption]: newArray });
        setSelectedData(newArray);
      }
    } else {
      let newArray = [...data[selectedOption]];
      newArray[index][e.target.name] = e.target.value;
      if (error) {
        validation();
      }
      setData({ ...data, [selectedOption]: newArray });
      setSelectedData(newArray);
    }

  };

  const handleToolRemoveItem = (index: any) => {
    let newArray = [...data[selectedOption]];
    newArray.splice(index, 1)
    setData({ ...data, [selectedOption]: newArray });
    setSelectedData(newArray);
  }

  const handleSubmit = async () => {
    let validated = validation();
    if (validated) {
      let hasChanges = anyChanges();
      if (hasChanges || oldData === undefined) {
        const result = await updateProjectTax(props.project.id, data, accessToken);
        console.log(result);
        if (result.status === 200 || result.status === 201) {
          setOpenDialogs(true);
          setSuccess(true);
          setReadOnly(true);
          fetchData();
        } else {
          setOpenDialogs(true);
          setSuccess(false);
        }
      } else {
        setNoChange(true);
      }
    }
  }

  let objectData: any = {};

  const anyChanges = () => {
    let hasChanges = false;
    if (oldData !== undefined) {
      Object.entries(data).map((object: any) => {
        if (oldData[object[0]].length !== object[1].length) {
          hasChanges = true;
        }
        if (!hasChanges) {
          Object.keys(object[1][0]).map((p: any) => {
            if (object[1][0][p] !== oldData[object[0]][0][p]) {
              hasChanges = true;
            }
          })
        }

      })
    }
    return hasChanges;
  }


  const fetchData = async () => {
    const response = await getProjectTax(props.project.id, accessToken);
    if (response?.data?.invoice !== undefined && response.data.invoice !== null && response?.data?.po !== undefined && response.data.po !== null) {
      setData({ ...data, invoice: response.data.invoice, po: response.data.po });
      Object.assign(data, { invoice: response.data.invoice, po: response.data.po });
      objectData = structuredClone({ invoice: response.data.invoice, po: response.data.po });
      setOldData(objectData);
      setReadOnly(true);
    }
    setSelectedData(data[selectedOption]);
  }

  const handleClose = () => {
    if (success === true) {
      if (props.project.status === "awaitingEstimation") {
        props.tabChange('simple-tab-1', 1);
      } else {
        props.tabChange('simple-tab-0', 0);
      }
    }
    setOpenDialogs(false);
    setSuccess(false);
    setNoChange(false);
  }

  React.useEffect(() => {
    fetchData();
  }, []);


  return (


    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6">
              Tax Terms
            </Typography>
            <div>
              <FormControl fullWidth sx={{ mt: 3, mb: 3, width: 300 }} size='small' error={error && errorTab.length !== 0}>
                <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={handleChange}
                  label="Select Option"
                >
                  <MenuItem value="invoice"><Typography color={errorTab.includes("invoice") ? 'error' : "inherit"}>Invoice</Typography></MenuItem>
                  <MenuItem value="po"><Typography color={errorTab.includes("po") ? 'error' : "inherit"}>PO</Typography></MenuItem>
                </Select>
              </FormControl>
              <div>
                <Typography variant="h6">
                  <Table sx={{ border: '1px solid lightgrey' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}
                        >
                          Tax Name
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}
                        >
                          Percentage
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedData?.map((tax: any, index: any) => {
                        return (
                          <TableRow>
                            <TableCell sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}>
                              <TextField
                                label="Tax Name"
                                id="outlined-size-small"
                                size="small"
                                fullWidth
                                value={tax?.name}
                                error={tax?.name === '' && error}
                                name="name"
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                            <TableCell sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}>
                              <Stack direction={'row'}>
                                <FormControl variant="outlined" size='small' fullWidth error={(tax?.percentage === '' && error)}>
                                  <InputLabel htmlFor="outlined-adornment-password">Percentage</InputLabel>
                                  <OutlinedInput
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <div>
                                          %
                                        </div>
                                      </InputAdornment>
                                    }
                                    fullWidth
                                    name='percentage'
                                    value={tax?.percentage}
                                    onChange={(e) => handleInputChange(e, index)}
                                    inputProps={{ min: "0", max: "100", step: "1" }}
                                    label="percentage"
                                    type='number'
                                    required
                                  />
                                </FormControl>
                                {index !== 0 && <IconButton onClick={() => handleToolRemoveItem(index)}>
                                  <DeleteRoundedIcon />
                                </IconButton>}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2} // Adjust spacing between the buttons
                    sx={{ padding: 2 }} // Optional padding for the container
                  >
                    <Button onClick={addItem}>Add Row</Button>
                  </Stack>
                </Typography>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container justifyContent="flex-end">
            <Box m={2} className="poFooter">
              <Button
                className="sucessButton"
                variant="contained"
                size="medium"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        open={openDialogs}
        onClose={handleClose}
        success={success}
        error={!success}
        Content={success ? "Tax Updated Successfully" : " Error Couldnot Update Tax "} />

      <Dialog open={noChange} >
        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="NoChangeButton" onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box >
  )
};

export default Taxsettings;
