import * as React from 'react';
import { Box, TextField, Paper, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomDialog from '../../../pages/Dialog';
import { addMailData, editMailData, getMailData } from '../../../services/MailConfigurationService';
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { editFinanceData } from '../../../services/FinanceSettingService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHandleUnauthorized } from '../../../hooks/UserSession';

const security = [
    {
        value: 'None',
        label: 'None',
    },
    {
        value: 'STARTTLS',
        label: 'STARTTLS',
    },
    {
        value: 'TLS',
        label: 'SSL/TLS',
    },

];

// const Authentication = [
//     {
//         value: 'Authentication',
//         label: 'No Authentication',
//     },
//     {
//         value: 'Password',
//         label: 'Password, transmitted insecurely',
//     },
//     {
//         value: 'Encrypted',
//         label: 'Encrypted Password',
//     },

//     {
//         value: 'Kerbros',
//         label: 'Kerbros/GSSAPI',
//     },

//     {
//         value: 'ntlm',
//         label: 'NTLM',
//     },

// ];



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const Mailcontent: React.FC = () => {

    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [show, setshow] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [initialData, setInitialData] = React.useState<any>();
    const [noChange, setNoChange] = React.useState(false);
    // const password ="hykfrmxnkvdgdsni";
    const [tenantmailData, setTenantData] = React.useState<any>(
        {
            email: '',
            password: '',
            servername: '',
            port: 0,
            security: 'None',
            name: '',
        });
    const { handleUnauthorized } = useHandleUnauthorized();


    const onclose = () => {

        setOpenDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);

    }

    const inputChange = (e: any) => {
        setTenantData({
            ...tenantmailData,
            [e.target.name]: e.target.value
        });
    };


    const validation = () => {
        let validated: any = true;
        if (tenantmailData.email === '') {
            validated = false;
        }
        if (tenantmailData.password === '') {
            validated = false;
        }
        if (tenantmailData.servername === '') {
            validated = false;
        }
        if (tenantmailData.port === '') {
            validated = false;
        }
        if (tenantmailData.security === '') {
            validated = false;
        }
        if (tenantmailData.name === '') {
            validated = false;
        }
        return validated;
    }


    const handleSubmit = async (e: any) => {
        setCount(count + 1);
        console.log(tenantmailData)
        let finalData = {
            "key": "mailConfig",
            "data": JSON.stringify(tenantmailData)
        }

        let validated = validation();
        if (validated === true) {
            setOpenDialogs(true);
            console.log(finalData);
            let result = await addMailData(finalData, accessToken);
            if (result.status === 201 || result.status === 200) {
                setCreateSuccess(true);
                setshow(false);
            }
            else {
                setCreateError(true);
            }
        }
        else {
            setCreateError(true);
        }
    }

    const handleUpdateSubmit = async () => {
        setCount(count + 1);
        console.log(tenantmailData)
        let finalData = {
            "key": "mailConfig",
            "data": JSON.stringify(tenantmailData)
        }
        let validated = validation();

        console.log(finalData);
        if (initialData.email === tenantmailData?.email
            && initialData.password === tenantmailData?.password
            && initialData.servername === tenantmailData.servername
            && initialData.port === tenantmailData.port
            && initialData.security === tenantmailData.security &&
            initialData.name === tenantmailData.name) {

            setNoChange(true);
        }
        else {
            if (validated === true) {
                setOpenDialog(true);
                let result = await editMailData(finalData, accessToken);
                console.log(result);
                if (result.status === 200) {
                    setUpdateSuccess(true);
                }
                else {
                    setUpdateError(true);
                }
            }
            else {
                setOpenDialog(true);
                setUpdateError(true);
            }
        }
    }

    const getMailinfo = async () => {
        try {
            let result = await getMailData("mailConfig", accessToken);
            handleUnauthorized(result)
            console.log(result)
            setInitialData(JSON.parse(result.data));
            if (result.data && result.data !== '{}') {
                setTenantData(JSON.parse(result.data));
                setshow(false);
            } else {
                setshow(true);
            }

        } catch (error) {
            console.error("Error fetching mail info:", error);
            setshow(true);
        }
    }

    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }



    React.useEffect(() => {
        getMailinfo();
    }, [count]
    )
    return (

        <Box sx={{ flexGrow: 1, p: 5 }}>

            <Typography variant="subtitle2" gutterBottom>
                Login Information
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Email Address"
                        size="small"
                        fullWidth
                        name="email"
                        value={tenantmailData?.email}
                        error={tenantmailData.email === '' && count !== 0}
                        onChange={(e) => inputChange(e)}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Password"
                        size="small"
                        fullWidth
                        // type="password"
                        value={tenantmailData.password}
                        error={tenantmailData.password === '' && count !== 0}
                        // autoComplete="current-password"              
                        name="password"
                        onChange={(e) => inputChange(e)}
                        variant="outlined"
                        aria-readonly />
                </Grid>
                <Grid item xs={6}></Grid>


                {/* <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Email Address"
                        size="small"
                        fullWidth
                        defaultValue=""
                        variant="outlined" />
                </Grid>

                <Grid item xs={6}></Grid> */}

                {/* <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Password"
                        size="small"
                        fullWidth
                        type="password"
                        // autoComplete="current-password"              
                        defaultValue=""
                        variant="outlined" />
                </Grid>

                <Grid item xs={6}></Grid> */}

            </Grid>

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
                Server Information
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Outgoing mail server name"
                        size="small"
                        fullWidth
                        name="servername"
                        value={tenantmailData.servername}
                        error={tenantmailData.servername === '' && count !== 0}
                        onChange={(e) => inputChange(e)}
                        // InputLabelProps={{
                        //     shrink: true,
                        //   }}

                        variant="outlined" />
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Port Address"
                        size="small"
                        fullWidth
                        defaultValue=""
                        value={tenantmailData.port}
                        error={tenantmailData.port === '' && count !== 0}
                        type='number'
                        variant="outlined"
                        name="port"
                        onChange={(e) => inputChange(e)}
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        size="small"
                        fullWidth
                        label="Connection security"
                        value={tenantmailData.security}
                        error={tenantmailData.security === '' && count !== 0}
                        name="security"
                        onChange={(e) => inputChange(e)}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {security.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={6}></Grid>
            </Grid>

            {/* <Typography variant="subtitle2" gutterBottom sx={{ mt: 3, mb: 2 }}>
                Security and Authentication
            </Typography>

            <Grid container spacing={2}>
               

                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        size="small"
                        fullWidth
                        label="Authentication method                        :          "
                        defaultValue="None"
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {Authentication.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}></Grid>

            </Grid> */}

            <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
                User Information
            </Typography>

            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="User Display Name"
                        size="small"
                        fullWidth
                        name="name"
                        value={tenantmailData.name}
                        error={tenantmailData.name === "" && count !== 0}
                        onChange={(e) => inputChange(e)}
                        variant="outlined" />
                </Grid>

                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                        <Box m={2} className="poFooter">
                            <Grid item xs={12}>

                                <Button
                                    className="button"
                                    variant="contained"
                                    size="medium"
                                    onClick={handleSubmit}
                                    disabled={!show}
                                >
                                    Save
                                </Button>

                                <Button
                                    className="button"
                                    variant="contained"
                                    size="medium"
                                    onClick={handleUpdateSubmit}
                                    disabled={show}
                                >
                                    Update
                                </Button>

                            </Grid>

                            <Grid item xs={6}></Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <CustomDialog
                open={openDialogs}
                onClose={() => setOpenDialogs(false)}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Created Successfully" : " Error Couldnot Created "} />

            <CustomDialog
                open={openDialog}
                onClose={() => onclose()}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Updated Successfully" : " Error Couldnot Updated"} />

            <Dialog open={noChange} >
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>

    );
}

export default Mailcontent;
