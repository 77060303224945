/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 21-Aug-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui to show reference project list
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { visuallyHidden } from '@mui/utils';
import { Backdrop, Box, Checkbox, CircularProgress, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getReferenceProjects, getReferenceProjectsCount } from '../../services/ProjectService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import { saveTemplateTasks } from '../../services/TaskService';
import ReferenceProjectWarningDialog from './ReferenceProjectWarningDialog';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { useHandleUnauthorized } from '../../hooks/UserSession';

interface Data {
    id: number;
    projectId: number,
    projectName: string;
    unitCost: string;
    dateCompleted: string;
    relevanceScore: string;
}

function createData(
    id: number,
    projectId: number,
    projectName: string,
    unitCost: string,
    dateCompleted: string,
    relevanceScore: string,
): Data {
    return {
        id,
        projectId,
        projectName,
        unitCost,
        dateCompleted,
        relevanceScore,
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'projectId',
        numeric: false,
        disablePadding: true,
        label: 'Project Id',
    },
    {
        id: 'projectName',
        numeric: true,
        disablePadding: false,
        label: 'Project Name',
    },
    {
        id: 'unitCost',
        numeric: true,
        disablePadding: false,
        label: 'Cost',
    },
    {
        id: 'dateCompleted',
        numeric: true,
        disablePadding: false,
        label: 'Completed Date',
    },
    {
        id: 'relevanceScore',
        numeric: true,
        disablePadding: false,
        label: 'Relevance Score',
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead >
            <TableRow className='tableHead'>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel

                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const ReferenceProjectList: React.FC<any> = (props) => {
    const [rows, setRows] = React.useState<any>([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('projectName');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const [count, setCount] = React.useState(Number);
    const referenceProjectStatus = "Completed";
    const { accessToken } = useOidcAccessToken();
    const [referenceProjectId, setReferenceProjectId] = React.useState<any>("null");
    const [warning, setWarning] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const { handleUnauthorized } = useHandleUnauthorized();
    const [loading, setLoading] = React.useState<boolean>(true);

    const handleClose = () => {
        props.onClose();
        setSelected([]);
        setReferenceProjectId("null")
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
    }

    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        if (referenceProjectId === id) {
            setReferenceProjectId("null");
        }
        else {
            setReferenceProjectId(id);
        }
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getReferenceProject = async () => {
        // let referenceCount = await getReferenceProjectsCount(props?.projectData?.trade, referenceProjectStatus, accessToken);
        // setCount(referenceCount.data);
        let result = await getReferenceProjects(props?.projectData?.id, accessToken);
        // let result = await getReferenceProjects(props?.projectData?.trade, accessToken);
        handleUnauthorized(result)
        if(result.status === 200){
            setRows(result.data);
            setLoading(false);
        }
        
    }

    const handleTabChange = () => {
        props.tabChange('simple-tab-1', 1);
    }

    const saveSelectedProject = async () => {
        setIsLoading(true);
        let result = await saveTemplateTasks(props?.projectData?.id, props?.projectData?.trade, accessToken);
        if (result.status === 200 && result.data !== null && result.data !== "") {
            handleClose();
            setIsLoading(false);
            handleTabChange();
            handleCloseWarning();
        }
        else {
            setIsLoading(false);
            setError(true);
        }
    }

    const handleCloseWarning = () => {
        setWarning(false);
    }

    const openWarning = () => {
        if (referenceProjectId !== "null") {
            setWarning(true);
        }
        else {
            saveSelectedProject();
        }

    }

    React.useEffect(() => {
        getReferenceProject();
    }, [props]);

    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'md'}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                // onClick={handleLoadingClose}
                >
                    <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle>Please Choose the relevant completed project to continue</DialogTitle>
                <DialogContent >
                    <DialogContentText sx={{ marginBottom: 2 }}>
                        Completed Projects:
                    </DialogContentText>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table
                                stickyHeader aria-label="sticky table"
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows?.length}
                                />
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography align="center">Loading...  <CircularProgress size={20} /></Typography>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <>
                                            {rows?.length === 0 ?
                                                (<TableRow>
                                                    <TableCell colSpan={6}>
                                                        <Typography align="center">No Reference Project</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                ) : (
                                                    <> {rows?.map((row, index) => {
                                                        const isItemSelected = isSelected(Number(row?.id));
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                onClick={(event) => handleClick(event, Number(row?.id))}
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                selected={isItemSelected}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                                {/* <TableCell padding="checkbox">
                                                                <CustomCheckbox
                                                                    className='check'
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell> */}
                                                                <TableCell
                                                                // className='tablerows'
                                                                // component="th"
                                                                // id={labelId}
                                                                // scope="row"
                                                                // padding="none"
                                                                >
                                                                    {row?.id}
                                                                </TableCell>
                                                                <TableCell >{row?.description}</TableCell>
                                                                <TableCell >$ {row?.totalCost}</TableCell>
                                                                <TableCell >{dayjs(row?.endDate).format("MMM DD, YYYY")}</TableCell>
                                                                <TableCell >{row?.relevanceScore}%</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}</>)
                                            }
                                        </>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            sx={{ border: 'none', }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={openWarning}>Continue</Button>
                </DialogActions>
            </Dialog>
            <ReferenceProjectWarningDialog open={warning} onClose={handleCloseWarning} projectId={props?.projectData?.id}
                referenceId={referenceProjectId} handleChangeTab={handleTabChange} closeReferenceProject={handleClose} />
            <CustomDialog
                open={openDialog}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "" : "Error"} />
        </React.Fragment>
    );
}
export default ReferenceProjectList;