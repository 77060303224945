/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-March-2024
* @author      : Vijay kumar S
* @since       : 0.0.1
* Requirement# :
* Purpose      : JobLedger Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { getPODetails } from '../data/PODetails'
import { vendorDetails } from '../data/VendorDetails'
import { executeGetData, executePostData } from './ApiBase';
import { ConfigUrl } from '../auth/OidcConfig';

// export const getPoDetails = async () => {
//   const url = getPODetails;
//   return url;
// };
export const getJobLedgerList = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/jobLedgers/getJobLedger/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getJobLedgerExpense = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/jobLedgers/getLabourExpense/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getJobLedgerToolsExpense = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/jobLedgers/getToolExpense/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}
// export const getJobLedgerMaterialExpense = async (projectId: any, accessToken: any) => {
//   const url = `${ConfigUrl.baseURL}/jobLedgers/getMaterialExpense/projectId/${projectId}`;
//   return await executeGetData(url, accessToken);
// }
export const getExpense = async (projectId: any, code:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/jobLedgers/getExpense/projectId/${projectId}/code/${code}`;
  return await executeGetData(url, accessToken);
}

export const getWipReport = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/jobLedgers/getWipReport/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getInvoiceReceives = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/paymentReceives/getInvoiceReceives/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getExpenses = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/paymentExpenses/getListPaymentExpense/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getCOAAccountTypes = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/chartofaccount/getallaccounttypes`;
  return await executeGetData(url, accessToken);
}

export const getAllCodes = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/jobLedgers/getAllCodes`;
  return await executeGetData(url, accessToken);
}