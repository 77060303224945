/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   :22-apr-2024
* @author      : Priyankaa
* @since       : 0.0.1
* Requirement# :
* Purpose      :uploading  tenant contract template----------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem                                                                                           |  DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* #2065 Create ui for collecting signature of authorized person in trade contract                           28/05/2024   Asish
 #2066 created functionality to collect tenant signature  in trade contract                                 28/05/2024   Asish
 #2067 created functionality to convert signature to multipart file and send it to api in trade contract    28/05/2024   Asish
 #2068 integrated  api to get signature from blob  to get the admin signature  in trade template            28/05/2024   Asish
 #2069 removed signature implemenattion from general configuration                                          28/05/2024   Asish
*
*
*/
import * as React from "react";
import {
  Button, Typography, Box, Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Avatar,
  Modal,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import "../../Tenanatconfiguration.scss";
import SignatureCanvas from 'react-signature-canvas';

import ContractTemplate from "../../../contractTemplate/ContractTemplate";
import { contractUpload, getContract } from "../../../../services/ContractSigningService";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { createSignatureContract, getSignature, getTenantDetails } from "../../../../services/TenantService";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { getTenantDetails } from "../../../../services/TenantService";
import { Code } from "@mui/icons-material";
import { useHandleUnauthorized } from "../../../../hooks/UserSession";

import AddressModule from "../../../ReactQuil/AddressModule";

const containerStyle = {
  width: "100%",
  height: "300px",
};
const libraries: any = ["places"];

export default function Tradecontract() {

  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [successType, setSuccessType] = React.useState('');
  const [tenantDetails, setTenantDetails] = useState<any>();
  const [signature, setSignature] = useState<string | null>(null);
  // const [editorHtml, setEditorHtml] = useState('<h2>Contract Signing</h2><p>Sign below:</p>');
  const editorRef = React.useRef<ReactQuill>(null); // Explicitly define the type of editorRef
  const [sign, setSign] = useState<string>(""); // Declare sign state variable
  const signatureRef = React.useRef<any>(null); // Declare signatureRef with its type
  const [openSignature, setOpenSignature] = useState<boolean>(false); // Declare openSignature state variable
  const [editorHtml, setEditorHtml] = useState('');
  const [file, setFile] = useState<File | any>(null);
  // console.log(file);
  const [validation, setValidation] = React.useState(false);
  const [noChange, setNoChange] = React.useState(false);
  console.log(validation);
  const [initialValue, setInitiailValue] = React.useState<any>("")
  const { handleUnauthorized } = useHandleUnauthorized();


  const getTenantConfig = async () => {
    const response = await getTenantDetails(accessToken);
    ////console.log(response.data)
    handleUnauthorized(response);
    let details = response.data
    // //console.log(details)
    setTenantDetails(details);
  }

  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
  ];
  
  const modules = {
    toolbar: [
      [{ font: ["sans-serif", "serif", "monospace"] }], // Added monospace font
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };
  
  const formats = [
    "font", // Include font support
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
  ];
  

  const [code, setCode] = useState(`
      <h2><span>[trade] Construction Contract Agreement</span></h2>
      </br> 
      <p>This Construction Contract Agreement ("Agreement") is made and entered into as of [Date], by and between: </br> </br></p>

    <table id="quillAddress" style="width: 100%; font-family: 'Courier New', monospace; border: 'none !important'; border-collapse: collapse;">
   
    <tr>
        <td style="border: 1px solid white; padding: 5px;"><strong>Client:</strong></td>
        <td style=" border: 1px solid white; padding: 5px;"><strong>Contractor:</strong></td>
    </tr>
    <tr>
        <td style="border: 1px solid white; padding: 5px;"> [Customer Name]</td>
        <td style=" border: 1px solid white; padding: 5px;">    [Contractor Name]</td>
    </tr>
    <tr>
        <td style="border: 1px solid white; padding: 5px;">[Customer Address]</td>
        <td style=" border: 1px solid white; padding: 5px;">   [Contractor Address]</td>
    </tr>
      <tr>
        <td style="border: 1px solid white; padding: 5px;"> [City, State, ZIP Code]</td>
        <td style=" border: 1px solid white; padding: 5px;">   [Tenant Email Address]</td>
    </tr>
     <tr>
        <td style="border: 1px solid white; padding: 5px;">       [Customer Email Address]</td>
        <td style=" border: 1px solid white; padding: 5px;">    [Tenant Phone Number]</td>
    </tr>
        <tr>
        <td style="border: 1px solid white; padding: 5px;">     [Customer Phone Number]</td>
        <td style="border: 1px solid white; padding: 5px;">   </td>
    </tr>
</table>
</br>

      <p>Hi [Customer Name],</p>
      </br> 
      <p>Here is your [trade] layout and foundation drawing. Below are the requirements/details we'll need to get this into production. Once you are ready to confirm, we'd need the following:</p>
      </br> 
      <p><strong>1. Signed Documents</strong></p>
      <ul>
        <li>Final Order form & layouts</li>
        <li>Terms and Conditions</li>
        <li>Watertight building expectations</li>
      </ul>
      </br> 
      
      <p><strong>2. </strong>The Watertight Building Expectations document included is to make sure you, as the end-user, know how to plan your space. We don't recommend couches, pianos, or artwork in the [trade], as it is considered a wet environment.
</p>
      </br> 
      <p><strong>3. Contract Price:</strong> The total contract price for the work described in this Agreement is [Total Amount], payable as follows:</p>
      <ul>
        <li>Initial deposit of $[Deposit Amount] upon signing this Agreement.</li>
        <li>Final payment of $[Final Payment Amount] upon completion and acceptance of the work.</li></br> 
      </ul>
      <p>Checks can be mailed to our office address listed above. If you prefer making a wire transfer, let us know and we'll send over instructions.</p></br> 
                  
      <p>Thanks again [Customer Name] for setting up your [trade] with [Contractor Name], and let us know if we can assist you with anything else.</p></br> 
      
      <p><strong>Signatures:</strong></p>
      <br>
      <p>[Customer Name]<br>
      Date: [Date]</p>
      <br>
      <p>[Contractor Name]<br>
      Date: [Date]</p>
      `);

  const [storedContract, setStoredContract] = useState();
  const [str, setStr] = useState("");
  const handleProcedureContentChange = (content: any) => {
    setCode(content);
  };
  const getContractSignature = async () => {
    await getContract(accessToken).then((responseData) => {
      handleUnauthorized(responseData);

      if (responseData.data !== "") {
        setCode(responseData.data);
        setStoredContract(responseData.data);
        console.log(responseData)
      }
    })
    await getSignature(accessToken).then((res) => {
      handleUnauthorized(res);

      console.log(res);
      const imgTag = res?.data;
      //console.log(res.data)
      const srcMatch = imgTag?.match(/src="([^"]+)"/);

      if (srcMatch && srcMatch[1]) {
        const srcValue = srcMatch[1];
        setFile(srcValue)
        // setCode((prevHtml) => `${prevHtml}${imgTag}`);

        // setFile(srcValue);
      } else {
        console.error("No src attribute found in the image tag.");
      }
    }
    );
    ////console.log(response);
  }
  const handlematrecSave = () => {
    ////console.log(signatureRef.current)
    setValidation(true);
    if (signatureRef.current) {
      // Get a reference to the canvas element
      const canvas = signatureRef.current.getCanvas();

      // Create a temporary canvas with the desired dimensions
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = 150; // Set the new width
      tempCanvas.height = 100; // Set the new height

      // Draw the original canvas content onto the temporary canvas with the new dimensions
      const tempCtx = tempCanvas.getContext('2d');
      if (tempCtx) {
        tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);
      }

      // Convert the temporary canvas to a data URL
      const signatureBase64 = tempCanvas.toDataURL();
      const updatedEditorHtml = `<img src="${signatureBase64}" alt="Signature" />`;
      console.log("sign", updatedEditorHtml)
      setEditorHtml(updatedEditorHtml);
      setFile(signatureBase64)

      // Create a download link for the signature image
      const link = document.createElement('a');
      link.href = signatureBase64;

      // Update state with the signature data
      setSign(signatureBase64.replace(/^data:image\/(png|jpeg);base64,/, ""));
      setSignature(signatureBase64);
    }
    setOpenSignature(false);
  };
  const handleSignatureToBlob = async () => {
    try {
      // setOpenSignature(true);
      const formData = new FormData();
      const blob = new Blob([editorHtml], { type: 'text/html' });
      if (blob.size === 0) {
        // setError(true);
        // setSuccessType("update");
        // setValidation(false);
      }
      else {

        formData.append('file', blob || file, 'signature.html');
        console.log(formData);
        const response = await createSignatureContract(formData, accessToken);
        console.log(response)
        // setEditorHtml(response);
        if (response.status === 200) {
          // setValidation(true);
          setSuccess(true);
        } else {
          setError(true);
        }
      }
    } catch (error) {
      console.error("Error while saving to database:", error);
    }
  };
  const handlematrecCancel = () => {
    setOpenSignature(false);
  };
  const handlematrecOpen = () => {
    setOpenSignature(true);
  };

  const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

    setNoChange(false);

  }




  const handleSave = async () => {
    // await handleValidation();
    if (code !== storedContract || validation) {
      try {
        console.log(code);
        const formData = new FormData();
        const blob = new Blob([code], { type: 'text/html' });
        formData.append('file', blob, 'contract.html');
        handleSignatureToBlob();
        // if(validation){
        const response = await contractUpload(formData, accessToken);

        if (response.status === 200 || response.status === 201) {
          setValidation(false);
          setSuccess(true);

        }
        else {
          setError(true)
        }
        // }
      }



      catch (error) {
        console.error("Error while saving to database:", error);
      }
      setValidation(false)

    }
    else {
      console.log(" no change in contract");
      // setError(true);
      setNoChange(true);
    }
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccess(false);
    setError(false);
    getContractSignature();
  }

  React.useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      if (code !== undefined) {
        quill.clipboard.dangerouslyPasteHTML(code);

      }
      else {
        quill.clipboard.dangerouslyPasteHTML(code);
      }

    }
    getTenantConfig();
    // getContractInformation();
    getContractSignature();
    // getContractSignature();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>

      <Grid
        container
        sx={{ p: 0 }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >

        <Grid item xs={12} md={12}>
          <Grid
            container
            sx={{ p: 0 }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} md={12}>
              <Avatar alt="Remy Sharp" src={tenantDetails?.tenantLogo} />
              <AddressModule
               value={code}
              readOnly={false} 
              onChange={handleProcedureContentChange}           
               
              />

            </Grid>

            <Grid item xs={12} md={12}>
              {/* <ContractTemplate /> */}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={10}>
                {/* [Signature of Contractor]
                [Printed Name of Contractor]
                [Date] */}
              </Grid>
              <Grid item xs={12} md={2}>
                <Box
                  //sx={{ margin: "8px" }}
                  //p={2}
                  width={100}
                  height={100}
                  margin="20px auto"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                ><img src={file} alt="" /></Box>
                {/* {file} */}
                {/* [Signature of Customer] */}
                <Typography>{accessTokenPayload.name}</Typography>
                {moment().format('DD MMM YYYY')}
              </Grid>
            </Grid>

            <Grid>
              <Modal
                open={openSignature}
                aria-labelledby="signature-pad-modal"
              >
                <div
                  style={{
                    position: "fixed",
                    top: 100,
                    right: 100,
                    bottom: 100,
                    left: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 500, // Set your desired height
                    width: 500, // Set your desired width
                    backgroundColor: "white",
                    margin: "0 auto",
                  }}
                >
                  <h4>Please put your signature here</h4>
                  <Grid sx={{ border: "1px solid" }}>
                    <SignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{ width: 400, height: 250 }}
                    />
                  </Grid>

                  <div
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <Button
                      sx={{ margin: "8px" }}
                      variant="contained"
                      onClick={() => signatureRef.current.clear()}
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{ margin: "8px" }}
                      className='SucessSolid'
                      onClick={handlematrecSave}
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ margin: "8px" }}
                      className='CancelSolid'
                      onClick={handlematrecCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Modal>
 
            </Grid>

            <Grid container spacing={1} mt={1}>
     
              <Grid item xs={12} md={1} >

              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Button
                sx={{ minWidth: "100px", ml: "auto", marginRight: '7px' }}
                className="primaryTonalButton"
                onClick={handlematrecOpen}

              >
                Signature
              </Button>
              <Button
                className={`primaryTonalButton`}
                variant="contained"
                size="small"
                onClick={handleSave}
                disabled={file === null} // Disable button if file is null

              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Dialog open={success} >
          <DialogTitle className='success'>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                Success
              </Typography>
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent >
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Contract Upload Successfully</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Dialog open={error} >
          <DialogTitle className='error'>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                Error
              </Typography>
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent >
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType !== "update" ? "Error Couldn't Upload Contract" : "Please update signature also"}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
            </DialogActions>
          </Box>

        </Dialog>

        <Dialog open={noChange}>
          <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} />
              </Typography>
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent>
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                No Value has been Changed
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
            </DialogActions>
          </Box>
        </Dialog>


      </Grid>
    </Box>
  );
}
