import * as React from 'react';
import {
    Box, Button, TextField, Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@mui/material';
import CustomDialog from '../../../pages/Dialog';
import Header from '../../Header';
import { addMetrics, getMetricsData, updateTenantConfigData } from '../../../services/MailConfigurationService';
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHandleUnauthorized } from '../../../hooks/UserSession';


const ProcessMetrics: React.FC = () => {

    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [initialData, setInitialData] = React.useState<any>();
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [noChange, setNoChange] = React.useState(false);
    const { handleUnauthorized } = useHandleUnauthorized();

   
    const [metricData, setMetricData] = React.useState<any>(
        {
            estimate: "",
            sales: "",
            preproduction: "",
            activeconstruction: "",
            postproduction: "",
            billing: ""
        });

    const validation = () => {
        let validated: any = true;
        if (metricData.estimate === '') {
            validated = false;
        }
        if (metricData.sales === '') {
            validated = false;
        }
        if (metricData.preproduction === '') {
            validated = false;
        }
        if (metricData.activeconstruction === '') {
            validated = false;
        }
        if (metricData.postproduction === '') {
            validated = false;
        }
        if (metricData.billing === '') {
            validated = false;
        }
        return validated;
    }

    const handleUpdateSubmit = async () => {
        setCount(count + 1);

        let finalData = {
            "key": "metrics",
            "data": JSON.stringify(metricData)
        }
        let validated = validation();

        if (initialData.estimate === metricData?.estimate
            && initialData.sales === metricData?.sales
            && initialData.preproduction === metricData.preproduction
            && initialData.activeconstruction === metricData.activeconstruction
            && initialData.postproduction === metricData.postproduction &&
            initialData.billing === metricData.billing) {

            setNoChange(true);
        }
        else {
            if (validated === true) {
                setOpenDialog(true);
                let result = await updateTenantConfigData(finalData, accessToken);
                if (result.status === 200) {
                    setUpdateSuccess(true);
                }
                else {
                    setUpdateError(true);
                }
            }
            else {
                setOpenDialog(true);
                setUpdateError(true);
            }
        }
    }

    const handleSubmit = async (e: any) => {
        setCount(count + 1);
        e.preventDefault();
        let finalData = {
            "key": "metrics",
            "data": JSON.stringify(metricData)
        }

        let validated = validation();
        if (validated === true) {
            setOpenDialogs(true);
            const res = await addMetrics(finalData, accessToken)
            if (res.status === 201 || res.status === 200) {
                setCreateSuccess(true);
                fetchData();
            }
            else {
                setCreateError(true);
            }
        }
        else {
            setCreateError(true);
        }
    }

    const inputChange = (e: any) => {
        setMetricData({
            ...metricData,
            [e.target.name]: e.target.value
        });
    }

    const fetchData = async () => {
        const response = await getMetricsData("metrics", accessToken);
        handleUnauthorized(response)
        let data = JSON.parse(response.data);
        if (data != null){
            setIsUpdate(true);
            setInitialData(data);
            setMetricData(data);
        }
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box sx={{ flexGrow: 1, p: 5 }}>

            <Header title="THRESHOLDS" subtitle={""} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Estimate to Sales"
                        type='number'
                        size="small"
                        fullWidth
                        error={metricData?.estimate === '' && count !== 0}
                        value={metricData?.estimate !== undefined ? metricData?.estimate : ""}
                        name="estimate"
                        onChange={(e) => inputChange(e)}
                        InputProps={{
                            inputProps: { min: 1 }
                          }}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Sales to Preproduction"
                        type='number'
                        size="small"
                        fullWidth
                        error={metricData?.sales === '' && count !== 0}
                        value={metricData?.sales !== undefined ? metricData?.sales : ""}
                        name="sales"
                        onChange={(e) => inputChange(e)}
                        InputProps={{
                            inputProps: { min: 1 }
                          }}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="Preproduction to ActiveConstruction"
                        type='number'
                        size="small"
                        fullWidth
                        error={metricData?.preproduction === '' && count !== 0}
                        value={metricData?.preproduction !== undefined ? metricData?.preproduction : ""}
                        name="preproduction"
                        onChange={(e) => inputChange(e)}
                        InputProps={{
                            inputProps: { min: 1 }
                          }}
                        variant="outlined" />
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField id="outlined-basic"
                        label="ActiveConstruction to Postproduction"
                        type='number'
                        size="small"
                        fullWidth
                        error={metricData?.activeconstruction === '' && count !== 0}
                        value={metricData?.activeconstruction !== undefined ? metricData?.activeconstruction : ""}
                        defaultValue=""
                        variant="outlined"
                        name="activeconstruction"
                        onChange={(e) => inputChange(e)}
                        InputProps={{
                            inputProps: { min: 1 }
                          }}
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency-native"
                        type='number'
                        size="small"
                        fullWidth
                        error={metricData?.postproduction === '' && count !== 0}
                        value={metricData?.postproduction !== undefined ? metricData?.postproduction : ""}
                        label="Postproduction to Billing"
                        name="postproduction"
                        onChange={(e) => inputChange(e)}
                        InputProps={{
                            inputProps: { min: 1 }
                          }}
                    >
                    </TextField>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-select-currency-native"
                        type='number'
                        size="small"
                        fullWidth
                        error={metricData?.billing === '' && count !== 0}
                        value={metricData?.billing !== undefined ? metricData?.billing : ""}
                        label="Billing to Estimate"
                        name="billing"
                        onChange={(e) => inputChange(e)}
                        InputProps={{
                            inputProps: { min: 1 }
                          }}
                    >
                    </TextField>
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                        <Box m={2} className="poFooter">
                            <Grid item xs={12}>

                                {isUpdate ? <Button
                                    className="button"
                                    variant="contained"
                                    size="medium"
                                    onClick={handleUpdateSubmit}
                                >
                                    Update
                                </Button> :
                                <Button
                                className="button"
                                variant="contained"
                                size="medium"
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>}
                            </Grid>

                            <Grid item xs={6}></Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <CustomDialog
                open={openDialogs}
                onClose={() => setOpenDialogs(false)}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Created Successfully" : " Error Couldnot Created "} />

            <CustomDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Updated Successfully" : " Error Couldnot Updated"} />

            <Dialog open={noChange} >
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={() => setNoChange(false)}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    )
};

export default ProcessMetrics;