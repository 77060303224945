export const gpsTaskTypes = ["Manual Task", "Create BOM Task", "Approve PO Task", "Confirm PO Task", "Receive Task",
    "Remove Task", "Install Task", "Verify Task", "Thank You Card Task", "Approve Contract Task", "Create Estimate Task",
    "Payment Verification Task", "Receive PO Task", 'Verify PO Task', 'Approve Invoice Task'
]

export const actionableTasks = ["Approve Contract Task", "Approve Invoice Task", "Approve PO Task", "Payment Verification Task"]

export const poTaskGroup = ["Create PO Task", "Create BOM Task",  "Approve PO Task", "Confirm PO Task", 
    "Receive PO Task", "Verify PO Task", 
]

export const poTaskGroupwithOutBom = ["Approve PO Task", "Confirm PO Task", "Create PO Task",
    "Receive PO Task", "Verify PO Task"
]

export const invoiceTaskGroup = ["Create Invoice Task", "Approve Invoice Task", "Payment Task", "Remainder Invoice Task", "Payment Verification Task"
]

export const APPROVE_CONTRACT_TASK = "Approve Contract Task";

export const APPROVE_INVOICE_TASK = "Approve Invoice Task";

export const APPROVE_PO_TASK = "Approve PO Task";

export const PAYMENT_VERIFICATION_TASK = "Payment Verification Task";

export const CREATE_BOM_TASK = "Create BOM Task";

export const mandatoryTaskList = ["Create BOM Task", "Create Estimate Task", "Approve Contract Task", "Approve Invoice Task",
    "Payment Verification Task", "Approve PO Task", "Confirm PO Task", "Receive PO Task", "Verify PO Task", "Thank You Card Task"
]

export const nonDeletableTasks = ["Remainder Invoice Task","Verify PO Task","Receive PO Task","Approve PO Task","Create PO Task",
    "Payment Task","Approve Invoice Task","Create Invoice Task","Remainder Customer Task","Customer Task","Create BOM Task","Create Invoice Task",
    "Approve Invoice Task","Reminder Invoice Task"];

