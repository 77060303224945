/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-Oct-2024
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Uploading Delivery Slip from Desktop
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useCallback } from "react";
import {
    DialogContent, DialogActions,
    DialogTitle, Dialog, Box, Typography, Card, CardContent, Grid, Button, IconButton,
    FormControl, InputLabel, Select,
    MenuItem, TextField, Paper,
    CircularProgress, FormControlLabel,
    Checkbox, createFilterOptions,
    Autocomplete, RadioGroup, Radio, Switch,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Backdrop,
    DialogContentText,
    FormLabel
} from '@mui/material';
import '../paymentTypes/unPaidList/MakePaymentList'
import CloseIcon from '@mui/icons-material/Close';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { useEffect, useState } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../pages/Dialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { confirmDeliveyBill, uploadBill, uploadReceipt } from "../../services/DeliverySlipService";
import { getAllVendors, getMaterialsByAddress, getVendorNames, getVendorsAddressByName } from "../../services/VendorService";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VendorRegister from "../vendor/VendorRegister";
import { getGradesByNameAndVendorId, getMaterialsByNameAndGrade } from "../../services/materialService";
import AddMaterialForm from "../materialManagement/AddMaterialForm";
import AddIcon from '@mui/icons-material/Add';
import { getCOAAccountTypes } from "../../services/JobLedgerService";
import { styled } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { getUserSession, useHandleUnauthorized, UserContext } from "../../hooks/UserSession";
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPOItemDetails, getPOItems, getUnReceivedPODetails } from "../../services/PoService";
import { set } from "date-fns";
import { RssFeed } from "@mui/icons-material";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import WarningDailog from "../../pages/warningDialog";
import { getOrderNoByRole } from "../../services/ProjectService";
import { getBillByReceiptId } from "../../services/BillService";

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const UploadSlip: React.FC<any> = (props) => {
    const navigate = useNavigate();

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [bill, setBill] = useState<any>([]); // Initialize with an empty array
    const [type, setType] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState<any>();
    const [pOId, setPOId] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [isAck, setIsAck] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [payment, setPayment] = useState('');
    const [accountType, setAccountType] = useState('');
    const [paid, setPaid] = useState('');
    const [cost, setCost] = useState('');
    const [totalCost, setTotalCost] = React.useState<any>('');
    const [poData, setPoData] = React.useState({} as any);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [updated, setUpdated] = React.useState(false);
    const [poItems, setPoItems] = React.useState<any>([]);
    const [notPOItems, setNotPOItems] = React.useState(false);
    const [notLinkedItems, setNotLinkedItems] = React.useState<any>([]);
    const [notLinkedLessItems, setNotLinkedLessItems] = React.useState<any>([]);

    const [validatePO, setValidatePO] = React.useState(false);
    const [excessPOItems, setExcessPOItems] = React.useState<any>([]);
    const [excessData, setExcessData] = React.useState(false);
    const [validateDI, setValidateDI] = React.useState(false);
    const [lessPOItems, setLessPOItems] = React.useState<any>([]);
    const [lessData, setLessData] = React.useState(false);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [dcuploaded, setDcUploaded] = React.useState(false);
    const [createConfirmSuccess, setCreateComfirmSuccess] = React.useState(false);
    const [createConfirmError, setCreateCofirmError] = React.useState(false);
    const [poIds, setPoIds] = React.useState<any>(['']);
    const [selectedPO, setSelectedPO] = React.useState<any>();
    const [createErrorDI, setCreateErrorDI] = React.useState(false);
    const [createSuccessDI, setCreateSuccessDI] = React.useState(false);

    const [createError, setCreateError] = React.useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [openReturnable, setOpenReturnable] = React.useState(false);
    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [vendorName, setVendorName] = React.useState<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [accountList, setAccountList] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>(0);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [confirmResult, setConfirmResult] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [materialData, setMaterialData] = React.useState<any>();
    const [vendorId, setVendorId] = React.useState<any>();
    const [openAddMaterialForm, setOpenAddMaterialForm] = React.useState<boolean>(false);
    const [openConfirmForm, setOpenConfirmForm] = React.useState<boolean>(false);
    const { userSessionDetails } = React.useContext(UserContext);
    const paymentTypes = ["Credit Card", "Vendor Account", "Cash"];
    const paymentMode = ["Personal Card", "Company Card"];
    const [checked, setChecked] = React.useState(false);
    const [gradeList, setGradeList] = React.useState<any>();
    const [materialList, setMaterialList] = React.useState<any>([]);
    const [material, setMaterial] = React.useState<any>(
        {
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorId': '',
            'vendorMaterialMapId': '',
            'address': '',

        }
    );

    const [role, setRole] = React.useState<any>('');
    const [billData, setBillData] = React.useState<any>(
        {
            'vendorid': '',
            'paid': Boolean,
            'paymentMethod': '',
            'paymentType': '',
            'reason': '',
            'receiptInfo': '',
            'total': '',
            'billNo': '',
            'billDate': new Date(),
            'category': '',
            'accountType': '',
            'taxInfos': ''
        }
    );
    const DELIVERYSLIP = 'DeliverySlip';
    const BILL = 'Bill'
    const { handleUnauthorized } = useHandleUnauthorized();
    const [orderNoList, setOrderNoList] = React.useState<any>([] as any[]);
    const [documentData, setDocumentData] = React.useState<any>();
    const [errors, setErrors] = useState({
        billNo: false,
        billDate: false,
        accountType: false,
        orderNo: false,
        total:false,
        file: false,
        paid: false
    });

    const filter = createFilterOptions<any>();

    const handleOpenWithTimeout = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, 3000); // Adjust the delay (in milliseconds) as needed
    };

    const onClose = () => {
        props.handleClose();
        // window.location.reload();
    }

    const getBill = async (projectId: any, receiptId: any) => {
        let result = await getBillByReceiptId(projectId, receiptId, accessToken);
        console.log(result?.data);
        if (result?.data['name'] === "VendorBill") {
            setType("Bill")
        } else {
            setType(result?.data['name']);
        }
        setBill(result?.data);
        if (result?.data['name'] === BILL || result?.data['name'] === "VdendorBill") {
            getOrderNo();
        }
        setOrderNo(result?.data['jobNo']);
        setBillNo(result?.data['billNo']);
        setBillDate(result?.data['billDate'])
        setTotalCost(result?.data['total']);
        if (result?.data['paid'] === true) {
            setPaid("Yes")
            setIsPaid(true);
        }
        else {
            setPaid("No");
            setIsPaid(false);
        }
        setAccountType(result?.data['category']);
        setPayment(result?.data['paymentType'])
        setPaymentMethod(result?.data['paymentMethod']);
        setDocumentData(result?.data['url']);
    }

    useEffect(() => {
        getUserSession(accessToken).then(res => {
            console.log(res?.role?.name)
            setRole(res.role.name);
        })
        getUnReceivedPODetails(accessToken).then((res => {
            console.log(res.data)
            if (res.data) {
                setPoIds(res.data)
            }
        }));
        if ((props?.billDetails !== '' || props?.billDetails !== undefined) && props?.projectId) {
            getBill(props?.projectId, props?.billDetails);
        }
        // handleOpenWithTimeout();
        getVendors();
        getAccountTypes();
    }, [isOpen]);

    const onselect = async (e: any) => {
        setType(e.target.value);
        if (e.target.value === BILL) {
            getOrderNo();
        }
    };
    const handleRemoveFile = useCallback((index: number) => {
        SetSelectedFile((prevFiles) => {
            // console.log('prevFiles',prevFiles);
            const updatedFiles = prevFiles.filter((_, i) => i !== index); // Remove the file at the specified index
            //   console.log('updataedfile',updatedFiles.length    );

            // Clear the file error if no files are left
            if (updatedFiles.length === 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    file: file,
                }));
            }

            return updatedFiles;
        });
    }, []);

    const getOrderNo = async () => {
        let result = await getOrderNoByRole(userSessionDetails?.role?.name, accessToken);
        console.log(result);
        if (result !== null || result !== '') {
            setOrderNoList(result?.data);
        }
    }
    const validationClose = () => {
        setUpdated(false);
        setValidatePO(false)
    }
    const onselected = async (e: any) => {
        // console.log(e.target.value)
        setSelectedPO(e.target.value);
    };

    const onselection = async (e: any) => {
        // console.log(e.target.value)
        setAccountType(e.target.value);
    };


    const manualReturnable = async (e: any) => {
        // console.log(e.target.value)
        setAccountType(e.target.value);
    };

    const handlepaymentmethod = async (e: any) => {
        console.log(e.target.value, e.target.checked)
        setChecked(e.target.checked)
        setPaymentMethod(e.target.value);
    };

    const handlepayment = async (e: any) => {
        setPayment(e.target.value);
    };

    const handleOrder = async (e: any) => {
        setOrderNo(e.target.value);
    };

    const handlebillNo = async (e: any) => {
        setBillNo(e.target.value);
    };

    const handlebillDate = async (e: any) => {
        setBillDate(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z");
    };

    const handleToggle = () => {
        setIsAck((prev) => !prev);
    };


    const handleDialogClose = () => {
        setOpenDialogs(false);


        onClose();
        window.location.reload();
    };
    const handleConfirmDialogClose = () => {
        setOpenConfirmForm(false);
        setOpenDialogs(false);
        setDcUploaded(false)
        onClose();
    };
    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            console.log(files)
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        console.log(file);
        setLoading(true);
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            if (reader.result === null) {
                setImagePreview(null);
            } else {
                setImagePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let images: any = [];
    const handleChange = useCallback(
        (event: any) => {
            for (let i = 0; i < event.target.files.length; i++) {
                images.push((event.target.files[i]));
                let reader = new FileReader();
                let file = event.target.files[i];
                setFile(images);
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                // id: shortid.generate(),
                                filename: event.target.files[i].name,
                                filetype: event.target.files[i].type,
                                fileimage: reader.result,
                                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(event.target.files[i].size)
                            }
                        ]
                    });
                }
                if (event.target.files[i]) {
                    reader.readAsDataURL(file);
                }
            }
        },
        []
    );

    const addTaxRows = () => {
        const taxComponent = {
            name: "",
            percentage: "",
            value: "",
            reason: "",
            status: "active",
            updatedAt: null,
            createdBy: userSessionDetails?.firstname
        };

        const updateTaxInfo: any = [
            // copy the current users state
            ...billData.taxInfo,
            // now you can add a new object to add to the array
            taxComponent,
        ];
        newRows.push(updateTaxInfo.indexOf(taxComponent));

        // update the state to the updatedUsers
        setBillData({ ...billData, taxInfo: updateTaxInfo });
    };

    const addRows = () => {
        if (!validateRows()) {
            setUpdated(false);
            return;
        }

        const addNewItem = {
            cost: "",
            costCode: "",
            customerName: '',
            delivery_status: "",
            description: "",
            id: "",
            itemCode: "",
            itemStatus: "",
            quantity: "",
            // rate: "",
            received_quantity: '',
            referenceType: "",
            service: "",
            // totalCost: ""

        };

        // Add the new item directly to poItems
        const updatedPoItems = [...poData.poItems, addNewItem];

        // Update state
        setPoData({ ...poData, poItems: updatedPoItems });
    };
    const HandleValidateDiClose = () => {
        setValidateDI(false);
        setDcUploaded(true);
        addRows();
    }
    const handleUploadChange = async () => {
        const newErrors = {
            billNo: !billNo.trim(),
            billDate: !billDate,
            accountType: !accountType,
            orderNo: !orderNo,
            file: file === null || file.length === 0,
            total: !totalCost,
            paid: !paid
        };

        const newFileValidation={
            file: file === null || file.length === 0,
        }
        // If taxInfos is empty, show an error and add a new row


        setErrors(newErrors);

        if (!Object.values(type==BILL ?newErrors:newFileValidation).some((error) => error)) {
            setOpenDialogs(true);
            try {
                if (file !== null && file.length > 0) {
                    const formData = new FormData();
                    file.map((file: any) => {
                        formData.append('file', file);
                    });
                    console.log(type);
                    formData.append('name', type)
                    if (type === DELIVERYSLIP) {
                        setLoading(true);
                        const data = await uploadReceipt(selectedPO, formData, accessToken)
                        if (data?.status === 200) {
                            console.log("Upload successful:", data);
                            console.log(data);
                            handleUnauthorized(data);
                            let finalData = data.data;
                            var add;
                            if (finalData?.customerAddress) {
                                add = JSON?.parse(finalData?.customerAddress);
                                finalData['customerAddress'] = add?.address
                            }

                            if (finalData?.status === "Draft" && finalData?.taxInfos === null) {
                                finalData['taxInfos'] = [{
                                    name: "",
                                    percentage: "",
                                    value: "",
                                }]
                                if (finalData) {
                                    setPoData(finalData);

                                }
                            }
                            else {
                                if (finalData) {
                                    setPoData(finalData);
                                }

                            }
                            // if (finalData?.status !== "Draft") {
                            //     setTaxShow(true);
                            // }
                            // else {
                            //     setTaxShow(false);
                            // }
                            getPurchaseItems(finalData.id);

                            if (finalData.poItems != null && finalData.poItems.length > 0) {
                                setValidateDI(true);
                                setCreateErrorDI(false);
                                setCreateSuccessDI(true)

                            }
                            else {
                                setValidateDI(true);
                                setCreateErrorDI(true);
                                setCreateSuccessDI(false);

                            }


                            // setDcUploaded(true);

                            // setCreateSuccess(true);
                        } else {
                            setCreateError(true);
                            console.error("Upload failed:", data);
                        }
                    } else if (type === BILL) {
                        setBillData({
                            'vendorid': vendorId,
                            'paid': isPaid,
                            'paymentMethod': payment,
                            'paymentType': paymentMethod,
                            'reason': cost,
                            'receiptInfo': JSON.stringify(materialList),
                            'total': totalCost,
                            'billNo': billNo,
                            'billDate': billDate,
                            'category': accountType,
                            'accountType': '',
                            'taxInfos': ''
                        })
                        // console.log(billData)
                        formData.append('data', JSON.stringify({
                            'vendorid': vendorId,
                            'paid': isPaid,
                            'paymentMethod': payment,
                            'paymentType': paymentMethod,
                            'reason': cost,
                            'receiptInfo': JSON.stringify(materialList),
                            'total': totalCost,
                            'billNo': billNo,
                            'billDate': billDate,
                            'category': accountType,
                            'accountType': '',
                            'taxInfos': ''
                        }))
                        const data = await uploadBill(orderNo, formData, accessToken)
                        if (data?.status === 200) {
                            if (data.data) {
                                setCreateSuccess(true);
                            } else {
                                setErrorMessage("Bill already upload")
                                setCreateError(true);
                            }
                        } else {
                            setCreateError(true);
                        }
                    } else {

                    }
                }
                else {
                    console.error("Invalid file:", file);
                    setCreateError(true);
                }
            }
            catch (error) {
                console.error("Error uploading:", error);
            }
            finally {
                setLoading(false);
            }
        }
    };

    const getVendors = async () => {
        // let result = await getAllVendors(accessToken);
        let result = await getVendorNames(accessToken);
        handleUnauthorized(result);
        setVendorList(result.data);
    }

    const getAccountTypes = async () => {
        // let result = await getCOAAccountTypes(accessToken);
        // if (result.data !== undefined) {
        //     setAccountList(result.data);
        // }
        setAccountList(["Material", "Service", "Travel", "Gas", "Food", "Medical"])
    }

    const handleChangeVendor = (e: any, value: any) => {
        // props.changeVendorName(value?.companyName);
        if (value !== null) {
            getVendorAddressList(value?.companyName);
            setVendorName(value?.companyName);
            setMaterial({ ...material, "companyName": value?.companyName });
        }
    }

    const getVendorAddressList = async (vendorName: any) => {
        let result = await getVendorsAddressByName(vendorName, accessToken);
        console.log(result.data)
        if (result.data !== undefined) {
            setVendorAddressList(result.data);
        }
    }

    const openReturnableItems = () => {
        setOpenReturnable(true);
    }
    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };

    const handleChangeAddress = async (e: any) => {
        setVendorAddress(e.target.value);
        setMaterial({ ...material, "address": e.target.value });
        let res = await getMaterialsByAddress(e.target.value, accessToken);
        if (res.data !== undefined) {
            console.log(res.data)
            setMaterialData(res?.data);
            setVendorId(res?.data[0]?.vendorId);
            material['vendorId'] = res?.data[0]?.vendorId;
        }
    }

    const handleChangeMaterialName = (e: any, value: any) => {
        setMaterial({ ...material, "name": value?.name });
        material['name'] = value?.name;
        if (value?.name != null) {
            getMaterialGrades(value?.name, vendorId);
        }
        if (value?.name !== '' && material?.grade !== '') {
            getMaterials(value?.name, material?.grade);
        }
    }

    const openForm = () => {
        setOpenAddMaterialForm(true);
    }

    const getMaterialGrades = async (name: any, vendorId: any) => {
        let materials = {};
        materials["name"] = name;
        materials["vendorId"] = vendorId;

        let result = await getGradesByNameAndVendorId(materials, accessToken);
        if (result.data !== undefined || result.data !== null) {
            setGradeList(result.data);
        }
    }

    // get vendors based on material name and grade
    const getMaterials = async (materialName: any, grade: any) => {
        if (materialName !== '' && grade !== '') {
            setMaterial({ ...material, "vendorId": vendorId });
            let finalMaterialData = {};
            finalMaterialData["name"] = materialName;
            finalMaterialData["grade"] = grade;
            finalMaterialData["vendorId"] = vendorId;
            finalMaterialData["address"] = vendorAddress;
            let result = await getMaterialsByNameAndGrade(finalMaterialData, accessToken);
            if (result?.data !== undefined) {
                setMaterial({
                    ...material, "unit": (result?.data)?.unit, "unitCost": (result?.data)?.cost,
                    "vendorMaterialMapId": (result.data)?.vendorMaterialMapId, "grade": grade
                });
            }
            if (result?.data !== undefined && material?.quantity !== '') {
                setMaterial({
                    ...material, "unit": (result?.data)?.unit, "unitCost": (result?.data)?.cost,
                    "vendorMaterialMapId": (result.data)?.vendorMaterialMapId, totalCost: ((result?.data)?.cost * material?.quantity)
                });
            }
        }
    }

    const handleChangeGrade = (e: any) => {
        setMaterial({ ...material, [e.target.name]: e.target.value })
        material['grade'] = e.target.value;
        if (material?.name !== '' && e.target.value !== '') {
            getMaterials(material?.name, e.target.value);
        }
    }

    const handleChangeQuantity = (e: any) => {
        if (e.target.value > 0 || e.target.value === '') {
            setMaterial({ ...material, [e.target.name]: e.target.value, totalCost: (material?.unitCost * e.target.value) })
        }
    }

    const handleListChange = (material) => {
        if (materialList.length === 0) {
            materialList.push(material);
            calculateTotalCost(materialList);
            setMaterialList(materialList);
            setMaterial({
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'grade': '',
                'unitCost': '',
                'vendorId': '',
                'vendorMaterialMapId': '',
                'address': '',
            })
        }
        else {
            const updatedMaterials = [
                ...materialList,
                material,
            ];
            calculateTotalCost(updatedMaterials);
            setMaterialList(updatedMaterials);
            setMaterial({
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'grade': '',
                'unitCost': '',
                'vendorId': '',
                'vendorMaterialMapId': '',
                'address': '',

            })
        }
    }

    const handlePaid = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Yes") {
            setPaid(event.target.value)
            setIsPaid(true);
        } else {
            setPaid(event.target.value)
            setIsPaid(false);
        }
    }

    const handleCost = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Yes") {
            setCost(event.target.value)
        } else {
            setCost(event.target.value)
        }
    }

    const validateRows = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.description || !item.itemCode || !item.quantity) {
                return false;
            }
        }
        return true;
    };
    const validatePOLinked = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.poItemId) {
                if (item.itemStatus !== 'returnable') {
                    return false;
                }

            }
        }
        return true;

    };
    const getPONotLinked = () => {
        // Initialize an array to store descriptions that match the condition
        const notLinkedItems: any = [];

        // Check if any rows are not linked
        for (const item of poData.poItems) {
            if (!item.poItemId && item.itemStatus !== 'returnable') {
                notLinkedItems.push(item.description); // Add description to the array
            }
        }

        // Validation for the notLinkedItems
        if (notLinkedItems.length === 0) {
            // If no items were found, show an appropriate message or handle it
            console.log('No unlinked items found.');
            return null; // or you can return an empty array or a specific message
        }
        console.log(notLinkedItems)
        // Join the descriptions with a comma separator and return the result
        return notLinkedItems.join(', '); // Join descriptions with comma and space
    };
    const updatedPoItems = () => {
        setUpdated(true);
        const updatedItems = poItems
            .map((item) => {
                // Find the matching item in poData.poItems
                const matchingItem = poData.poItems.find((poItem) => poItem.poItemId === item.id);
                let received_quantity = 0;
                if (item.received_quantity > 0) {
                    received_quantity = item.quantity - item.received_quantity;
                }
                else {
                    received_quantity = item.quantity;
                }
                // Check if the matching item's quantity is greater than the current item's quantity
                if (matchingItem && matchingItem.quantity > received_quantity) {
                    // Calculate the quantity difference
                    const quantityDifference = matchingItem.quantity - received_quantity;

                    // Update the matching item in poData.poItems with the new fields
                    setPoData((prevPoData) => ({
                        ...prevPoData,
                        poItems: prevPoData.poItems.map((poItem) =>
                            poItem.poItemId === item.id
                                ? { ...poItem, isQuantityGreater: true, quantityDifference: quantityDifference }
                                : poItem
                        ),
                    }));
                    // Return the updated item
                    return { ...item, isQuantityGreater: true, quantityDifference };
                }

                // If no matching item is found or quantity is not greater, return null
                return null;
            })
            .filter((item) => item !== null); // Filter out unchanged items

        return updatedItems;
    };

    const lessPoItems = () => {
        setUpdated(true);
        const updatedItems = poItems
            .map((item) => {
                // Find the matching item in poData.poItems
                const matchingItem = poData.poItems.find((poItem) => poItem.poItemId === item.id);
                let received_quantity = 0;
                if (item.received_quantity > 0) {
                    received_quantity = item.quantity - item.received_quantity;
                }
                else {
                    received_quantity = item.quantity;
                }
                // Check if the matching item's quantity is greater than the current item's quantity
                if (matchingItem && matchingItem.quantity < received_quantity) {
                    // Calculate the quantity difference
                    const quantityDifference = received_quantity - matchingItem.quantity;

                    // Update the matching item in poData.poItems with the new fields
                    setPoData((prevPoData) => ({
                        ...prevPoData,
                        poItems: prevPoData.poItems.map((poItem) =>
                            poItem.poItemId === item.id
                                ? { ...poItem, isQuantityGreater: false, quantityDifference: quantityDifference }
                                : poItem
                        ),
                    }));
                    // Return the updated item
                    return { ...item, isQuantityGreater: true, quantityDifference };
                }

                // If no matching item is found or quantity is not greater, return null
                return null;
            })
            .filter((item) => item !== null); // Filter out unchanged items

        return updatedItems;
    };

    const pendingPoItems = () => {
        setUpdated(true);
        const updatedItems = poItems
            .map((item) => {
                // Find the matching item in poData.poItems
                const matchingItem = poData.poItems.find((poItem) => poItem.poItemId === item.id);

                // Check if the matching item's quantity is greater than the current item's quantity
                if (matchingItem && item.quantity > matchingItem.quantity) {
                    // Calculate the quantity difference
                    const quantityDifference = item.quantity - matchingItem.quantity;

                    // Update the matching item in poData.poItems with the new fields
                    setPoData((prevPoData) => ({
                        ...prevPoData,
                        poItems: prevPoData.poItems.map((poItem) =>
                            poItem.poItemId === item.id
                                ? { ...poItem, isQuantityGreater: true, quantityDifference: quantityDifference }
                                : poItem
                        ),
                    }));
                    console.log(poData, poItems)
                    // Return the updated item
                    return { ...item, isQuantityGreater: false, quantityDifference };
                }

                // If no matching item is found or quantity is not greater, return null
                return null;
            })
            .filter((item) => item !== null); // Filter out unchanged items

        return updatedItems;
    };

    const getPurchaseItems = (poId: any) => {
        getPOItemDetails(poId, accessToken).then((res => {
            console.log(res.data)
            setPoItems(res.data)

        }))
    }

    const markReturnable = () => {
        const updatedPoItems = [...poData.poItems]; // Create a copy of poItems

        for (const item of updatedPoItems) {
            if (!item.poItemId && item.itemStatus !== 'returnable') {
                console.log("hiii")
                item.itemStatus = 'returnable'; // Update the itemStatus to 'returnable'
                item.poItemId = null;

                console.log(item)
            }
        }
        console.log(updatedPoItems)
        setUpdated(true)
        // Update the state with the modified poItems
        setPoData({ ...poData, poItems: updatedPoItems });
        setNotPOItems(false);
        setUpdated(true);
        ConfirmDeliveryItems();
    };

    const calculateTotalCost = (materialList: any) => {
        let total = 0;
        for (let i = 0; i < materialList?.length; i++) {
            total = materialList[i]?.totalCost + total;
        }
        setTotalCost(total.toString());
    }
    const handleChanged = (e, id, field) => {
        // updatedRows[index][field] = e.target.value;
        // Find the corresponding item in poItems using the id from rows
        const updatedPoItems = poData.poItems.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    [field]: e.target.value, // Update the field with the new value
                };
            }
            return item;
        });
        // Update the poData with the updated poItems
        setPoData({
            ...poData,
            poItems: updatedPoItems.poItems,

        });
        //setRows(updatedRows);
    };
    const onInputChange = (e: any, index: number) => {
        const { name, value } = e.target;

        // Prevent negative numbers and zero for numeric fields
        if ((name === "quantity" ) && (Number(value) <= 0 || isNaN(Number(value)))) {
            return; // Ignore invalid input
        }

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);

        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });

        console.log(poData);
    };


    const onSelectChange = (e: any, index: number) => {
        const { name, value } = e.target;

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        console.log(updatedPoItems);
        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });
    };
    const handleRemoveItem = (index: any) => {
        let newArray = [...poData?.poItems];
        newArray.splice(index, 1)
        // setInvoiceData({ ...invoiceData, taxInfo: newArray });
        let totalTaxAmount = 0

        newRows.pop();
        setPoData({ ...poData, poItems: newArray });
    }
    const ConfirmDeliveryItems = () => {

        if (!updated) {
            setNotLinkedItems([]);
            setLessPOItems([]);
            setExcessPOItems([]);

            console.log(updated);

            if (!validateRows()) {
                setUpdated(false);
                return;
            }

            const excessData = updatedPoItems();
            console.log(excessData, excessData.length > 0);
            const lessData = lessPoItems();
            console.log(excessData, lessData);

            if (excessData.length > 0) {
                setExcessPOItems(excessData);
                setValidatePO(true);
                setExcessData(true);
                setNotPOItems(false);
            }

            if (lessData.length > 0) {
                setLessPOItems(lessData);
                setValidatePO(true);
                setLessData(true);
                setNotPOItems(false);
            }
            if (!validatePOLinked()) {
                setNotLinkedItems(getPONotLinked() || []);
                setValidatePO(true)
                setNotPOItems(false);
                // return;
            }
            else if (excessData.length <= 0 && lessData.length <= 0 && !validatePOLinked()) {
                confirmDeliveyBill(poData?.receiptId, poData, accessToken).then(res => {
                    console.log(res);
                    if (res.data) {
                        setOpenConfirmForm(true);
                        if (res.data && (res.status === 200 || res.status === 201)) {
                            setCreateComfirmSuccess(true);
                            setCreateCofirmError(false);
                            setConfirmResult(res.data);
                        } else {
                            setOpenConfirmForm(true);
                            setCreateCofirmError(true);
                        }
                    } else {
                        setOpenConfirmForm(true);
                        setCreateCofirmError(true);
                    }
                });
            }
        }
        else {
            const res = confirmDeliveyBill(poData?.receiptId, poData, accessToken).then(res => {
                console.log(res);
                if (res.data) {
                    setOpenConfirmForm(true);
                    if (res.data && (res.status == 200 || res.status == 201)) {
                        setCreateComfirmSuccess(true);
                        setCreateCofirmError(false)

                        setConfirmResult(res.data);
                    }
                    // else {
                    //     setOpenConfirmForm(true);
                    //     setCreateCofirmError(true);
                    //     setConfirmResult(res.data);

                    // }

                }
                else {

                    setOpenConfirmForm(true);
                    setCreateCofirmError(true)
                }
            });
            console.log(res);

        }

    }
    const handleCheckboxChange = (index) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(index)) {
                console.log(prevSelectedItems)
                return prevSelectedItems.filter((item) => item !== index);
            } else {
                return [...prevSelectedItems, index];
            }
        });
    };

    const handleMarkReturnable = () => {
        // Update the status of selected items to 'returnable' and others to null
        const updatedPoItems = poData.poItems.map((item, index) => {
            if (selectedItems.includes(index)) {
                return { ...item, itemStatus: 'returnable', poItemId: null };
            } else {
                return { ...item, itemStatus: 'delivered' };
            }
        });
        // Update the poData state with updatedPoItems
        console.log(updatedPoItems)
        setPoData({ ...poData, poItems: updatedPoItems });
        setOpenReturnable(false);
    };
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={props.open}
            // onClose={()=>onClose()}
            >
                {loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <Stack direction="column" spacing={2} alignItems="center">
                            <Typography variant="h6">Automatic Reconciliation Using Document Intelligence</Typography>
                            <CircularProgress color="inherit" />
                        </Stack>
                    </Backdrop>
                ) : (
                    <>
                        <DialogTitle>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1} >
                                    <Typography className='unPaidHead'>Upload Receipt/Bill</Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={() => onClose()}>
                                        <CloseIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </DialogTitle>
                        <DialogContent sx={{ backgroundColor: "#f8f7fa" }}>
                            {(bill === ' ' && !dcuploaded) ? (
                                <CardContent className='noInvoice'>
                                    <Typography variant="body1" className='noInvoiceFound'><ReceiptIcon /></Typography>
                                    <Typography variant="body1" className='noInvoiceFound'>No purchase order found.</Typography>
                                </CardContent>
                            ) :
                                (
                                    !dcuploaded && (
                                        <Card className='contractCard' >
                                            <CardContent >
                                                <Grid container className='makePayment' spacing={2}>
                                                    <Grid item xs={6} spacing={2}>
                                                        <Grid container >
                                                            {!dcuploaded && (
                                                                <>
                                                                    <Grid item xs={12} sm={9} md={9} lg={9} xl={9} mb={2}>
                                                                        <Typography variant='h5' color="rgb(60 60 60 / 87%)">Upload Type</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9} md={9} lg={9} xl={9} mb={2}>
                                                                        <FormControl variant="outlined" fullWidth size="small">
                                                                            <InputLabel id="demo-select-small" required>Type</InputLabel>
                                                                            <Select
                                                                                // error={selectedData.assetUid === undefined && completed}
                                                                                required
                                                                                labelId="demo-select-small-label"
                                                                                id="demo-select-small"
                                                                                value={type}
                                                                                disabled={props?.billView === true ? true : false}
                                                                                label="Type"
                                                                                onChange={(e) => onselect(e)}
                                                                                name='Type'
                                                                            >
                                                                                {(role === "admin" || role === "foreman") && (
                                                                                    <MenuItem value="DeliverySlip">Delivery Slip</MenuItem>
                                                                                )}
                                                                                <MenuItem value="Bill">Bill</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            {type === BILL && <><Grid item xs={12} sm={9} md={9} lg={9} xl={9} mb={2}>
                                                                <FormControl variant="outlined" fullWidth size="small" error={errors.orderNo && orderNo === ''}>
                                                                    <InputLabel id="demo-select-small" required>Project Order No</InputLabel>
                                                                    <Select
                                                                        // error={selectedData.assetUid === undefined && completed}
                                                                        required
                                                                        labelId="demo-select-small-label"
                                                                        id="demo-select-small"
                                                                        value={orderNo}
                                                                        disabled={props?.billView === true ? true : false}
                                                                        label="Project Order No"
                                                                        onChange={(e) => handleOrder(e)}
                                                                        name='orderNo'
                                                                    >
                                                                        {orderNoList?.map((orderNo: any, index: any) => {
                                                                            return (
                                                                                <MenuItem key={index} value={orderNo?.jobNo}>{orderNo?.customerName} - {orderNo?.jobNo}</MenuItem>
                                                                            )

                                                                        })}
                                                                    </Select>
                                                                    {errors.orderNo && orderNo === '' && <Typography color="error" fontSize={11} paddingLeft={2}>Please select project number</Typography>}
                                                                </FormControl>
                                                            </Grid>
                                                                <Grid item xs={12} sm={9} md={9} lg={9} xl={9} mb={2}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            id="demo-helper-text-aligned"
                                                                            label="Bill Number"
                                                                            name="billNumber"
                                                                            size="small"
                                                                            fullWidth
                                                                            disabled={props?.billView === true ? true : false}
                                                                            onChange={(e) => handlebillNo(e)}
                                                                            value={billNo}
                                                                            error={errors.billNo && billNo === ''}
                                                                            helperText={errors.billNo ? "Please enter a bill number" : ""}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={9} md={9} lg={9} xl={9} mb={2}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <FormControl fullWidth size="small" error={billDate === null}>
                                                                            <DatePicker
                                                                                // format='DD/MM/YYYY'
                                                                                label="Bill Date"
                                                                                disabled={props?.billView === true ? true : false}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        fullWidth: true, size: "small",
                                                                                        error: errors.billDate && billDate === undefined,
                                                                                        helperText: (errors.billDate && billDate === undefined) ? "Please select a billing date" : "",
                                                                                    }
                                                                                }}
                                                                                value={billDate !== undefined ? dayjs(billDate) : null}
                                                                                onChange={handlebillDate}
                                                                            />
                                                                        </FormControl>
                                                                    </LocalizationProvider>

                                                                </Grid>
                                                            </>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                {(type === DELIVERYSLIP && !dcuploaded) && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Purchaser Order No.</Typography>}
                                                                {type === BILL && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Category</Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                                {(type === DELIVERYSLIP && !dcuploaded) && (

                                                                    <FormControl variant="outlined" fullWidth size="small">
                                                                        <InputLabel id="demo-select-small" required>Purchase Order No</InputLabel>
                                                                        <Select
                                                                            // error={selectedData.assetUid === undefined && completed}
                                                                            required
                                                                            labelId="demo-select-small-label"
                                                                            id="demo-select-small"
                                                                            value={selectedPO}
                                                                            label="Purchase Order Number"
                                                                            onChange={(e) => onselected(e)}
                                                                            name='purchaseOrder'
                                                                        >
                                                                            {poIds?.map((purchaseId: any, index: any) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={purchaseId}>{purchaseId}</MenuItem>
                                                                                )

                                                                            })}
                                                                        </Select>
                                                                    </FormControl>

                                                                )

                                                                }
                                                                {type === BILL && <><FormControl variant="outlined" fullWidth size="small" error={errors.accountType && accountType === ''}>
                                                                    <InputLabel id="demo-simple-select-label" >Category</InputLabel>
                                                                    <Select
                                                                        // error={selectedData.assetUid === undefined && completed}
                                                                        required
                                                                        labelId="demo-select-small-label"
                                                                        id="demo-select-small"
                                                                        value={accountType}
                                                                        label="Account Type"
                                                                        onChange={(e) => onselection(e)}
                                                                        name='accountType'
                                                                        disabled={props?.billView === true ? true : false}
                                                                    >
                                                                        {accountList?.map((acc: any, index: any) => {
                                                                            return (
                                                                                <MenuItem key={index} value={acc}>{acc}</MenuItem>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Select>
                                                                    {errors.accountType && accountType === '' && <Typography color="error" fontSize={11} paddingLeft={2}>Please select an account type</Typography>}
                                                                </FormControl>
                                                                    <FormControl>
                                                                        <FormLabel id="demo-row-radio-buttons-group-label">Has this bill been paid for</FormLabel>
                                                                        <RadioGroup
                                                                            row
                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                            name="row-radio-buttons-group"
                                                                            value={paid}
                                                                            onChange={handlePaid}
                                                                        >
                                                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={props?.billView === true ? true : false} />
                                                                            <FormControlLabel value="No" control={<Radio />} label="No" disabled={props?.billView === true ? true : false} />
                                                                        </RadioGroup>
                                                                        {errors.paid && paid === '' && <Typography color="error" fontSize={11} paddingLeft={2}>Please select any</Typography>}
                                                                    </FormControl>
                                                                </>
                                                                }
                                                            </Grid>

                                                            {isPaid ?
                                                                <Grid item xs={12}>
                                                                    {type === BILL && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Select the payment method used</Typography>}
                                                                </Grid> : <></>}
                                                            {isPaid ? <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                                {type === BILL &&
                                                                    <>
                                                                        {paymentTypes?.map((p: any, index: any) => {
                                                                            return (
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                                            checkedIcon={<CheckCircleIcon />}
                                                                                            name="checkbox"
                                                                                            checked={paymentMethod === p ? true : false}
                                                                                            value={p}
                                                                                            disabled={props?.billView === true ? true : false}
                                                                                            onChange={(e) => handlepaymentmethod(e)}
                                                                                            size='small'
                                                                                        // disabled={(assetDataObject?.isBilling === true)}
                                                                                        />
                                                                                    }
                                                                                    label={p}
                                                                                />
                                                                            )
                                                                        })
                                                                        }

                                                                        {paymentMethod === "Credit Card" && (
                                                                            <>
                                                                                {paymentMode?.map((pm: any, index: number) => (
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                icon={<RadioButtonUncheckedIcon />}
                                                                                                checkedIcon={<CheckCircleIcon />}
                                                                                                name="checkbox"
                                                                                                checked={payment === pm ? true : false}
                                                                                                value={pm}
                                                                                                disabled={props?.billView === true ? true : false}
                                                                                                onChange={(e) => handlepayment(e)}
                                                                                                size="small"
                                                                                            />
                                                                                        }
                                                                                        label={pm}
                                                                                    />
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                    </>
                                                                }
                                                            </Grid> : <></>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                                {type === BILL &&
                                                    <>
                                                        <Grid container spacing={2} mb={2}>
                                                            {/* <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="demo-helper-text-aligned"
                                                                label="Bill Number"
                                                                name="billNumber"
                                                                size="small"
                                                                fullWidth
                                                                onChange={(e) => handlebillNo(e)}
                                                                value={billNo}
                                                            />
                                                        </FormControl>
                                                    </Grid> */}
                                                            {/* <Grid item xs={6}>

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <FormControl fullWidth size="small">
                                                                <DatePicker
                                                                    // format='DD/MM/YYYY'
                                                                    label="Choose Date"
                                                                    slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                    value={billDate !== undefined ? dayjs(billDate) : null}
                                                                    onChange={handlebillDate}
                                                                />
                                                            </FormControl>
                                                        </LocalizationProvider>
                                                    </Grid> */}

                                                            <Grid xs={12} mt={2}>
                                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                                    <Grid item xs={3}>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                    </Grid>
                                                                    <Grid item xs={2} className='alignEnd'>
                                                                        <Typography>Total:</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                id="demo-helper-text-aligned"
                                                                                label="Total"
                                                                                name="total"
                                                                                size="small"
                                                                                fullWidth
                                                                                disabled={props?.billView === true ? true : false}
                                                                                onChange={(e) => setTotalCost(e.target.value.replace(/[^0-9\.]/g, ""))}
                                                                                value={totalCost}
                                                                                error={errors.total && totalCost === ''}
                                                                                helperText={errors.total && totalCost === '' ? "Enter amount" : ""}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={1}>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                                {!dcuploaded && (
                                                    <Grid item xs={12} pt={1}>
                                                        <Box>
                                                            {props?.billView ? (
                                                                <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                                                    <iframe src={documentData} width="100%" height="100%" title="PDF Viewer" frameBorder="0"></iframe>
                                                                </Grid>
                                                            ) : (
                                                                <>
                                                                    {/* Drag & Drop File Upload */}
                                                                    <Paper
                                                                        variant="outlined"
                                                                        onDragOver={handleDragOver}
                                                                        onDragLeave={handleDragLeave}
                                                                        onDrop={handleDrop}
                                                                        style={{
                                                                            border: dragOver ? "2px dashed #000" : "2px dashed #aaa",
                                                                            padding: 20,
                                                                            textAlign: "center",
                                                                            cursor: "pointer",
                                                                            background: dragOver ? "#eee" : "#fafafa",
                                                                            position: "relative",
                                                                        }}
                                                                    >
                                                                        <input
                                                                            accept="image/*,.pdf"
                                                                            style={{ display: "none" }}
                                                                            id="raised-button-file"
                                                                            multiple
                                                                            type="file"
                                                                            onChange={handleChange}
                                                                        />
                                                                        <label htmlFor="raised-button-file">
                                                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                                    <CloudUploadIcon style={{ fontSize: 60 }} />
                                                                                </IconButton>
                                                                                <Typography>Upload</Typography>
                                                                                <Typography>Drag and drop files here or click to select files</Typography>
                                                                            </Box>
                                                                        </label>
                                                                        {loading && (
                                                                            <CircularProgress
                                                                                size={24}
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    top: "50%",
                                                                                    left: "50%",
                                                                                    marginTop: "-12px",
                                                                                    marginLeft: "-12px",
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {errors.file && <Typography color="error">Please upload at least one file</Typography>}

                                                                    </Paper>

                                                                    {/* File Preview */}
                                                                    {selectedfile.length > 0 && (
                                                                        <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                                                            {selectedfile.map((file: any, index) => (
                                                                                <Grid item key={index} style={{ position: "relative", marginBottom: 16 }}>
                                                                                    {file.fileimage && (
                                                                                        <>
                                                                                            {file.filetype === "application/pdf" ? (
                                                                                                <iframe title="PDF Viewer" src={file.fileimage} style={{ height: "800px", width: "100%" }} />
                                                                                            ) : (
                                                                                                <img src={file.fileimage} alt={file.filename} style={{ maxWidth: "100%", maxHeight: "800px" }} />
                                                                                            )}
                                                                                            <IconButton
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: 8,
                                                                                                    right: 8,
                                                                                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                                                                                }}
                                                                                                onClick={() => handleRemoveFile(index)}
                                                                                            >
                                                                                                <DeleteIcon color="error" />
                                                                                            </IconButton>
                                                                                        </>
                                                                                    )}
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                )}

                                            </CardContent>
                                        </Card>
                                    )

                                )}
                            {dcuploaded && (

                                <>
                                    <Grid item xs={12}>
                                        <Grid container className='invoiceHeader' rowSpacing={0} columnSpacing={0}>
                                            {/* Header - Tenant Details */}
                                            <Grid item xs={12} sm={6}>
                                                <Typography>
                                                    <img src={poData?.tenantLogo} alt="Tenant Logo" className="logo" />
                                                </Typography>
                                                <Typography paddingTop={2}>{poData?.tenantAddress}</Typography>
                                                <Typography>{poData?.tenantCity}, {poData?.tenantState}</Typography>
                                                <Typography>{poData?.tenantCountry}, {poData?.tenantPrimaryContact}</Typography>
                                            </Grid>

                                            {/* Header - Invoice Info */}
                                            <Grid item xs={12} sm={6} className='alignEnd'>
                                                <Stack>
                                                    <Typography className='invoiceNumber' display="flex" alignItems="center">Delivery Number:&nbsp;#{poData?.receiptId}</Typography>
                                                    <Typography display="flex" alignItems="center">PO Number:&nbsp;#{poData?.id}</Typography>
                                                    <Typography >Date Issued: {dayjs(poData?.billDate).format("MMM DD, YYYY")}</Typography>
                                                    {/* <Typography>Date Due: {poData?.dueDate}</Typography> */}
                                                    <Typography display="flex" alignItems="center">Delivery Date: {poData?.deliveryDate && dayjs(poData?.deliveryDate).isValid()
                                                        ? dayjs(poData?.deliveryDate).format('MMM DD, YYYY') // Format or bind the valid date
                                                        : "No delivery date available." // Message for invalid or empty date
                                                    }</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Box m={2} mb={4} sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>



                                            {/* Vendor Details */}
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="h5"
                                                    sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                                    Vendor Details:
                                                </Typography>
                                                <Box ml={1}>
                                                    <Typography display="flex" alignItems="center">{poData?.vendorName}</Typography>
                                                    <Typography display="flex" alignItems="center">{poData?.vendorEmail}</Typography>
                                                    <Typography display="flex" alignItems="center">{poData?.vendorAddress}</Typography>
                                                    <Typography display="flex" alignItems="center">{poData?.vendorMobileNo}</Typography>
                                                </Box>
                                            </Box>

                                            {/* Customer Details */}
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="h5"
                                                    sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                                    Customer Details:
                                                </Typography>
                                                <Box ml={1}>
                                                    <Typography display="flex" alignItems="center">{poData?.customerName}</Typography>
                                                    <Typography display="flex" alignItems="center">{poData?.customerEmail}</Typography>
                                                    <Typography display="flex" alignItems="center">{poData?.customerAddress}</Typography>
                                                    <Typography display="flex" alignItems="center">{poData?.customerMobileNo}</Typography>
                                                </Box>
                                            </Box>


                                        </Box>
                                        {/* <Typography variant="h5"
                                            sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>Manual Reconciliation on Confirming Delivery Items</Typography> */}
                                        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow className='tableHead'>
                                                        <TableCell>Item Name</TableCell>
                                                        <TableCell>Code</TableCell>
                                                        <TableCell>Quantity</TableCell>
                                                        <TableCell>Link PO Items</TableCell>

                                                        {/* <TableCell>Unit Cost</TableCell>
                                                <TableCell>Amount</TableCell> */}
                                                        <TableCell>Actions</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {poData?.poItems.length > 0 ? (
                                                        poData?.poItems?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                {/* <TableCell>
                                  <input
                                    type="text"
                                    value={row.industry}
                                    onChange={(e) => handleChange(e, index, 'industry')}
                                  />
                                </TableCell> */}


                                                                <TableCell>
                                                                    <TextField id="standard-basic" variant="standard"
                                                                        type="text"
                                                                        value={row?.description}
                                                                        name="description"
                                                                        error={!updated && !row?.description}
                                                                        helperText={!updated && !row?.description ? ("enter description ") : ("")}
                                                                        sx={{
                                                                            '& .MuiInputBase-input': {
                                                                                color: !row.poItemId && row.itemStatus !== 'returnable' ? 'red !important' : 'inherit',
                                                                            },
                                                                        }}
                                                                        onChange={(e) => onInputChange(e, index)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField id="standard-basic" variant="standard"
                                                                        type="text"
                                                                        value={row?.itemCode}
                                                                        name="itemCode"
                                                                        error={!updated && !row?.itemCode}
                                                                        helperText={!updated && !row?.itemCode ? ("enter item code ") : ("")}
                                                                        sx={{
                                                                            '& .MuiInputBase-input': {
                                                                                color: !row.poItemId && row.itemStatus !== 'returnable' ? 'red !important' : 'inherit',
                                                                            },
                                                                        }}
                                                                        onChange={(e) => onInputChange(e, index)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField id="standard-basic" variant="standard"
                                                                        type="number"
                                                                        value={row?.quantity}
                                                                        name="quantity"
                                                                        error={!updated && !row?.quantity}
                                                                        helperText={!updated && !row?.quantity ? ("enter quantity ") : ("")}
                                                                        sx={{
                                                                            '& .MuiInputBase-input': {
                                                                                color: !row.poItemId && row.itemStatus !== 'returnable' ? 'red !important' : 'inherit',
                                                                            },
                                                                        }}
                                                                        onChange={(e) => onInputChange(e, index)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row?.itemStatus === 'returnable' ? (
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
                                                                            <Typography variant="body2" color="green">
                                                                                Returnable Item
                                                                            </Typography>
                                                                            <CheckCircleIcon fontSize="small" sx={{ marginLeft: 1 }} />
                                                                        </Box>
                                                                    ) : (<FormControl fullWidth size="small">
                                                                        <InputLabel id="po-select-label">Purchase Order</InputLabel>
                                                                        <Select
                                                                            labelId="po-select-label"
                                                                            name="poItemId"
                                                                            label="PO Item name"
                                                                            value={row?.poItemId}
                                                                            onChange={(e) => onSelectChange(e, index)}
                                                                            displayEmpty
                                                                        >
                                                                            <MenuItem value="" disabled>
                                                                                Select a Purchase Order
                                                                            </MenuItem>
                                                                            {poItems.map((item) => {
                                                                                // Check if the current item's ID is already selected
                                                                                const isDisabled = poData.poItems.some((poItem) => poItem.poItemId === item.id);
                                                                                return (
                                                                                    <MenuItem key={item.id} value={item.id} disabled={isDisabled}>
                                                                                        {item.description}
                                                                                    </MenuItem>
                                                                                );
                                                                            })}

                                                                        </Select>
                                                                    </FormControl>)}</TableCell>
                                                                {/* <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.rate}
                                                                name="rate"
                                                                error={!updated && !row?.rate}
                                                                helperText={!updated && !row?.rate ? ("enter rate ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.cost}
                                                                name="cost"
                                                                error={!updated && !row?.cost}
                                                                helperText={!updated && !row?.cost ? ("enter cost ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell> */}
                                                                <TableCell>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                                                            <DeleteIcon fontSize='small'
                                                                            />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )
                                                        : (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    textAlign: 'center', // Optional: Ensures text is centered
                                                                    height: '100%', // Optional: Adjust height as needed
                                                                }}
                                                            >
                                                                <Stack>
                                                                    <Stack><Typography>Cannot able to read Delivery Items</Typography></Stack>
                                                                    <Stack> <Button onClick={addRows}>Add Row</Button></Stack>
                                                                </Stack>
                                                            </Box>


                                                        )}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Grid item xs={6} sm={3} mb={2}>

                                            <Box className='alignEnd'>
                                                <Stack >
                                                    <Button onClick={() => setOpenReturnable(true)}>Mark Returnable Items?</Button>
                                                </Stack>
                                                <Stack>
                                                    <Button onClick={addRows}>Add Row</Button>
                                                </Stack>

                                            </Box>
                                        </Grid>
                                        {/* <Grid container rowSpacing={2} paddingBottom={3}>
                                    <Grid item xs={6} sm={6}>
                                        <Box>
                                            <Typography className='subHeader' textAlign="left">
                                                Total:
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Box>
                                            <Typography textAlign="right">
                                                ${Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid> */}
                                    </Grid>
                                </>)}
                        </DialogContent>
                        {props?.billView === true ?
                            <></> :
                            <DialogActions>
                                {/* <Button className='sucessButton' onClick={ConfirmDeliveryItems}>Extra Items</Button>
                <Button className='sucessButton' onClick={ConfirmDeliveryItems}>Partial Complete</Button>
                <Button className='sucessButton' onClick={ConfirmDeliveryItems}>InComplete</Button> */}

                                {!dcuploaded ? <Button onClick={handleUploadChange} variant="contained">Submit</Button>
                                    :

                                    <Button className='sucessButton' onClick={ConfirmDeliveryItems}>Complete</Button>}
                                <Button className='primaryTonalButton' onClick={onClose}>Cancel</Button>
                            </DialogActions>
                        }
                    </>)}
            </Dialog>
            {openReturnable && (<> <Dialog open={openReturnable} onClose={onClose}>
                <DialogTitle>Mark Returnable Items</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select</TableCell>
                                    <TableCell>Item Name</TableCell>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {poData.poItems.filter(row => row.description?.trim() !== "" && row.itemCode?.trim() !== "" && row.quantity).length > 0 ? (
                                    poData.poItems
                                        .filter(row => row.description?.trim() !== "" && row.itemCode?.trim() !== "" && row.quantity) // Exclude empty items
                                        .map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedItems?.includes(index)}
                                                        onChange={() => handleCheckboxChange(index)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell>{row.itemCode}</TableCell>
                                                <TableCell>{row.quantity}</TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            No items available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenReturnable(false)}>Cancel</Button>
                    <Button onClick={handleMarkReturnable}>Apply</Button>
                </DialogActions>
            </Dialog> </>)}

            {validatePO && (<>   <Dialog open={validatePO} >
                <DialogTitle className='warning'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            <div>
                                {excessData && (
                                    <ul>
                                        <small style={{ color: 'black' }}>There are some excess Items in Delivery Slips</small>
                                        {excessPOItems.map(item => (
                                            <li key={item.id}>
                                                Item:<span style={{ color: "#cd4a4a !important" }} className="danger">&nbsp; {item.description}</span>, Quantity Difference:<span style={{ color: "#cd4a4a !important" }}> &nbsp;{item.quantityDifference}</span>
                                            </li>
                                        ))}
                                        <small>These will be Considered as Returnable.</small>
                                    </ul>
                                )}
                                <Grid mt={2}></Grid>
                                {lessData && (
                                    <ul>
                                        <small style={{ color: 'black' }}>There are some Less Quantity Items in Delivery Slips</small>
                                        {lessPOItems.map(item => (
                                            <li key={item.id}>
                                                Item:<span style={{ color: "#cd4a4a !important" }} className="danger">&nbsp; {item.description}</span>, Quantity Difference:<span style={{ color: "#cd4a4a !important" }}> &nbsp;{item.quantityDifference}</span>
                                            </li>
                                        ))}
                                        <small>These will be Considered as Partially Received.</small>
                                    </ul>
                                )}
                                {/* { notLinkedItems.length > 0 && (
                                    <p>


                                        <>
                                            {notLinkedItems} were not linked with PO items. If you are not linking, they will be considered as returnable items.
                                        </>

                                    </p>
                                )} */}
                                {notLinkedItems.length > 0 && (
                                    <p>Some of the items were not  linked with PO items. If you are not linking, they will be considered as returnable items.</p>
                                )}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className='sucessButton' onClick={() => { markReturnable() }} >Proceed</Button>
                        <Button variant='contained' className='primaryTonalButton' onClick={() => validationClose()}>Cancel</Button>

                    </DialogActions>
                </Box>
            </Dialog> </>)}
            <AddMaterialForm open={openAddMaterialForm}
                onClose={() => setOpenAddMaterialForm(false)}></AddMaterialForm>

            <VendorRegister
                open={openVendorForm}
                onClose={() => setOpenVendorForm(false)}
            />
            <WarningDailog
                open={validateDI}
                onClose={HandleValidateDiClose}
                success={createSuccessDI}
                error={createErrorDI}
                Content={createSuccessDI ? "Successfully Reading Using Document Intelligence ,Verify All The Details Are Correct " : "Failed To Read Using Document Intelligence, Please Fill The Information Manually"} />
            <CustomDialog
                open={openDialogs}
                onClose={handleDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Uploaded succesfully" : errorMessage !== '' ? errorMessage : "Failed"} />
            <CustomDialog
                open={openConfirmForm}
                onClose={handleConfirmDialogClose}
                success={createConfirmSuccess}
                error={createConfirmError}
                Content={createConfirmSuccess && !dcuploaded ? "Uploaded succesfully" : dcuploaded && createConfirmSuccess ? confirmResult : dcuploaded && createConfirmError ? confirmResult : "failed"} />
        </>
    );
}

export default UploadSlip;