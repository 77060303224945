/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 5-Apr-2024
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : For Delivery Slip and PO comparison
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../purchaseOrder/getPODetails.scss';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { isUserHasPermission, useHandleUnauthorized } from '../../hooks/UserSession';
import { getInvoiceByProjectIdAndInvoiceId } from '../../services/InvoiceServices';
import Header from '../Header';
import PaymentTypes from '../paymentTypes/PaymentTypes';
import { useState } from 'react';
import { getPOAndSlip, getPOItems, getVendorDetails } from '../../services/PoService';
import { formatFileName } from '../../utils/fileUtils';



const DeliveryslipDetails: React.FC<any> = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [poData, setPoData] = React.useState({} as any);
    const location = useLocation();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [show, setShow] = React.useState(false);
    const [openmatrec, setOpenmatrec] = useState<boolean>(false);
    const { handleUnauthorized } = useHandleUnauthorized();
    // let data = location?.state?.rowData;
    // const getInvoice = async () => {
    //     let result = await getInvoiceByProjectIdAndInvoiceId(data?.projectId, data?.id, accessToken);
    //     setInvoiceData(result?.data);
    // }

    const rowData = location.state;
    // console.log(rowData.projectId, rowData.purchaseId)
    const getCompare = async () => {
        let result = await getPOAndSlip(rowData?.projectId, rowData?.purchaseId, rowData?.receiptId, accessToken);

        handleUnauthorized(result)
        setPoData(result.data);
    }

    React.useEffect(() => {

        getCompare();

    }, []);
    const [rows, setRows] = useState([
        {
            industry: 'Roofing',
            code: 'PO1234',
            description: 'End Caps',
            quantity: '1',
            unitCost: '$340',
            amount: '$340',
        },
    ]);

    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            {
                industry: '',
                code: '',
                description: '',
                quantity: '',
                unitCost: '',
                amount: '',
            },
        ]);
    };

    const handleChange = (e, index, field) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = e.target.value;
        setRows(updatedRows);
    };
    const clearRows = () => {
        setRows(rows.filter(row => Object.values(row).some(value => value !== '')));
    };

    const handlematrecOpen = () => {
        setOpenmatrec(true);
    };

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            //m="10px 0"
            >
                <Header title="Delivery Slip" subtitle="" />
            </Box>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={9}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined" sx={{ padding: '48px' }}>

                            <Grid container className='invoiceHeader' rowSpacing={0} columnSpacing={0}>
                                {/* <Grid item xs={12} sm={12} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Box>
                                        <Typography className='companyName'>MATERIO</Typography>
                                        <Typography paddingTop={2}>Office 149, 450 South Brand Brooklyn</Typography>
                                        <Typography>San Diego County, CA 91905, USA</Typography>
                                        <Typography>+1 (123) 456 7891, +44 (876) 543 2198</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className='companyName'>MATERIO</Typography>
                                        <Typography paddingTop={2}>Office 149, 450 South Brand Brooklyn</Typography>
                                        <Typography>San Diego County, CA 91905, USA</Typography>
                                        <Typography>+1 (123) 456 7891, +44 (876) 543 2198</Typography>
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    {/* <Typography className='companyName'>ABC Construction</Typography> */}
                                    <Typography><img src={poData?.tenantLogo} alt='' height={"80px"} width={"280px"} /></Typography>
                                    <Typography paddingTop={2}>{poData?.tenantAddress}</Typography>
                                    <Typography>{poData?.tenantCity}</Typography>
                                    <Typography>{poData?.state}</Typography>
                                    <Typography>{poData?.tenantPrimaryContact}, {poData?.tenantSecondaryContact}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className='alignEnd'>
                                    <Stack>
                                        <Typography className='invoiceNumber poHeader'>Delivery Slip Number #{rowData?.receiptId}</Typography>
                                        <Typography paddingTop={2}>Date Issued: {dayjs(poData?.createdAt).format("MMM DD, YYYY")}</Typography>
                                        <Typography>Date Due: {dayjs(poData?.deliveryDate).format("MMM DD, YYYY")}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} mb={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Delivery Slip To:</Typography>
                                    <Typography paddingTop={2}>{poData?.vendorName}</Typography>
                                    <Typography>{poData?.vendorAddress}</Typography>
                                    <Typography>{poData?.vendorMobileNo}</Typography>
                                    {/* <Typography>718-986-6062</Typography> */}
                                    <Typography >{poData?.vendorEmail}</Typography>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Shipping Address:</Typography>
                                    <Typography paddingTop={2}>{poData?.customerName}</Typography>
                                    <Typography>{poData?.vendorAddress}</Typography>
                                    <Typography>{poData?.vendorMobileNo}</Typography>
                                    <Typography>{poData?.vendorEmail}</Typography>
                                    {/* <Typography>asish@gms.com</Typography> */}
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={4}>
                                <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell>Industry</TableCell> */}
                                                <TableCell >Code</TableCell>
                                                <TableCell >Description</TableCell>
                                                <TableCell>Delivered Quantity</TableCell>
                                                <TableCell>Received Quantity</TableCell>
                                                {/* <TableCell >Unit Cost</TableCell>
                                                <TableCell >Amount</TableCell> */}
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {poData?.poItems?.map((row, index) => (
                                                <TableRow key={index}>
                                                    {/* <TableCell>
                                                    <input
                                                    type="text"
                                                    value={row.industry}
                                                    onChange={(e) => handleChange(e, index, 'industry')}
                                                    />
                                                    </TableCell> */}
                                                    <TableCell>
                                                        <TextField id="standard-basic" variant="standard"
                                                            type="text"
                                                            value={row.itemCode}
                                                        //onChange={(e) => handleChange(e, index, 'code')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField id="standard-basic" variant="standard"
                                                            type="text"
                                                            value={row.description}
                                                        //onChange={(e) => handleChange(e, index, 'description')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField id="standard-basic" variant="standard"
                                                            type="text"
                                                            value={row.quantity}
                                                            sx={{
                                                                input: {
                                                                    color: (row.quantity === row.received_quantity ? 'green' : 'red'),
                                                                    fontWeight: 'bold'
                                                                }
                                                            }}

                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField id="standard-basic" variant="standard"
                                                            type="text"
                                                            value={row.received_quantity}
                                                            sx={{
                                                                input: {
                                                                    color: (row.quantity === row.received_quantity ? 'green' : 'red'),
                                                                    fontWeight: 'bold'
                                                                }
                                                            }}
                                                        //onChange={(e) => handleChange(e, index, 'received_quantity')}
                                                        />
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        <TextField id="standard-basic" variant="standard"
                                                            type="text"
                                                            value={row.rate}
                                                        //onChange={(e) => handleChange(e, index, 'unitCost')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField id="standard-basic" variant="standard"
                                                            type="text"
                                                            value={row.cost}
                                                        //onChange={(e) => handleChange(e, index, 'amount')}
                                                        />
                                                    </TableCell> */}
                                                 <TableCell>                                                    
                                                         {formatFileName(row?.itemStatus ?? '')}
                                                        {/* {row.quantity === row.received_quantity ?
                                                            <CheckCircleOutlinedIcon sx={{ fontSize: "25px", color: "green" }} /> :
                                                            <CancelOutlinedIcon sx={{ fontSize: "25px", color: "red" }} />
                                                        } */}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {/* <Button onClick={addRow}>Add Row</Button>
                                    <Button onClick={clearRows}>Clear Rows</Button> */}
                                </TableContainer>
                                
                            </Grid>
                            {/* <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12} sm={8}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Purchase Officer:</Typography>
                                        <Typography>&nbsp;&nbsp;{poData?.createdBy} </Typography>
                                    </Stack>
                                    <Typography>Thanks for your business</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader'>Subtotal:</Typography>
                                        <Typography className='subHeader'>Total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${poData?.purchaseAmount}</Typography>
                                        <Typography className='subHeader' textAlign={'right'}>${poData?.purchaseAmount}</Typography>
                                    </Box>
                                </Grid>
                            </Grid> */}
                            <Divider></Divider>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Note:&nbsp;&nbsp;</Typography>
                                        <Typography> It was a pleasure working with you and your team. We hope you will keep us in
                                            mind for future freelance projects. Thank You!</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        {/* <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained" onClick={handlematrecOpen} fullWidth sx={{ backgroundColor: '#56CA00' }}>
                                        <MonetizationOnOutlinedIcon sx={{ fontSize: '19px !important' }} />&nbsp;Proceed To Payment
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} paddingTop={2}>
                                    <Button variant="outlined" fullWidth>
                                        Edit PO
                                    </Button>
                                </Grid> 
                                <Grid item xs={12} sm={12} paddingTop={2}>
                                    <Button variant="outlined" fullWidth>
                                        Download
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} paddingTop={2}>
                                    <Stack direction={'row'} spacing={2}>
                                        <Button variant="outlined" fullWidth>
                                            Print
                                        </Button>
                                        <Button variant="outlined" fullWidth>
                                            Edit
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Card> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default DeliveryslipDetails;