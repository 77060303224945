/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 27-Dec-2024
* @author      : Kanimozhi
* @since       : 0.0.1
* Requirement# :
* Purpose      : Gantt Chart task view 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/ 
* Issue/WorkItem |   DATE        |   AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 3157          |  27/12/2024    |   Kanimozhi  |   hanged the layout and ui changes ,data bind implemented
* 3157          |  31/12/2024    |   Kanimozhi  |   Single and multi project data implemented
* 3157          |  08/01/2025    |   Kanimozhi  |   Flag implemented
* 3157          |  21/01/2025    |   Kanimozhi  |   Flag Text ,project name fileds added
* 3157          |  23/01/2025    |   Kanimozhi  |   Filed Name changed 
* 3436          |  03/02/2025    |   Kanimozhi  |   Status impelementation 
* 3623          |  08/02/2025    |   Kanimozhi  |   Loading implemented 
* 3657          |  27/02/20245   |   Kanimozhi  |   Removed error message 
* 3705          |  27/02/2025    |   Kanimozhi  |   Changed the myajax funciton  for edit and delete task  
* 
*/


import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

interface GanttChartComponentProps {
  onGanttDataUpdate: (data: any) => void;
  selected: any;
  status: any;
  projectId: any;
}

const GanttChartComponent: React.FC<GanttChartComponentProps> = ({ onGanttDataUpdate, selected, status, projectId }) => {
  console.log('selected', selected);

  const [ganttData, setGanttData] = useState<any[]>([]);
  const { accessToken } = useOidcAccessToken();
  const layoutUrl = status === 'single' ? "Layouts/GanttTreeDef.xml" : "Layouts/GanttDef.xml";
  const projectStatus = ['scheduled', 'active', 'new', 'complete'];
  useEffect(() => {
    const processTasks = async () => {
      const transformedTasks: any[] = [];
      let phaseIndex = 0;
      if (status === 'single') {
        if (selected && selected.length > 0) {
          for (const phase of selected) {
            for (let taskIndex = 0; taskIndex < phase.taskViewData.length; taskIndex++) {
              const task = phase.taskViewData[taskIndex];
              const resourceNames = task.resource?.map(res => res.name) || [];
              const assigneeNames = resourceNames.join(', ');
              if (!task.taskType || task.taskType === '') {
                continue;
              }
              const childTask = {
                id: task.taskId,
                TaskId: task.taskId,
                PrimaryTaskId: `Phase-${phaseIndex}`,
                Task: task.taskName,
                Start: task.startTime,
                End: task.endTime,
                Dependency: task.dependencies,
                Duration: task.estimatedHours,
                taskType: task.taskType,
                PrimaryTask: `Phase ${phaseIndex + 1}: ${phase.phase}`,
                GmsResource: assigneeNames,
                Status: task.status,
                ProjectStatus: projectStatus,
              };
              transformedTasks.push(childTask);
            }
            phaseIndex++;
          }
        }
      }

      else if (status === 'multi') {
        for (const project of selected) {
          let projectPhaseIndex = 0;
          if (!project.tasks || project.tasks.length === 0) {
            console.warn('No tasks found for project:', project);
          }
          for (let taskGroupIndex = 0; taskGroupIndex < project.tasks.length; taskGroupIndex++) {
            const taskGroup = project.tasks[taskGroupIndex];
            for (let taskIndex = 0; taskIndex < taskGroup.taskViewData.length; taskIndex++) {
              const task = taskGroup.taskViewData[taskIndex];
              if (!task.taskType || task.taskType === '') {
                continue;
              }
              const resourceNames = task.resource?.map(res => res.name) || [];
              const assigneeNames = resourceNames.join(', ');
              const childTask = {
                id: task.taskId,
                Project: ` ${project.jobNo}  ${project.ProjectName} `,
                TaskId: task.taskId,
                Task: task.taskName,
                Start: task.startTime,
                End: task.endTime,
                Dependency: task.dependencies,
                Duration: task.estimatedHours,
                taskType: task.taskType,
                PrimaryTask: `Phase ${projectPhaseIndex + 1}: ${task.phase} `,
                GmsResource: assigneeNames,
                FLAGS: task.endTime,
                FLAGSTEXT: task.taskName,
                GanttGanttFlagsType: '3',
                Status: task.status,
                Completed: '',
              };
              transformedTasks.push(childTask);
            }
            projectPhaseIndex++;
          }
        }
      }
      setGanttData(transformedTasks);
    };
    processTasks();
  }, [selected, status]);



  useEffect(() => {
    if (ganttData.length > 0 && window.StartTreeGrid) {
      window.StartTreeGrid();
      window.Grids.OnCustomAjax = (G: any, IO: any, data: any, func: any) => {
        MyAjax(IO, data);
      };
    } else {
      console.log('Gantt chart data is empty or StartTreeGrid is not defined');
    }
  }, [ganttData]);

  const MyAjax = (IO: any, data: string) => {
    try {
      console.log('MyAjax received data:', data, IO);
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');
      console.log('Parsed XML:', xmlDoc);
      const items = xmlDoc.getElementsByTagName('I') || [];
      console.log('Extracted Items:', items);
      const treeGridData = Array.from(items).map(item => {
        const obj: Record<string, string> = {};
        Array.from(item.attributes).forEach(attr => {
          obj[attr.name] = attr.value;
        });
        return obj;
      });
      console.log('Formatted Tree Grid Data:', treeGridData);
      setGanttData(treeGridData);
      onGanttDataUpdate(treeGridData);
    } catch (error) {
      console.error('Failed to parse data in MyAjax:', error);
    } finally {
      window.StartTreeGrid();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Box component="section" sx={{ p: 2 }}>
        <div className="ExampleBorder">
          <div className="ExampleMain" style={{ width: '100%' }} id="TreeGridMainTag">
            <bdo
              debug=""
              id="SampleGrid"
              layout_url={layoutUrl}
              data_data={JSON.stringify({ Body: [ganttData] })}
              upload_url={`http://localhost:8080`}
              style={{ width: '100%', height: '100%', }}
              upload_sync="0"
            />
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
};

export default GanttChartComponent;
