import ProjectList from '../../components/projectList/ProjectList';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { isUserHasPermission } from '../../hooks/UserSession';
import GPS from '../../components/gps/Gps';
import { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import CompletedTaskFeed from '../../components/completedTaskFeed/CompletedTaskFeed';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ProjectSearchAutocomplete from '../../components/gps/ProjectSearchAutocomplete';

const SalesProduction: React.FC = () => {
    useOidcAccessToken();
    const [searchQuery, setSearchQuery] = useState<any>('');
    const [projectId, setProjectId] = useState<any>();
    const [taskStatus, setTaskStatus] = useState<any>('');
    const handleProjectSelect = (project: any, searchQuery: any) => {
        // console.log("Selected Project:", project);
        setProjectId(project?.id)
        setSearchQuery(searchQuery)
    };

    const getStatus = (status: any) => {
        setTaskStatus(status)
    }
    // const [success, setSuccess] = React.useState(false);
    // const [error, setError] = React.useState(false);
    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }} mt={2}>
                <Grid item xs={9}>
                    <Grid item xs={12}><ProjectList /></Grid>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="column" spacing={2}>
                    {isUserHasPermission("GPS") && 
                        <><Grid item xs={12}>
                                <ProjectSearchAutocomplete onSelect={handleProjectSelect} status={taskStatus} />
                            </Grid><Grid item xs={12}><GPS projectId={projectId} searchQuery={searchQuery} throwStatus={getStatus} />
                                </Grid></>}
                        <Grid item xs={12} className='headerTable'>
                            <Stack direction="row" sx={{ marginBottom: '1vh' }}>
                                <InsertChartIcon />&nbsp; Completed Task Feed
                            </Stack>
                            <CompletedTaskFeed />

                        </Grid>
                    </Stack>
                </Grid>
            </Grid>

            {/* <Dialog open={success} >
            <DialogTitle className='success'>
            <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography>
                        </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                <DialogContent >
                    <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Bid has been added to your cart Successfully</DialogContentText>
                </DialogContent>
                <DialogActions style={{  justifyContent: "center" }}>
                    <Button variant='contained' className="successButton">Okay</Button>
                </DialogActions>
                </Box>
            </Dialog>

            <Dialog open={error} >
            <DialogTitle className='error'>
            <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                              Error
                            </Typography>
                        </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                <DialogContent >
                    <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Create Bid</DialogContentText>
                </DialogContent>
                <DialogActions style={{  justifyContent: "center" }}>
                    <Button variant='contained' sx={{backgroundColor:"#df3333ed"}}>Close</Button>
                </DialogActions>
                </Box>
                
            </Dialog>
 */}

        </>
    )
}

export default SalesProduction;