/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For showing Completed Task feed
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1496           |  25/3/24    |   Nandha  |  Complete taskfeed orderNo mismatch issue and toFixed method null problem solved
*
*/
import { Grid, CardContent, Card, Typography, Avatar } from "@mui/material";
import React, { useContext, useState } from "react";
import { Stack } from "@mui/system";
import './CompletedTaskFeed.scss'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getCompletedTaskByassignees, getTaskByCustomerIdAndStatus } from "../../services/TaskService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import dayjs from "dayjs";
import { TaskContext,useHandleUnauthorized,UserContext } from "../../hooks/UserSession";

const CompletedTaskFeed: React.FC = () => {
    const [tasks, setTasks] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [role, setRole] = useState('');
    const {  isTaskCompleted } = React.useContext(TaskContext);
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    const { handleUnauthorized } = useHandleUnauthorized();
    // console.log( isTaskCompleted);
    const fetchRoleInform = async () => {
        try {
            // const response = await getUserSession(accessToken);
            // console.log(response.role.name);
            setRole(userSessionDetails?.role?.name);
            if (userSessionDetails?.role.name === "customer") {
                fetchCustomerTask();
                setLoading(false);
            }
            else {
                fetchContractorTask();
                setLoading(false)
            } // Set loading to false when data is fetched
        } catch (error) {
            console.error('Error fetching user session:', error);
            setLoading(false); // Set loading to false in case of error
        }
    };
    const fetchCustomerTask = async () => {
        try {
            const response = await getTaskByCustomerIdAndStatus(accessTokenPayload.oid, 'completed', accessToken);
            handleUnauthorized(response);
            setTasks(response?.data)

        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const fetchContractorTask = async () => {
        try {
            const response = await getCompletedTaskByassignees(accessTokenPayload.oid, accessToken);
            handleUnauthorized(response);
            setTasks(response?.data)
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    React.useEffect(() => {
      
            fetchRoleInform();
            // console.log("button clicked :", isTaskCompleted);
        

    }, [isTaskCompleted]);
    return (
        <>
       <Card >
        <CardContent style={{ height: '500px', overflow: 'auto' }} >
            <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                <Grid item xs={12} md={12}>
                    {tasks?.length === 0 ? (<Grid justifyContent="center" className='notaskDisplay'> <Typography>No completed task </Typography></Grid>) :
                      tasks?.map((task: any, index: any) => {
                            return (<Card className="POCard mb-2">
                                <Grid item xs={12} className="taskNo"> Order No :&nbsp; #{task?.jobNo ||task?.
                                            projectDTO.jobNo ||task?.
                                            projectDTO?.orderNO
                                        }</Grid>
                                <CardContent className="forecastRatio">
                                    <Grid container alignItems="center">
                                        <Grid item xs={10}>
                                            <Typography>
                                                <Stack direction='row' className="taskName"> {task?.taskname}{task?.taskName}</Stack>
                                            
                                            </Typography>
                                            <Typography>{task?.description}</Typography>
                                        </Grid>
                                        <Grid item xs={2} container justifyContent="flex-end">
                                            <Avatar src="/path_to_your_avatar.jpg" alt="Avatar" />
                                        </Grid>
                                    </Grid>
                                    <Typography pt={1}>
                                        <Grid container alignItems="center">
                                            <Grid container justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={12}>
                                                    <Stack direction="row" alignItems="center" spacing={1} >
                                                        <AccessTimeIcon className="taskTimerIcon" />
                                                        <Typography className="taskTime" variant="h4">Completed on:</Typography>
                                                        <Typography >{dayjs(task?.endTime).format('MMM DD, YYYY')}</Typography>
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs={5}>
                                                    <Typography variant="body1">{dayjs(task?.endTime).format('DD MMM YYYY')}</Typography>
                                                </Grid> */}
                                            </Grid>
                                            <Grid item xs={12} container justifyContent="flex-Start" direction='row'>
                                                <Grid container justifyContent="flex-start" alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Stack direction="row"  alignItems="center" spacing={1} >
                                                            <AccessTimeIcon className="taskTimerIcon" />
                                                            <Typography className="taskTime" variant="h4">Time Spent:</Typography>
                                                            <Typography>{task?.timeSpent?.toFixed(2)}</Typography>                                                           
                                                        </Stack>
                                                    </Grid>
                                                    {/* <Grid item xs={5}>
                                                        <Typography variant="body1">{task?.timeSpent}</Typography>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                            )
                        })}
                </Grid>
            </Grid>
            </CardContent></Card>
        </>
    );
};
export default CompletedTaskFeed;