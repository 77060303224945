/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :For showing upcomingVerification for sales
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1492           |  22/3/24    |   Nandha  |  generate otp refresh issue
*
*
*/
import * as React from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow,
    Paper, IconButton,
    Grid, Stack, Button, Typography, TextField, InputAdornment
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext, useRef, useState } from 'react';
import dayjs from 'dayjs';
import './UpcomingVerification.scss'
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { getMyTask, getTaskByStatus, getVerificationTasks, getVerificationTasksCount } from '../../services/TaskService';
import OtpGeneratorDialog from '../otpGeneratorDialog/OtpGeneratorDialog';
import SearchIcon from "@mui/icons-material/Search";
import { TaskContext, useHandleUnauthorized, UserContext } from "../../hooks/UserSession";
import { getVerificationProjects, getVerificationProjectsCount } from '../../services/ProjectService';



// import { getBillList } from '../../../services/billService';
// import { getTasksByProjectAndStatus } from '../../services/TaskService';

interface Data {
    id: number;
    project: string;
    // trade: string;
    manufacture: string;
    state: number;
    orderNo: number;
    estimate: any;

}

type Order = 'asc' | 'desc';


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
    return (
        <>
            <TableHead >
                <TableRow className=' tableHead'>

                    <TableCell className='headLeftDetails' size='small'>Order No</TableCell>
                    <TableCell size='small'>Project Name</TableCell>
                    {/* <TableCell size='small'>Trade</TableCell> */}
                    {/* <TableCell size='small'>Start Date</TableCell>
                    <TableCell size='small'>End Date</TableCell> */}
                    <TableCell size='small'>Created By</TableCell>
                    <TableCell size='small'>Zip Code</TableCell>
                    <TableCell className='headLeftDetails' size='small'>Status</TableCell>
                    <TableCell size='small'>Action</TableCell>
                    {/* <TableCell className='tablerows ' size='small' style={{ borderRight: '0px !important' }}>Estimate</TableCell> */}
                </TableRow>
            </TableHead>
        </>
    );
}
interface UpcomingVerificationProps {
    status: string | undefined;
    taskName: string | undefined;
    bidId: any;
}
const UpcomingVerification: React.FC<UpcomingVerificationProps> = ({ status, taskName, bidId }) => {
    const [active, setActive] = React.useState<any>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('project');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [selectedValue, setSelectedValue] = React.useState<any>('');
    const componentData = useRef<any>();
    const navigate = useNavigate();
    const SALESPERSONVISITSTATUS = 'Salesperson Visit';
    const PROJECTACTIVESTATUS = 'Project Active';
    const SALESPERSONAPPROVALSTATUS = 'Salesperson Approval';
    const CONTRACTAPPROVAL = 'Contract Approval';
    const PROJECTREVIEWSTATUS = 'bidReview';
    const PROJECTAWAITINGESTIMATESTATUS = "awaitingEstimation";
    const PROJECTINACTIVE = 'Project Inactive';
    const CONTRACTUPLOAD = 'Contract Upload';
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [billList, setBillList] = React.useState([] as any[]);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [tasks, setTasks] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const confirmationState = "confirmation";
    const confirmationtask = 'OTP Generation For Confirmation';
    const { isTaskCompleted } = React.useContext(TaskContext);
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    const projectView = false;
    const [searchParams, setSearchParams] = React.useState<any>(null);

    // ////console.log(accessTokenPayload.oid)
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setModalOpen(true);
    };
    const { handleUnauthorized } = useHandleUnauthorized();

    const addContract = (project: any) => {
        navigate("/contract", {
            state: { project } // Pass projectDTO as state to the /contract route
        });
    }

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = billList.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - billList.length) : 0;

    const [age, setAge] = React.useState('');
    const [count, setCount] = useState(Number);

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };
    const fetchTask = async (value: any) => {
        try {
            const res = await getVerificationProjectsCount(accessToken);
            handleUnauthorized(res); // Handle unauthorized access

            setCount(res.data);
            //console.log( rowsPerPage, accessToken)
            const response = await getVerificationProjects(value, page, rowsPerPage, accessToken);
            handleUnauthorized(response); // Handle unauthorized access

            setTasks(response.data);
            return response.data || []; 

            // if (response && response.data) {
            //     ////console.log(response.data);

            //     // Filter the tasks to only keep the ones with the name "Tenant approved/Confirmed"
            //     const approvedConfirmedTasks = response.data.filter((task) => {
            //         const conditionResult = taskName === task.taskname && status === task.taskStatus;
            //         ////console.log(status, taskName, bidId);
            //         ////console.log(conditionResult);
            //         return task.taskType === confirmationState;
            //     });
            //     ////console.log(approvedConfirmedTasks);

            //     // Set the filtered tasks in state
            //     setTasks(approvedConfirmedTasks);

            // } else {
            //     // Handle the case where either response or response.data is null or undefined
            //     setError("Response data is missing");
            // }
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const validateTaxs = (paymentConfig: any) => {
        var config = JSON.parse(paymentConfig);
        return config.invoice !== null && config.po !== null;
    }

    // if(taskName!=undefined && bidId!=undefined && status=== 'active'){
    //     fetchTask();
    // }
    // const fetchData = async () => {
    //     try {
    //         const response = await getMyTask(null, null,accessToken);
    //         handleUnauthorized(response); // Handle unauthorized access

    //         setActive(response.data);
    //     } catch (error) {
    //         // Handle errors
    //         console.error('Error fetching task:', error);
    //     }
    // };

    const onProjectInformationPage = (rowData: any, status: any) => {
        let user = userSessionDetails;
        navigate("/soldproduction/projectinformation", { state: { rowData, user, status, projectView } });
    };
    React.useEffect(() => {
        componentData.current = {
            userUid: accessTokenPayload.oid,
            resourceType: "sales",
        };

        // fetchData();
        fetchTask(searchParams);
    }, [isTaskCompleted]);

    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        fetchTask(searchParams);

    }, [page, rowsPerPage]);
    // const handleChangePage =  async(event: unknown, newPage: number) => {
    //     const response = await getVerificationTasks(newPage, rowsPerPage, accessToken);
    //     setTasks(response.data);
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = async(event: React.ChangeEvent<HTMLInputElement>) => {
    //     const newRowsPerPage = parseInt(event.target.value);
    //     const newPage = Math.floor(page * rowsPerPage / newRowsPerPage);
    //     setRowsPerPage(newRowsPerPage);
    //     setPage(newPage);
    //     const response = await getVerificationTasks(newPage, newRowsPerPage, accessToken);
    //     setTasks(response.data);
    // };

    const handleTextChange = async (e: any) => {
        setValue(e.target.value);
        
        var value = e.target.value === "" ? null : e.target.value;
        
        if (e.target.value.length >= 3) {
            setSearchParams(value);
            const tasksList = await fetchTask(value);
            setCount(tasksList?.length || 0); 
        }
    
        if (e.target.value.length === 0) {
            setSearchParams(value);
            const tasksList = await fetchTask(value);
            setCount(tasksList?.length || 0); 
        }
    };
    
    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} mt={0} className='headerTable'>Upcoming Verification</Grid>
                {/* <Grid item xs={12}> */}
                <Box sx={{ width: '100%', }}>
                    <Paper className='cardBoxShadow' sx={{ width: '100%', mb: 2 }}>
                        <Grid item md={12} ml={2}>
                            <br />
                            <br />
                            <TextField
                                label="Search by status,customer"
                                size='small'
                                name="materialName"

                                value={value}
                                onChange={(e) => handleTextChange(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <br />
                            <br />
                        </Grid>
                        <TableContainer >
                            <Table
                                sx={{ minWidth: 750, border: 'none', }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={billList.length}
                                />
                                <TableBody>
                                    {tasks?.length === 0 ? (<TableRow>
                                        <TableCell colSpan={10}>
                                            <Typography align="center">No Upcoming task for sales</Typography>
                                        </TableCell>
                                    </TableRow>) :

                                        (<> {tasks?.map((task: any, index: any) => {
                                            const isItemSelected = isSelected(task.projectId);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, task.projectId)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}

                                                    key={task.projectId}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >

                                                    <TableCell
                                                        style={{ borderLeft: 'none !important' }}
                                                        component="th"
                                                        id={labelId}
                                                        size='small'
                                                        scope="row"
                                                        padding={"none"}
                                                        className='orderNoDetails'
                                                    >
                                                        <Stack className='orderNo '> #{task.jobNo}</Stack>
                                                    </TableCell>

                                                    <TableCell
                                                        // className='tableRows'
                                                        component="th"
                                                        id={labelId}
                                                        size='small'
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Stack  >
                                                            <div style={{ marginRight: '8px', marginBottom: '2px' }}>{/* Adjust margin as needed */}
                                                                {task.description}

                                                            </div>

                                                        </Stack>
                                                    </TableCell>
                                                    {/* <TableCell size='small'> {task?.trade}</TableCell> */}
                                                    {/* <TableCell size='small'>{dayjs(task?.jobStartdate).format('DD MMM YYYY')}</TableCell>
                                                    <TableCell size='small'>{dayjs(task?.jobEnddate).format('DD MMM YYYY')}</TableCell> */}
                                                    <TableCell size='small'>{task?.createdBy}</TableCell>
                                                    <TableCell size='small'>{task?.zipCode}</TableCell>
                                                    <TableCell className='orderNoDetails' size='small'>
                                                        {task?.projectStatus === SALESPERSONVISITSTATUS && (<Stack className='statusvisit'>{task?.projectStatus}</Stack>
                                                        )}
                                                        {task?.projectStatus === SALESPERSONAPPROVALSTATUS && (<Stack className='salesApproval'>{task?.projectStatus}</Stack>
                                                        )}
                                                        {task?.projectStatus === PROJECTACTIVESTATUS && (<Stack className=' activeProject'>{task?.projectStatus}</Stack>)}

                                                        {task?.projectStatus === CONTRACTAPPROVAL && (<Stack className=' contractStatus'>{task?.projectStatus}</Stack>
                                                        )}
                                                        {task?.projectStatus === CONTRACTUPLOAD && (<Stack className=' statusvisit'>{task?.projectStatus}</Stack>
                                                        )}
                                                        {task?.projectStatus === PROJECTINACTIVE && (<Stack className=' contractStatus'>{task?.projectStatus}</Stack>
                                                        )}
                                                        {task?.projectStatus === PROJECTREVIEWSTATUS && (<Stack className=' activeProject'>{(task?.projectStatus).split(/(?=[A-Z])/).join(" ").replace(/^./, task?.projectStatus[0].toUpperCase())}</Stack>)}
                                                        {task?.projectStatus === PROJECTAWAITINGESTIMATESTATUS && (<Stack className=' activeProject'>{(task?.projectStatus).split(/(?=[A-Z])/).join(" ").replace(/^./, task?.projectStatus[0].toUpperCase())}</Stack>)}

                                                    </TableCell>
                                                    <TableCell size='small'>

                                                        {/* <Grid item xs={12} ml={2}>
                                                            <Stack className='otpVerify'>
                                                                {task?.projectStatus !== CONTRACTAPPROVAL && active[0]?.
                                                                    taskType=== "confirmation" && (
                                                                        active[0]?.jobNo === task.jobNo ? (
                                                                            <OtpGeneratorDialog props={task} size />
                                                                        ) : (
                                                                            // <Button size='small' variant="outlined" disabled>
                                                                            //     Generate OTP
                                                                            // </Button>
                                                                            <></>
                                                                        )
                                                                    )}
                                                            </Stack>

                                                        </Grid> */}

                                                        <Grid item xs={12} ml={2}>
                                                            <Stack className='otpVerify'>
                                                                {(task?.projectStatus === SALESPERSONVISITSTATUS || task?.projectStatus === SALESPERSONAPPROVALSTATUS) && <OtpGeneratorDialog props={task} size />}
                                                            </Stack>

                                                        </Grid>

                                                        {/* 
                                                            {(task?.projectStatus === 'Salesperson Approval') && (
                                                                <Grid xs={12} ml={2} >

                                                                    {(
                                                                        <Stack className='otpVerify'>
                                                                            <OtpGeneratorDialog props={task} size />
                                                                        </Stack>
                                                                    )}
                                                                </Grid>
                                                            )} */}
                                                        <Stack className='otpVerify'>
                                                            {/* {(task?.projectStatus === CONTRACTAPPROVAL && validateTaxs(task?.paymentConfiguration)) && (<Button variant="contained" size="small" className='primarySolid' onClick={() => addContract(task)}>Contract</Button>)} */}
                                                            <Button onClick={() => onProjectInformationPage(task.projectId, "")}>View</Button>
                                                        </Stack>
                                                    </TableCell>

                                                    {/* <TableCell  size='small' >  <Stack className=' estimateCost '> ${project?.estimate}</Stack></TableCell> */}
                                                </TableRow>
                                            )
                                        })}</>)

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            sx={{ border: 'none', }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
                {/* </Grid> */}
            </Grid>
        </>

    );
}


export default UpcomingVerification;