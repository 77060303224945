import React, { useEffect, useState, useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { UserContext } from "../../hooks/UserSession";
import { searchProjects } from "../../services/ProjectService"; // Adjust import path
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getPreference } from "../../services/TaskService";
import ProjectSwitchWarning from "./ProjectSwitchWarning";

interface Project {
  id: number;
  jobNo: string;
  projectName: string;
}

interface ProjectSearchAutocompleteProps {
  onSelect: (project: Project | null, searchTerm: string) => void;
  status: string;
}

const ProjectSearchAutocomplete: React.FC<ProjectSearchAutocompleteProps> = ({ onSelect, status }) => {

  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [searchTerm, setSearchTerm] = useState("");
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectId, setProjectId] = useState('');
  const { userSessionDetails } = useContext(UserContext);
  const [value, setValue] = React.useState<any | null>(null);
  const [showSwitchWarning, setShowSwitchWarning] = useState<boolean>(false);

  const debouncedFetch = debounce(async (query: any) => {
    try {
      if(query !== "") {
        const results = await searchProjects(query, accessToken);
        setProjects(results.data ?? []);
      } else {
        const results = await searchProjects(null, accessToken);
        setProjects(results.data ?? []);
      }

    } catch (error) {
      console.error("Error fetching projects:", error);
      setProjects([]);
    }
  }, 500);

  useEffect(() => {
    fetchData();
    console.log(status)
  }, [status]);

  const fetchData = async () => {
    const res = await getPreference(accessToken);
    console.log(res.data)
    if (res.data !== null && res.data !== undefined) {
      let userData = JSON.parse(res.data.preferenceData)
      setProjectId(userData.projectId);
      let value = {
        id: userData.projectId,
        jobNo: userData.jobNo,
        projectName: userData.projectName
      }
      setValue(value)
      onSelect(value, userData?.search)
      // console.log(userData.search)
      // const results = await searchProjects(userData.search, accessToken)
      // console.log(results)
      // setProjects(results.data ?? []);
    } else {
      const results = await searchProjects(null, accessToken)
      console.log(results)
      setProjects(results.data ?? []);
    }
  }

  const handleChange = async (e: any, value: any) => {

    if (value === null) {
      console.log("handle")
      const results = await searchProjects(null, accessToken);
      console.log(results)
      setProjects(results.data ?? []);
    }
    if (value !== null && status !== 'active') {
      setValue({
        id: value.id,
        jobNo: value.jobNo,
        projectName: value.projectName
      })
      onSelect(value, searchTerm)
    }
    else if (status === 'active') {
      console.log("error")
      setShowSwitchWarning(true);
    }

  }

  const onInputChange = (e: any, inputvalue: any) => {
    setSearchTerm(inputvalue)
    if (inputvalue.length >= 3) {
      debouncedFetch(inputvalue);
    }
    else {
      setProjects([]);
    }
  }

  return (
    <>
      <Autocomplete
        // freeSolo
        size="small"
        sx={{ width: 300 }}
        value={value}
        noOptionsText={"No project Found"}
        options={projects}
        getOptionLabel={(option) => `${option?.jobNo || ""} - ${option?.projectName || ""}`}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            handleChange(event, newValue);
          }
          else {
            handleChange(event, newValue);
          }
        }}
        // onChange={(event, newValue) => onSelonInputChangeect(newValue, searchTerm)}
        onInputChange={(event, newValue) => onInputChange(event, newValue)}
        renderInput={(params) => <TextField {...params} label="Search My Projects" margin="normal" />}
        renderOption={(props, option, { inputValue }) => {
          const { ...optionProps } = props;
          console.log(props, option, inputValue)
          const text = `${option?.jobNo || ""} - ${option?.projectName || ""}`;
          const matches = match(text, inputValue, { insideWords: true });
          const parts = parse(text, matches);

          return (
            <li {...optionProps}>
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
      <ProjectSwitchWarning open={showSwitchWarning} onClose={() => setShowSwitchWarning(false)} />
    </>
  );
};

export default ProjectSearchAutocomplete;