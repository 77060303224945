/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 24-Jan-2024
* @author      : Vijay Kumar.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : For adding invoice 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import './getPODetails.scss'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import Header from '../Header';
import { useState } from 'react';
import { getPOItems, getVendorDetails, rejectPOItems, sendPoToVendor, updateTaxInfoAndTotal } from '../../services/PoService';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomDialog from '../../pages/Dialog';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { getPreviousPageDetails, getUserSession, useHandleUnauthorized } from '../../hooks/UserSession';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const PODetails: React.FC<any> = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [poData, setPoData] = React.useState({} as any);
    const location = useLocation();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [openSaveDialogs, setOpenSaveDialogs] = React.useState(false);
    const [createSaveSuccess, setSaveCreateSuccess] = React.useState(false);
    const [createSaveError, setSaveCreateError] = React.useState(false);
    const [openSendDialogs, setOpenSendDialogs] = React.useState(false);
    const [createSendSuccess, setSendCreateSuccess] = React.useState(false);
    const [createSendError, setSendCreateError] = React.useState(false);
    const [openTaxDialogs, setOpenTaxDialogs] = React.useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
    const [poItemId, setPoItemId] = React.useState<any>();

    const [createTaxSuccess, setTaxCreateSuccess] = React.useState(false);
    const [createTaxError, setTaxCreateError] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [taxShow, setTaxShow] = React.useState(false);
    const [user, setUser] = useState<any>();
    const [newRows, setNewRows] = React.useState<any>([]);
    const navigate = useNavigate();
    const poStatus = "Pending Approval";
    // let data = location?.state?.rowData;
    // const getInvoice = async () => {
    //     let result = await getInvoiceByProjectIdAndInvoiceId(data?.projectId, data?.id, accessToken);
    //     setInvoiceData(result?.data);
    // }
    let projectView = location?.state?.projectView;
    const { handleUnauthorized } = useHandleUnauthorized();

    const rowData = location.state;
    console.log(rowData)
    const getPOItem = async () => {

        let result = await getPOItems(rowData?.purchaseId, rowData?.projectId, accessToken);
        handleUnauthorized(result);
        console.log(result.data)
        let finalData = result.data;
        var add = JSON.parse(finalData?.customerAddress);
        finalData['customerAddress'] = add.address
        if (finalData?.status === "Draft" && finalData?.taxInfos === null) {
            finalData['taxInfos'] = [{
                name: "",
                percentage: "",
                value: "",
            }]
            setPoData(finalData);
        }
        else {
            setPoData(finalData);
        }
        if (finalData?.status !== "Draft") {
            setTaxShow(true);
        }
        else {
            setTaxShow(false);
        }
    }
    const addRows = () => {
        const taxComponent = {
            name: "",
            percentage: "",
            value: "",
        };

        const updateTaxInfo = [
            // copy the current users state
            ...poData.taxInfos,
            // now you can add a new object to add to the array
            taxComponent,
        ];
        newRows.push(updateTaxInfo.indexOf(taxComponent));
        // update the state to the updatedUsers
        setPoData({ ...poData, taxInfos: updateTaxInfo });
    };
    React.useEffect(() => {

        getPOItem();
        (async () => {
            const response = await getUserSession(accessToken);
            setUser(response);
        })()
        // }

        // const handleCustomerAddressChange = (event: { target: { value: string } }) => {
        //     setCustomerAddress(event.target.value);
        // };
        // const [rows, setRows] = useState<any>([]);
        // React.useEffect(() => {
        //     // const fetchRows = async () => {
        //     //     const data = await getPoDetails();
        //     //    // console.log(data)
        //     //     setRows(data);
        //     // };
        //     const getVendorDetail = async () => {
        //         const data = await getVendorDetails();
        //        // console.log(data)
        //         setVendor(data);
        //     }
        //     // fetchRows();
        //     getVendorDetail();
    }, []);
    const [rows, setRows] = useState([
        {
            industry: 'Roofing',
            code: 'PO1234',
            description: 'End Caps',
            quantity: '1',
            unitCost: '$340',
            amount: '$340',
        },
    ]);

    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            {
                industry: '',
                code: '',
                description: '',
                quantity: '',
                unitCost: '',
                amount: '',
            },
        ]);
    };

    const handleChange = (e, index, field) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = e.target.value;
        setRows(updatedRows);
    };
    const clearRows = () => {
        setRows(rows.filter(row => Object.values(row).some(value => value !== '')));
    };
    const handleDelete = async (id) => {
        try {
            setPoItemId(id)
            setOpenDeleteConfirmation(true);
           
            // Show success message or update the table
        } catch (error) {
            console.error('Failed to delete row:', error);
            // Show error message to the user
        }
    };
    const onInputTaxNameChange = (e: any, index: any) => {
        let newArray = [...poData?.taxInfos];
        newArray[index][e.target.name] = e.target.value;
        setPoData({ ...poData, taxInfos: newArray });
    };
    const handleRemoveItem = (index: any) => {
        let newArray = [...poData?.taxInfos];
        newArray.splice(index, 1)
        let totalTaxAmount = 0
        newArray?.map((d: any) => {
            if (typeof (d.value) !== "string") {
                totalTaxAmount = totalTaxAmount + d.value;
            }
        })
        newRows.pop();
        let totalPoAmount = poData?.subTotal + totalTaxAmount;
        setPoData({ ...poData, taxInfos: newArray, purchaseAmount: totalPoAmount });
    }
    const onInputChange = (e: any, index: any, subTotal: any) => {
        if ((e.target.value) >= 0) {
            let taxValue = (e.target.value * subTotal) / 100;
            let newArray = [...poData?.taxInfos];
            newArray[index][e.target.name] = e.target.value;
            newArray[index]["value"] = taxValue;
            let totalTaxAmount = 0
            poData?.taxInfos?.map((d: any) => {
                if (typeof (d.value) !== "string") {
                    totalTaxAmount = totalTaxAmount + d.value;
                }
            })
            let totalPoAmount = subTotal + totalTaxAmount;
            setPoData({ ...poData, taxInfos: newArray, purchaseAmount: totalPoAmount });
        }
    };

    const validation = () => {
        let validated: any = true;
        if ((poData?.taxInfos)?.length === 0) {
            setOpenTaxDialogs(true);
            setTaxCreateError(true);
            validated = false;
        }
        poData?.taxInfos?.map(d => {
            if (d.name === "" || d.percentage === "") {
                setError(true);
                validated = false;
            }
        })
        return validated;
    }
    const handleCancelDeletePOItem = () => {
        setOpenDeleteConfirmation(false);
    }
    const handleConfirmDeletePOItem = async () => {
        console.log(poItemId)
        const res = await rejectPOItems(poItemId, accessToken); // Replace with actual delete logic
        console.log(res)
        if (res.data == true) {
            getPOItem();
            setOpenDeleteConfirmation(false);

        }
    }
    const handeUpdate = async () => {
        let dataSet = { ...poData };
        let d = JSON.stringify(dataSet?.taxInfos);
        dataSet.taxInfos = d;
        let validated = validation();
        setOpenSaveDialogs(true);
        if (validated === true) {
            let result = await updateTaxInfoAndTotal(dataSet, accessToken);
            if (result.status === 201 && result.data !== null) {
                setNewRows([]);
                setSaveCreateSuccess(true);
                getPOItem();
            }
            else {
                setSaveCreateError(true);
            }
        }
    }

    const sendPo = async () => {
        setOpenSendDialogs(true);
        let result = await sendPoToVendor(rowData?.projectId, rowData?.purchaseId, poStatus, accessToken);
        if (result.status === 201) {
            setSendCreateSuccess(true);
            getPOItem();
        }
        else {
            setSendCreateError(true);
        }
    }

    const handleBack = () => {
        const previousPageDetails = getPreviousPageDetails();
        if (previousPageDetails !== undefined) {
            navigate(previousPageDetails.path, { state: { rowData: rowData?.projectId, user, selectedTab: previousPageDetails.tabPosition, tabName: previousPageDetails.tabName, projectView: projectView } });
        }
        else {
            navigate("/soldproduction")
        }

    }

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            //m="10px 0"
            >
                <Header title="Purchase Order" subtitle="" />
            </Box>
            <CustomDialog
                open={openSaveDialogs}
                onClose={() => setOpenSaveDialogs(false)}
                success={createSaveSuccess}
                error={createSaveError}
                Content={createSaveSuccess ? "Purchase Order saved" : "Purchase Order Not saved"}
            />
            <CustomDialog
                open={openSendDialogs}
                onClose={() => setOpenSendDialogs(false)}
                success={createSendSuccess}
                error={createSendError}
                Content={createSendSuccess ? "PO sent to Vendor successfully" : "PO unsent to Vendor"}
            />
            <CustomDialog
                open={openTaxDialogs}
                onClose={() => setOpenTaxDialogs(false)}
                success={createTaxSuccess}
                error={createTaxError}
                Content={createTaxSuccess ? "" : "Tax Component Not Added"}
            />
                      
            <Dialog open={openDeleteConfirmation}
        >
           <DialogTitle className='warning'>
            <Box paddingLeft={8} paddingRight={8}>
                    <Typography textAlign={'center'}>
                        <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                       Warning
                    </Typography>
                </Box>
                </DialogTitle>
                <DialogContent><Box m={2}>Selected Item will be considered as undelivered.</Box></DialogContent>
             <Box paddingLeft={8} paddingRight={8}>
            <DialogActions style={{  justifyContent: "center" }}>
            <Button onClick={()=>handleCancelDeletePOItem()} variant='contained' sx={{backgroundColor:"#df3333ed"}}>Close</Button>
                <Button onClick={handleConfirmDeletePOItem}  variant='contained' color="primary">
                    Ok
                </Button>
            </DialogActions>
            </Box>
        </Dialog>


            <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={10}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined" sx={{ padding: '48px' }}>

                            <Grid container className='invoiceHeader' rowSpacing={0} columnSpacing={0}>
                                {/* <Grid item xs={12} sm={12} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Box>
                                        <Typography className='companyName'>MATERIO</Typography>
                                        <Typography paddingTop={2}>Office 149, 450 South Brand Brooklyn</Typography>
                                        <Typography>San Diego County, CA 91905, USA</Typography>
                                        <Typography>+1 (123) 456 7891, +44 (876) 543 2198</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className='companyName'>MATERIO</Typography>
                                        <Typography paddingTop={2}>Office 149, 450 South Brand Brooklyn</Typography>
                                        <Typography>San Diego County, CA 91905, USA</Typography>
                                        <Typography>+1 (123) 456 7891, +44 (876) 543 2198</Typography>
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    {/* <Typography className='companyName'>ABC Construction</Typography> */}
                                    <Typography><img src={poData?.tenantLogo} alt='' className="logo"
                                    /></Typography>
                                    <Typography paddingTop={2}>{poData?.tenantAddress}</Typography>
                                    <Typography>{poData?.tenantCity}</Typography>
                                    <Typography>{poData?.state}</Typography>
                                    <Typography>{poData?.tenantPrimaryContact}, {poData?.tenantSecondaryContact}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className='alignEnd '>
                                    <Stack>
                                        <Grid item xs={12} className='billingAddressContainer'>
                                            <Typography className='invoiceNumber poHeader'>Purchase Order #{poData?.id}</Typography>
                                            <Typography paddingTop={2}>Date Issued: {dayjs(poData?.createdAt).format("MMM DD, YYYY")}</Typography>
                                            <Typography>Date Due: {dayjs(poData?.deliveryDate).format("MMM DD, YYYY")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} className='billingAddressContainer'>
                                            <Typography className='subHeader'>Billing Address:</Typography>
                                            <Typography paddingTop={2}>{poData?.tenantAddress}</Typography>
                                            <Typography>{poData?.tenantCity}</Typography>
                                            <Typography>{poData?.state}</Typography>
                                            <Typography>{poData?.tenantPrimaryContact}, {poData?.tenantSecondaryContact}</Typography>
                                        </Grid>

                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} mb={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Purchase Order To:</Typography>
                                    <Typography paddingTop={2}>{poData?.vendorName}</Typography>
                                    <Typography>{poData?.vendorAddress}</Typography>
                                    <Typography>{poData?.vendorMobileNo}</Typography>
                                    {/* <Typography>718-986-6062</Typography> */}
                                    <Typography >{poData?.vendorEmail}</Typography>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Shipping Address:</Typography>
                                    <Typography paddingTop={2}>{poData?.customerName}</Typography>
                                    <Typography>{poData?.customerAddress}</Typography>
                                    <Typography>{poData?.customerMobileNo}</Typography>
                                    <Typography>{poData?.customerEmail}</Typography>
                                    {/* <Typography>asish@gms.com</Typography> */}
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={4}>
                                <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell>Industry</TableCell> */}
                                                <TableCell >Code</TableCell>
                                                <TableCell >Description</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell >Unit Cost</TableCell>
                                                <TableCell >Amount</TableCell>
                                                <TableCell >Item Status</TableCell>
                                                <TableCell >Item Received</TableCell>

                                                <TableCell >Action</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {poData?.poItems?.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        backgroundColor:
                                                            row?.itemStatus === 'Rejected' || row?.itemStatus === 'PartiallyRejected'
                                                                ? 'grey.200'
                                                                : 'inherit', // Use Material-UI's grey palette for background
                                                        pointerEvents:
                                                            row?.itemStatus === 'Rejected' || row?.itemStatus === 'PartiallyRejected'
                                                                ? 'none'
                                                                : 'auto', // Disable interactions if row is grayed out
                                                        opacity:
                                                            row?.itemStatus === 'Rejected' || row?.itemStatus === 'PartiallyRejected'
                                                                ? 0.5
                                                                : 1, // Dim the row if grayed out
                                                    }}
                                                >
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.costCode}
                                                            onChange={(e) => handleChange(e, index, 'code')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.description}
                                                            onChange={(e) => handleChange(e, index, 'description')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.quantity}
                                                            onChange={(e) => handleChange(e, index, 'quantity')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.rate}
                                                            onChange={(e) => handleChange(e, index, 'unitCost')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.cost}
                                                            onChange={(e) => handleChange(e, index, 'amount')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.itemStatus}
                                                            onChange={(e) => handleChange(e, index, 'itemStatus')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="standard-basic"
                                                            variant="standard"
                                                            type="text"
                                                            value={row?.received_quantity}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {poData?.status =='Approved' && row?.delivery_status !== 'Received' && (
                                                            <Box onClick={() => handleDelete(row.id)} sx={{ cursor: 'pointer' }}>
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={1}
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        color: 'error.main',
                                                                        '&:hover': { color: 'error.dark' },
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </Stack>
                                                            </Box>
                                                        )}
                                                    </TableCell>
                                                    {row.received_quantity &&   <TableCell>
                                                                                                            {/* {row?.itemStatus} */}
                                                                                                            {row.quantity === row.received_quantity ?
                                                                                                                <CheckCircleOutlinedIcon sx={{ fontSize: "25px", color: "green" }} /> :
                                                                                                                <CancelOutlinedIcon sx={{ fontSize: "25px", color: "red" }} />
                                                                                                            }
                                                                                                        </TableCell>}
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                    {/* <Button onClick={addRow}>Add Row</Button>
      <Button onClick={clearRows}>Clear Rows</Button> */}
                                </TableContainer>
                            </Grid>
                            {/* <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12} sm={8}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Purchase Officer:</Typography>
                                        <Typography>&nbsp;&nbsp;{poData?.createdBy} </Typography>
                                    </Stack>
                                    <Typography>Thanks for your business</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader'>Subtotal:</Typography>
                                       
                                        <Typography className='subHeader'>Total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${poData?.purchaseAmount}</Typography>
                                       
                                        <Typography className='subHeader' textAlign={'right'}>${poData?.purchaseAmount}</Typography>
                                    </Box>
                                </Grid>
                            </Grid> */}
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3}>
                                <Grid item xs={12} sm={8}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Purchase Officer:</Typography>
                                        <Typography>&nbsp;&nbsp;{poData?.createdBy} </Typography>
                                    </Stack>
                                    <Typography>Thanks for your business</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Subtotal:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${poData?.subTotal !== undefined ? (poData?.subTotal).toFixed(2) : null}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            {poData?.taxExemption === false ?
                                <Grid container rowSpacing={1} columnSpacing={1}>
                                    {poData?.taxInfos?.map((tax, index) => (
                                        <><Grid item xs={12} sm={6}>
                                        </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <TextField
                                                        label="Tax Name"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={tax?.name}
                                                        disabled={taxShow && !newRows.includes(index)}
                                                        error={tax?.name === '' && error}
                                                        name="name"
                                                        onChange={(e) => onInputTaxNameChange(e, index)}
                                                    />
                                                </Box>
                                            </Grid><Grid item xs={6} sm={2} className='alignEnd'>
                                                <Box>
                                                    <TextField
                                                        label="Percentage"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={tax?.percentage}
                                                        name="percentage"
                                                        type='number'
                                                        error={tax?.percentage === '' && error}
                                                        disabled={taxShow && !newRows.includes(index)}
                                                        onChange={(e) => onInputChange(e, index, poData?.subTotal)}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={2} className='alignEnd'>
                                                <Box>
                                                    <Stack direction={'row'} spacing={1}>
                                                        <TextField
                                                            label="Value"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            type='number'
                                                            value={tax?.value !== '' || tax?.value !== undefined || tax?.value !== "null" ? Number(tax.value).toFixed(2) :0}
                                                            name="value"
                                                            disabled
                                                        // onChange={(e) => onInputChange(e, index)}
                                                        />
                                                        {poData?.status === "Generated" && (poData?.taxInfos).length !== 0 && newRows.includes(index) ?
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <IconButton onClick={() => handleRemoveItem(index)}>
                                                                    <DeleteIcon fontSize='small'
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                            :
                                                            <></>
                                                        }
                                                    </Stack>
                                                </Box>
                                            </Grid>

                                        </>
                                    ))}
                                </Grid> :
                                <></>
                            }
                            {poData?.status === "Generated" && poData?.taxExemption === false ?
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={7}>
                                    </Grid>
                                    <Grid item xs={6} sm={2}>
                                    </Grid>
                                    <Grid item xs={6} sm={3} className='alignEnd'>
                                        <Box>
                                            <Stack direction={'row'}>
                                                <Button onClick={addRows}>Add Row</Button>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid> :
                                <></>
                            }
                            <Grid container rowSpacing={2} columnSpacing={2} paddingBottom={3} paddingTop={1}>
                                <Grid item xs={12} sm={8}>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${Number(poData?.purchaseAmount)?.toFixed(2)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Note:&nbsp;&nbsp;</Typography>
                                        <Typography> It was a pleasure working with you and your team. We hope you will keep us in
                                            mind for future freelance projects. Thank You!</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {poData?.status === "Generated" && poData?.taxExemption === false ?
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} className='alignEnd'>
                                        <Button className='sucessButton' variant="contained" size="medium" onClick={handeUpdate}>Save</Button>
                                    </Grid>
                                </Grid> :
                                <></>
                            }
                        </Card>
                    </Box>
                </Grid>
     
                <Grid item xs={12} sm={2}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                            <Grid container spacing={2} direction={'column'}>
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained" fullWidth onClick={handleBack} >
                                        <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '16px' }} />&nbsp;Go Back
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained" fullWidth onClick={sendPo} disabled={poData?.status === "Generated" ? false : true}>
                                        <SendOutlinedIcon sx={{ fontSize: '16px' }} />&nbsp;Send PO
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} paddingTop={2}>
                                    <Button variant="outlined" fullWidth>
                                        Edit PO
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} paddingTop={2}>
                                    <Button variant="outlined" fullWidth>
                                        Download
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} paddingTop={2}>
                                    <Stack direction={'row'} spacing={2}>
                                        <Button variant="outlined" fullWidth>
                                            Print
                                        </Button>
                                        <Button variant="outlined" fullWidth>
                                            Edit
                                        </Button>
                                    </Stack>
                                </Grid> */}



                            </Grid>
                        </Card>

                    </Box>
                </Grid>
            </Grid>
 
        </>
    );
}

export default PODetails;