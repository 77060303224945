/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |     AUTHOR   |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*     2265       | 17-06-2024 | Shiyam Kumar | Create a Service For project Reschedule and StateChangeHistory
*
*  2235           |  14/06/24   |   Vanitha    | sold this year bar chart implementation
*  2235           |  21/06/24   |   Vanitha    | Project Total Esitmate for Month  and completed project count display implementation
*  2235           |  25/06/24   |   Vanitha    | Closing Ration display service



*
*/
import { executeGetData, executePostData, executePutData } from './ApiBase';
import { UUID } from 'crypto';
import { ConfigUrl } from "../auth/OidcConfig";


export const updateBidStatus = async (projectId: any, createdBy: string, status: string, accessToken: any): Promise<any> => {
  try {
    const url = `${ConfigUrl.baseURL}/projectConfirmations/otpGenerator/projectId/${projectId}/createdBy/${createdBy}/status/${status}`;
    return executePutData(url, null, accessToken);
  } catch (error) {
    // Handle error, e.g., log or throw a custom error
    throw new Error('Failed to update bid status');
  }
};

export const getProjectBycustomerUId = async (customerUId: UUID, page: any, size: any, accessToken: any,) => {
  
  const url = `${ConfigUrl.baseURL}/projects/customer/${customerUId}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};
export const getProjectCountBycustomerUId = async (customerUId: UUID,accessToken: any,) => {
  
  const url = `${ConfigUrl.baseURL}/projects/customer/${customerUId}/count`;
  return await executeGetData(url, accessToken);
};

// export const getProjectBycustomer = async (customerUId: UUID, accessToken: any) => {
//   const url = `${ConfigUrl.baseURL}/projects/customer/${customerUId}?page=0&size=5`;
//   return await executeGetData(url, accessToken);
// };

export const getProjectById = async (accessToken: any, projectId: any) => {
  const url = `${ConfigUrl.baseURL}/projects/${projectId}`;
  return await executeGetData(url, accessToken);
};

export const getProjectByJobno = async ( jobNo: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/jobno/${jobNo}`;
  return await executeGetData(url, accessToken);
};

export const getProjectCountByCustomerId = async (customerUid: any, status: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/customerProjects/${customerUid}/status/${status}/count`;
  return await executeGetData(url, accessToken);
};

export const getProjectByCustomerId = async (customerUid: any, status: any, page: any, size: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/customerProjects/${customerUid}/status/${status}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getProjectEstimateByYear = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getProjectEstimateByYear`;
  return await executeGetData(url, accessToken)
}

export const getMostLeadTrade = async (accessToken: string,startDate:any,endDate:any,status:any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/tradeSalesStatistics/from/${startDate}/to/${endDate}/status/${status}`;
  return await executeGetData(url, accessToken)
}

export const getEmployeeTerget = async (resourceUid:any, status:any, range:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/employeeTarget/resourceUid/${resourceUid}/status/${status}/range/${range}`;
  return await executeGetData(url, accessToken)
}

export const getMonhlySales = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/monthlySales`;
  return await executeGetData(url, accessToken)
}

export const getClosingRatio = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/closingRatio`;
  return await executeGetData(url, accessToken)
}


export const getCompletedProjectCount = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/count`;
  return await executeGetData(url, accessToken)
}

export const getProjectEstimateByMonth = async (accessToken: any,status:any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/projectEstimateByMonth/status/${status}`;
  return await executeGetData(url, accessToken)
}

export const getAllProjectByStatus = async (status: any, page: any, size: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/status/${status}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getProjectCountByStatus = async (status: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getMyProjectInfo/status/${status}/count`;
  return await executeGetData(url, accessToken);
};

export const getAllProjectCountByStatus = async (status: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getAllMyProjectInfo/status/${status}/count`;
  return await executeGetData(url, accessToken);
};


export const getProjectByStatus = async (status: any,  page: any, size: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getMyProjectInfo/status/${status}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getAssigneesProjectCountByStatus = async (assigneeUid:any, status: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getMyProjectInfo/assignee/${assigneeUid}/status/${status}/count`;
  return await executeGetData(url, accessToken);
};

export const getProjectByPOAndStatus = async (poUid: any, status: any,  page: any, size: any ,accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/projects/getMyProjectInfo/po/${poUid}/status/${status}?page=${page}&size=${size}`;
    return await executeGetData(url, accessToken);
};

export const getProjectCountByPOAndStatus = async (poUid: any, status: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/projects/getMyProjectInfo/po/${poUid}/status/${status}/count`;
    return await executeGetData(url, accessToken);
};
export const getAssigneesProjectByStatus = async (assigneeUid:any, status: any, page: any, size: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getMyProjectInfo/assignee/${assigneeUid}/status/${status}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getdisabledDatesByStartDateAndResourceType = async (startDate: any, resourceType: string, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/blockedDates/startDate/${startDate}/type/${resourceType}`;
  return await executeGetData(url, accessToken);
};

export const getdisabledDatesByResourceId = async (ResourceId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/resourceId/${ResourceId}`;
  return await executeGetData(url, accessToken);
};

export const addResourceSchedule = async (scheduleData: any, date: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/addResourceSchedule/date/${date}`;
  return await executePostData(url, scheduleData, accessToken);
};

export const checkout = async (bidId: number, startDate, sales, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/checkout/bidId/${bidId}/startDate/${startDate}/sales/${sales}`;
  return await executePostData(url, null, accessToken);
};

export const bookSalesPerson = async (bidData:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/bids/createSchedule`;
  return await executePostData(url, bidData, accessToken);
};

export const projectReschedule = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/reschedule/projectId/${projectId}`;
  return await executePostData(url,null,accessToken);
};

export const reschedule = async (projectId: any, scheduleType:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/reschedule/projectId/${projectId}/scheduleType/${scheduleType}`;
  return await executePostData(url,null,accessToken);
};

export const projectReschedulewithDate = async (projectId: any, startDate:any, scheduleType:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/reschedule/projectId/${projectId}/startDate/${startDate}/scheduleType/${scheduleType}`;
  return await executePostData(url,null,accessToken);
};

export const getProjectInformation = async (projectId: any, name:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getProjectInformation/projectId/${projectId}/name/${name}`;
  return await executeGetData(url, accessToken);
};
  
export const getSearch = async (customerUid: any,status: any,role:any,customer: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/customer/${customerUid}/By status/${status}/roles/${role}/search/customer/${customer}`;
  return await executeGetData(url, accessToken);
};

export const getSearchBySales = async (customerUid: any,status: any,customer: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/customer/${customerUid}/Bysales/${status}/search/customer/${customer}`;
  return await executeGetData(url, accessToken);
};
// export const getSearchByTrade = async (trade: any,accessToken: any) => {
//   const url = `${ConfigUrl.baseURL}/projects/search/customer/${trade}`;
//   return await executeGetData(url, accessToken);
// };


export const getSearchByCustomerAndStatus = async (customerUid: any,customer: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/customer/${customerUid}/search/status/customer/${customer}`;
  return await executeGetData(url, accessToken);
};

export const CheckInvoiceAvailability = async (projectId: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/checkInvoiceAvailability/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};

export const taskReschedule = async (projectId: any, taskId:any, wbs:any, startTime:any, endTime:any, accessToken: any) => {
  let url:any;
  if(endTime != null){
    url = `${ConfigUrl.baseURL}/projects/reschedule/projectId/${projectId}/taskId/${taskId}/wbs/${wbs}/startTime/${startTime}/endTime/${endTime}`;
  }else{
    url = `${ConfigUrl.baseURL}/projects/reschedule/projectId/${projectId}/taskId/${taskId}/wbs/${wbs}/startTime/${startTime}`;
  }
  return await executePostData(url,null,accessToken);
}
export const leaveBook = async ( resourceId: any,startDate:any,endDate, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/addResourceSchedule/resourceId/${resourceId}/startDate/${startDate}/endDate/${endDate}`;
  return await executePostData(url, null, accessToken);
}
export const getEmployeeLeaves = async ( type:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/employeeLeaves/type/${type}`;
  return await executeGetData(url, accessToken);
}


export const updateProjectByProjectId = async (projectId: any, name:any, reason:any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/updateProjectStatus/projectId/${projectId}/name/${name}/reason/${reason}/status/${status}`;
  return await executePutData(url, null, accessToken);
}

export const getRecentStateChangeHistory = async (projectId:any, accessToken:any) =>{
  const url = `${ConfigUrl.baseURL}/projects/getStatusChangeHistory/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getSalesPersonName = async (projectId:any, accessToken:any) =>{
  const url = `${ConfigUrl.baseURL}/projects/getSalesPersonName/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

// export const getReferenceProjects = async (trade:any, status:any, page:any, size:any, accessToken:any) => {
//   const url = `${ConfigUrl.baseURL}/projects/getReferenceProjects/trade/${trade}/status/${status}?page=${page}&size=${size}`;
//   return await executeGetData(url, accessToken);
// };
export const getReferenceProjects = async (projectId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/getReferenceProjects/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};
// export const getReferenceProjects = async (trade:any, accessToken:any) => {
//   const url = `${ConfigUrl.baseURL}/projects/getReferenceProjects/trade/${trade}`;
//   return await executeGetData(url, accessToken);
// };


export const getReferenceProjectsCount = async (trade:any, status:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/getReferenceProjectsCount/trade/${trade}/status/${status}`;
  return await executeGetData(url, accessToken);
};

export const updateTemplatesData = async (projectId: any,payload:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/answers/updateAnswerTemplateData/projectId/${projectId}`;
  return await executePutData(url, payload, accessToken);
}


export const getProjectTotalCost = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/projectTotalCost`;
  return await executeGetData(url, accessToken)
}

export const getSalesPersonPerformance= async (accessToken: any,from:any,to:any) => {
  const url = `${ConfigUrl.baseURL}/tradeStatistics/salesPersonPerformance/from/${from}/to/${to}`;
  return await executeGetData(url, accessToken)
}

export const getVerificationProjects = async (searchParams:any, page:any, size:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/MyVerificationProjects/search/${searchParams}?page=${page}&size=${size}`;
  return await executeGetData(url,accessToken);
};

export const getVerificationProjectsCount = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/getVerificationProjectCount`;
  return await executeGetData(url,accessToken);
};

export const getProjectTax = async (projectId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/getTax/projectId/${projectId}`;
  return await executeGetData(url,accessToken);
};

export const updateProjectTax = async (projectId:any, payload:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/updateTax/projectId/${projectId}`;
  return await executePutData(url, payload, accessToken);
};

export const updateProjectMarginAndOverHead = async (projectId:any, payload:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/updateMarginAndOverHead/projectId/${projectId}`;
  return await executePutData(url, payload, accessToken);
};

export const getAnswerData = async (projectId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/answers/getAnswerTemplateData/projectId/${projectId}`;
  return await executeGetData(url,accessToken);
}

export const getAssigneesProjectIdByStatus = async (assigneeUid:any, status: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getMyProjectId/assignee/${assigneeUid}/status/${status}`;
  return await executeGetData(url, accessToken);
};


export const completeProject = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/completeProject/projectId/${projectId}`;
  return await executePostData(url, null, accessToken);
}

export const getDuration = async (
  starttime: any, 
  endtime: any,
  accessToken: any
) => {
  const url = `${ConfigUrl.baseURL}/projects/duration/starttime/${starttime}/endtime/${endtime}`;
  return await executeGetData(url, accessToken);
};

export const getOrderNoByRole= async (role: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/getProjectOrderNo/role/${role}`;
  return await executeGetData(url, accessToken);
};

export const searchProjects= async (searchParams: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/projects/searchProjects/searchParams/${searchParams}`;
  return await executeGetData(url, accessToken);
};
