import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Grid, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { getSalesPersonPerformance } from '../../services/ProjectService';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useHandleUnauthorized } from '../../hooks/UserSession';
import { format } from "date-fns";

// import { makeStyles } from '@mui/styles';


const SalesTeamProficiencyRates = () => {

  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [performanceData, setPerformanceData] = useState<any>([]);
  const status = ["Salesperson Visit", "Contract Approval", "Salesperson Approval"];
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { handleUnauthorized } = useHandleUnauthorized();


  useEffect(() => {
    // SalesPersonPerformance();
    if (startDate && endDate) {
      SalesPersonPerformance();
    }
  }, [startDate, endDate]);

  const SalesPersonPerformance = async () => {
    const result = await getSalesPersonPerformance(accessToken, formatDate(startDate), formatDate(endDate));
    handleUnauthorized(result);
    setPerformanceData(result.data);
    // console.log('performance', result.data);

  }
  const formatDate = (date: Date | null): string => {
    if (!date) return ''; // Handle case where date is null
    return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  };

  const handleDateChange = (newValue: Date | null, type: 'startDate' | 'endDate') => {
    if (type === 'startDate') {
      setStartDate(newValue);
    } else if (type === 'endDate') {
      setEndDate(newValue);
    }
  };


  return (
    <>
      <div className='table-div'>
        <TableContainer className="salesTeam-tableContainer">
          <Box mt={2} sx={{ padding: '1vh' }}>
            <Stack direction={'row'} spacing={2}>
              <Grid item xs={6}>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => handleDateChange(newValue, 'startDate')}
                    format="MMM dd, yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => handleDateChange(newValue, 'endDate')}
                    format="MMM dd, yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>

              </Grid>
            </Stack>
          </Box>
          <Table className="salesTeam-table">
            <TableHead>

              <TableRow className="salesTeam-head">
                <TableCell>S.No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Bid sold</TableCell>
                <TableCell>Performance(%)</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="salesTeam-body">
              {performanceData?.map((data: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.lead.name}</TableCell>
                  <TableCell>{data.lead.completedCount}</TableCell>
                  <TableCell>{data.completionPercentage} %</TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default SalesTeamProficiencyRates;