import { useOidcAccessToken } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { getInvoiceTemplate, getInvoiceByProjectIdAndInvoiceId } from '../../services/InvoiceServices';
import { getPOItems, getPoTemplate } from '../../services/PoService';
import { Backdrop, Box, Button, Card, Grid } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { getPreviousPageDetails } from '../../hooks/UserSession';
import { useNavigate } from 'react-router-dom';
import gmslogo from "../../assets/logo.gif";
const PurchaseOrderPreview = () => {

//     const generateHTML = `
//                   <!DOCTYPE html>
// <html>
// <head>
//     <meta charset="utf-8" />
//     <title>Professional HTML Invoice Template</title>
//     <style>
//         .invoice-box {
//             margin: auto;
//             padding: 30px;
//             border: 1px solid #eee;
//             box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
//             font-size: 16px;
//             line-height: 24px;
//             font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
//             color: #555;
//         }

//         .invoice-box table {
//             width: 100%;
//             line-height: inherit;
//             text-align: left;
//         }

//         .invoice-box table .information {
//             background: #eee;
//         }

//         .invoice-box table td {
//             padding: 5px;
//             vertical-align: top;
//         }

//         .leftInfo {
//             text-align: right;
//         }

//         .highlight-text {
//             color: #0a0a0a;
//         }

//         .line-items-container th {
//             text-align: center;
//             color: #080808;
//             background: #ddd;
//             border-bottom: 2px solid #ddd;
//             padding: 5px 0 10px 0;
//             font-size: 0.90em;
//             text-transform: uppercase;
//         }

//         .invoice-items td,
//         .tax-items td {
//             text-align: right;
//         }

//         .line-items-container.has-bottom-border tbody tr:last-child td {
//             padding-bottom: 25px;
//         }

//         .line-items-container.has-bottom-border {
//             margin-bottom: 0;
//             margin-top: 15px;
//         }

//         .total {
//             text-align: right;
//         }

//         @media only screen and (max-width: 600px) {
//             .invoice-box table tr.top table td,
//             .invoice-box table tr.information table td {
//                 width: 100%;
//                 display: block;
//                 text-align: center;
//             }
//         }

//         .invoice-box.rtl {
//             direction: rtl;
//             font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
//         }

//         .invoice-box.rtl table {
//             text-align: right;
//         }
//     </style>
// </head>
// <body>
//     <div class="invoice-box">
//         <table cellpadding="0" cellspacing="0">
//             <tr class="top">
//                 <td colspan="1">
//                     <table>
//                         <tr>
//                             <td class="title">
//                                 <img src="{{logo}}" style="width: 100%; max-width: 100px" />
//                             </td>
//                             <td>
//                                 <strong>
//                                     {{tenantAddress}}<br />
//                                     {{tenantcity}}<br />
//                                     {{tenantstate}}<br />
//                                     {{tenantEmail}}<br />
//                                     {{tenantMobileNo}}
//                                 </strong>
//                             </td>
//                             <td class="leftInfo">
//                                 <strong>Purchase Order #{{purchaseno}}</strong><br />
//                                 Date Issued: <strong>{{createdAt}}</strong><br />
//                                 Date Due: <strong>{{deliveryDate}}</strong>
//                             </td>
//                         </tr>
//                     </table>
//                 </td>
//             </tr>
//             <tr class="information">
//                 <td colspan="2">
//                     <table>
//                         <tr>
//                             <td>
//                                 <strong class="highlight-text">Purchase Order To:</strong><br /><br />
//                                 {{vendorName}}<br />
//                                 {{vendorAddress}}<br />
//                                 {{vendorMobileNo}}<br />
//                                 {{vendorEmail}}
//                             </td>
//                             <td class="leftInfo">
//                                 <strong class="highlight-text">Shipping Address:</strong><br /><br />
//                                 {{customerName}}<br />
//                                 {{customerAddress}},{{customerCity}},{{customerState}}<br />
//                                 {{customerMobileNo}}<br />
//                                 {{customerEmail}}
//                             </td>
//                         </tr>
//                     </table>
//                 </td>
//             </tr>
//             <table class="line-items-container" style="margin-top: 10px;">
//                 <thead>
//                     <tr>
//                         <th>Code</th>
//                         <th>Description</th>
//                         <th>Quantity</th>
//                         <th>Unit Cost</th>
//                         <th>Amount</th>
//                     </tr>
//                 </thead>
//                 <tbody class="invoice-items">
//                     {{items}}
//                 </tbody>
//             </table>
//             <table class="line-items-container has-bottom-border">
//                 <thead>
//                     <tr>
//                         <th>Tax Name</th>
//                         <th>Percentage</th>
//                         <th>Value</th>
//                     </tr>
//                 </thead>
//                 <tbody class="tax-items">
//                     {{taxinfo}}
//                 </tbody>
//             </table>
//             <table style="width: 100%; text-align: right; border-collapse: collapse; border-bottom: 3px solid #ddd;">
//           <tr>
//                <td style="padding: 5px; font-weight: bold; font-size: 1.2em; color:rgb(48, 48, 48);">Subtotal:</td>
//              <td style="padding: 5px; text-align: right;">$ {{subtotal}}</td>
  
// </tr>
//                 <tr>
//                     <td style="padding: 5px; font-weight: bold; font-size: 1.2em; color: rgb(48, 48, 48);">Taxes:</td>
//                     <td style="padding: 5px;">$ {{totalTaxValue}}</td>
//                 </tr>
//                 <tr class="total">
//                     <td style="padding: 5px; font-weight: bold; font-size: 1.2em; border-top: 3px solid #ddd;">Total:</td>
//                     <td style="padding: 5px; font-weight: bold; font-size: 1.2em; border-top: 3px solid #ddd;">$ {{purchaseAmount}}</td>
//                 </tr>
//             </table>
//             <div class="row">
//                 <div style="margin-top: 25px;">
//                     <strong>Purchase Officer</strong>
//                 </div>
//                 <div style="font-size: 1.1em; margin-top: 15px;">
//                     Thank you for doing business with us!
//                 </div>
//                 <div style="font-size: 1.1em; margin-top: 10px;">
//                     It was a pleasure working with you. We hope you will keep us in mind for future projects. Thank you!
//                 </div>
//             </div>
//         </table>
//     </div>
// </body>
// </html>

//     `;


    const [poData, setPoData] = useState(null);
    const [htmlTemplate, setHtmlTemplate] = useState("");
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [isLoading, setIsLoading] = React.useState(false);
 const navigate = useNavigate();

    useEffect(() => {
        fetchPoData();
        setIsLoading(true);
    }, []);


    // const handleBack = () => {
    //         const previousPageDetails = getPreviousPageDetails();
    //         if (previousPageDetails !== undefined) {
    //             navigate(previousPageDetails.path, { state: { rowData: rowData?.projectId, user, selectedTab: previousPageDetails.tabPosition, tabName: previousPageDetails.tabName, projectView: projectView } });
    //         }
    //         else {
    //             navigate("/soldproduction")
    //         }
           
    //     }
        
    const fetchPoData = async () => {
             const res = await getPoTemplate(accessToken);
             console.log("html template",res)
           const  htmltemplatestring = res.data;
           if(htmltemplatestring !== ""){
            setIsLoading(false);
          }
        const result = await getPOItems("20", "1042", accessToken);
        console.log("po data", result?.data);
        let finalData = result?.data;
        setPoData(finalData);
        console.log("finalData", finalData);

        const getSafeValue = (value, defaultValue = '--') =>
            value !== null && value !== undefined ? value : defaultValue;

        const itemsRows = (finalData?.poItems || []).map(item => `
       <tr>
        
         <td>${getSafeValue(item?.costCode)}</td>
         <td>${getSafeValue(item?.description)}</td>
         <td>${getSafeValue(item?.quantity)}</td>
         <td>${getSafeValue(item?.rate)}</td>
         <td><strong>${getSafeValue(item?.cost)}</strong></td>
       </tr>
   `).join('');


        const totalTaxValue = (finalData?.taxInfos || []).reduce(
            (total, tax) => total + (tax?.value || 0),
            0
        );


        const taxinfo = (finalData?.taxInfos || []).map(tax => `
       <tr>
           <td>${getSafeValue(tax?.name)}</td>
           <td>${getSafeValue(tax?.percentage)}%</td>
           <td>${getSafeValue(tax?.value).toFixed(2)}</td>
       </tr>
   `).join('');


           const createdDate = dayjs(finalData?.createdAt).format("DD MMM YYYY");
           const deliveryDate = dayjs(finalData?.deliveryDate).format("DD MMM YYYY");
           var add = JSON.parse(finalData?.customerAddress);
           const purchaseAmount =Number(finalData?.purchaseAmount)?.toFixed(2);
           const subTotal = Number(finalData?.subTotal)?.toFixed(2);
        const boundHtml = htmltemplatestring
            .replace(/{{logo}}/g, getSafeValue(finalData?.tenantLogo))
            .replace(/{{tenantAddress}}/g, getSafeValue(finalData?.tenantAddress))
            .replace(/{{tenantcity}}/g, getSafeValue(finalData?.tenantCity))
            .replace(/{{tenantstate}}/g, getSafeValue(finalData?.tenantState))
            .replace(/{{tenantEmail}}/g, getSafeValue(finalData?.email))
            .replace(/{{tenantMobileNo}}/g, getSafeValue(finalData?.mobileNo))
            .replace(/{{purchaseno}}/g, getSafeValue(finalData?.id))
             .replace(/{{createdAt}}/g, getSafeValue(createdDate))
             .replace(/{{deliveryDate}}/g, getSafeValue(deliveryDate))
            .replace(/{{vendorName}}/g, getSafeValue(finalData?.vendorName))
            .replace(/{{vendorAddress}}/g, getSafeValue(finalData?.vendorAddress))
            .replace(/{{vendorMobileNo}}/g, getSafeValue(finalData?.vendorMobileNo))
            .replace(/{{vendorEmail}}/g, getSafeValue(finalData?.vendorEmail))
            .replace(/{{customerName}}/g, getSafeValue(finalData?.customerName))
            .replace(/{{customerAddress}}/g, getSafeValue(add?.address))
            .replace(/{{customerCity}}/g, getSafeValue(add?.city))
            .replace(/{{customerState}}/g, getSafeValue(add?.state))
            .replace(/{{customerMobileNo}}/g, getSafeValue(finalData?.customerMobileNo))
            .replace(/{{customerEmail}}/g, getSafeValue(finalData?.customerEmail))
            // .replace(/{{billingCity}}/g, getSafeValue(finalData?.billingCity))
            // .replace(/{{billingState}}/g, getSafeValue(finalData?.billingState))
            .replace(/{{invoiceNumber}}/g, getSafeValue(finalData?.invoiceNumber))
            .replace(/{{invoiceDate}}/g, getSafeValue(finalData?.invoiceDate))
            .replace(/{{subtotal}}/g, getSafeValue(subTotal))
            .replace(/{{purchaseAmount}}/g, getSafeValue(purchaseAmount))
            .replace(/{{items}}/g, getSafeValue(itemsRows))
            .replace(/{{taxinfo}}/g, getSafeValue(taxinfo))
            .replace(/{{totalTaxValue}}/g, getSafeValue(totalTaxValue))
            .replace(/{{purchaseName}}/g, getSafeValue(finalData?.createdBy));
        //  .replace(/{{invoicedAmount}}/g, getSafeValue(invoicedAmount));

        setHtmlTemplate(boundHtml);
    };

    return (
<>
<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
      </Backdrop>
    <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={5} md={9}>
        <div>
            <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
        </div>
        </Grid>
        <Grid item  xs={12} sm={7}  md={3}>
        <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                            <Grid container spacing={2} direction={'column'}>
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained" fullWidth  >
                                        <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '16px' }} />&nbsp;Go Back
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained" fullWidth >
                                        <SendOutlinedIcon sx={{ fontSize: '16px' }} />&nbsp;Send PO
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>

                    </Box>
        </Grid>
        </Grid>
        </>
    );
};

export default PurchaseOrderPreview
