import * as React from 'react';
import {
  Box, Card, Button, Typography, TextField, OutlinedInput, InputAdornment, Grid,
  InputLabel, Table, TableHead, TableBody, TableRow, TableCell, FormControl, Select, SelectChangeEvent,
  Stack, IconButton, MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import CustomDialog from './Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getProjectTax, updateProjectTax } from '../services/ProjectService';
import PaymentTermWarning from '../components/tenantconfiguration/financesettings/PaymentTermWarning';


const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Paymentsettings: React.FC<any> = (props) => {

  const [selectedOption, setSelectedOption] = React.useState<string>("invoice");
  const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<any>("");
  const [oldPaymentOption, setOldPaymentOption] = React.useState<any>("");

  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [tenantData, setTenantData] = React.useState<any>({});
  const [retainage, setRetainage] = React.useState<any>();
  const [data, setData] = React.useState<any>({});
  const [oldData, setOldData] = React.useState<any>();
  const [selectedData, setSelectedData] = React.useState<any>([]);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [noChange, setNoChange] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorTab, setErrorTab] = React.useState<any>([]);
  const [readOnly, setReadOnly] = React.useState<any>(false);
  const [paymentTerms, setPaymentTerms] = React.useState<any>();
  const [showContent, setShowContent] = React.useState(false);
  const [paymentTermWarning, setPaymentTermWarning] = React.useState<any>(false);
  const [paymentTermContent, setPaymentTermContent] = React.useState<any>('');

  const handleChange = (event: SelectChangeEvent, type: any) => {
    if (type === "tax") {
      setSelectedOption(event.target.value as string);
      setSelectedData(data[event.target.value]);
    }    
    setSelectedPaymentOption(event.target.value as string);
  };

  const addItem = (type: any) => {

    const payment = {
      name: "",
      value: "",
      description: ""
    };

    const updatePayments = [
      // copy the current users state
      ...paymentTerms,
      // now you can add a new object to add to the array
      payment,
    ];
    setData({ ...data, payments: updatePayments });
    // update the state to the updatedUsers
    setPaymentTerms(updatePayments);

  };

  const validation = () => {
    let validated: any = true;
    let updatedErrorTab: string[] = []; // Temporary variable to collect error keys
    // Validate basic fields first
    if (tenantData.retainage === '' && showContent === true) {
      validated = false;
    }
    if (tenantData.margin === '') {
      validated = false;
    }
    if (tenantData.overHead === '') {
      validated = false;
    }

    // Check payments data for validation
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      if (key === "payments") {
        value.forEach((d: any) => {
          console.log(d.name === "" || d.value === "")
          if (d.name === "" || d.value === "") {
            setError(true); // Set error flag
            validated = false;

            // Add "payments" to errorTab if not already included
            if (!updatedErrorTab.includes(key)) {
              updatedErrorTab.push(key);
            }
          } else {
            // Remove "payments" if the value is valid
            updatedErrorTab = updatedErrorTab.filter((item) => item !== key);
          }
        });
      }
    });
    if (selectedPaymentOption === "Part Payment") {
      var totalPercentage = 0;
      data.payments.map(payment => {
        totalPercentage = totalPercentage + Number(payment.value);
      }
      )
      if (data.retainage !== "null") {
        totalPercentage = totalPercentage + Number(data.retainage)
      }
      if (totalPercentage !== 100) {
        validated = false;
      }
      if (totalPercentage > 100) {
        setPaymentTermWarning(true);
        setPaymentTermContent("The payment term must not exceed 100 Percentage.");
      }
      else if (totalPercentage < 100) {
        setPaymentTermWarning(true);
        setPaymentTermContent("Payment terms cannot be less than 100 Percentage");
      }
    }


    // Update errorTab state with the collected error keys
    setErrorTab(updatedErrorTab);

    return validated;
  }

  //   const handleInputChange = (e: any, index: any) => {
  //     let newArray = [...data[selectedOption]];
  //     newArray[index][e.target.name] = e.target.value;
  //     if (error) {
  //       validation();
  //     }
  //     setData({ ...data, [selectedOption]: newArray });
  //     setSelectedData(newArray);
  //   };

  //   const handleToolRemoveItem = (index: any) => {
  //     let newArray = [...data[selectedOption]];
  //     newArray.splice(index, 1);
  //     setData({ ...data, [selectedOption]: newArray });
  //     Object.assign(data, {[selectedOption]: newArray});
  //     setSelectedData(newArray);
  //     console.log(data, selectedData, newArray);
  //   }

  const inputPaymentChange = (e: any, index: any) => {
    let newArray = [...data?.payments];
    newArray[index][e.target.name] = e.target.value;
    setData({ ...data, payments: newArray });
  };

  const handlePaymentRemoveItem = (index: any) => {
    let newArray = [...data.payments];
    newArray.splice(index, 1);
    setData({ ...data, payments: newArray });
    setPaymentTerms(newArray);
    Object.assign(data, { payments: newArray });
  }

  const handleSubmit = async () => {
    if (showContent === false) {
      data.retainage = "null";
    }
    console.log(selectedPaymentOption);
    if (selectedPaymentOption === "Full Payment") {
      let newArray = [{
        name: "",
        value: "",
        description: ""
      }];
      console.log(data?.retainage);
      newArray[0]["name"] = "Full Payment";
      if(data?.retainage === '' || data?.retainage === "null" || data?.retainage === undefined || data?.retainage === null){
        newArray[0]["value"] = "100";
      }
      else{
        newArray[0]["value"] = (100 - parseInt(data.retainage)).toString();
      }
      console.log(newArray);
      setData({ ...data, payments: newArray });
      data['payments'] = newArray;
      
      console.log(data);
    }
    let validated = validation();
    if (validated) {
      let hasChanges = anyChanges();
      if (hasChanges || oldData === undefined) {
        const result = await updateProjectTax(props.projectId, data, accessToken);
        if (result.status === 200 || result.status === 201) {
          setOpenDialogs(true);
          setSuccess(true);
          setReadOnly(true);
          fetchData();
        } else {
          setOpenDialogs(true);
          setSuccess(false);
        }
      } else {
        setNoChange(true);
      }
    }
  }

  let objectData: any = {};

  const anyChanges = () => {
    let hasChanges = false;
    if (data.retainage !== oldData.retainage) {
      hasChanges = true;
    }
    if (data.margin !== oldData.margin) {
      hasChanges = true;
    }
    if (data.overHead !== oldData.overHead) {
      hasChanges = true;
    }
    if (oldPaymentOption !== selectedPaymentOption) {
      hasChanges = true;

    }
    if (oldData !== undefined) {
      // Compare payments array
      if (oldData && data.payments && oldData.payments) {
        // Check if the length of the payments arrays is different
        if (oldData.payments.length !== data.payments.length) {
          hasChanges = true;
        }

        // Compare each payment object for changes in name and value
        if (!hasChanges) {
          data.payments.forEach((payment: any, index: number) => {
            // Compare each field (name and value) in the payment object
            if (oldData.payments[index]) {
              if (oldData.payments[index].name !== payment.name) {
                hasChanges = true;
              }
              if (oldData.payments[index].value !== payment.value) {
                hasChanges = true;
              }
            }
          });
        }
      }

    }
    return hasChanges;
  }


  const fetchData = async () => {
    const response = await getProjectTax(props.projectId, accessToken);
    if (response.data !== undefined && response.data !== null && response.data !== "") {
      response.data.payments = typeof response.data.payments === "string" ? JSON.parse(response.data.payments || '[]') : response.data.payments;
      setSelectedPaymentOption(response.data.payments.length > 1 ? "Part Payment" : "Full Payment");
      setOldPaymentOption(response.data.payments.length > 1 ? "Part Payment" : "Full Payment");
      console.log(response.data.retainage != null || response.data.retainage != undefined || response.data.retainage != '')
      if (response?.data?.retainage !== "null" && response?.data?.retainage?.length > 0) {
        setShowContent(true);
      } else {
        response.data['retainage'] = '';
        setShowContent(false);
      }
      setPaymentTerms(response.data.payments);
      //if (response?.data?.invoice !== undefined && response.data.invoice !== null && response?.data?.po !== undefined && response.data.po !== null) {
      setData(response.data);
      Object.assign(data, response.data);
      objectData = structuredClone(response.data);
      setOldData(objectData);
      setSelectedData(data[selectedOption]);
    }
  }

  const handleClose = () => {
    setOpenDialogs(false);
    setSuccess(false);
    setNoChange(false);
  }


  React.useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = () => {
    setShowContent((prev) => !prev);
  };

  const onInputChange = (e: any) => {
    setTenantData({ ...tenantData, [e.target.name]: e.target.value });
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handlePaymentWarning = () => {
    setPaymentTermWarning(false);
    setPaymentTermContent('');
  }

  return (


    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={8}>

          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6">
              Set Retainage
            </Typography>
            <div>
              <FormControlLabel
                control={<Android12Switch defaultChecked

                  checked={showContent} onChange={handleToggle} />}
                label=""
              />
              {showContent && (
                <div>
                  <FormControl variant="outlined" size='small'>
                    <InputLabel htmlFor="outlined-adornment-password">Set Retainage</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <div>
                            %
                          </div>
                        </InputAdornment>
                      }
                      name='retainage'
                      value={data.retainage}
                      onChange={(e) => onInputChange(e)}
                      inputProps={{ min: 1 }}
                      label="Set Retainage"
                      error={(data.retainage < 10 && showContent === true)}
                      type='number'
                    />
                  </FormControl>
                </div>
              )}
            </div>

          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6" sx={{ mx: 1, py: 1 }}>
              Margins
            </Typography>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Profit Pecentage
            </Typography>
            <div >
              <FormControl sx={{ m: 1, width: 300 }} variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment-password">Profit Pecentage</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name='margin'
                  value={data.margin !== undefined ? data.margin : ""}
                  error={data.margin === '' && error}
                  onChange={(e) => onInputChange(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <div>
                        %
                      </div>
                    </InputAdornment>
                  }
                  inputProps={{ min: 1 }}
                  label="Profit Pecentage"
                  type='number'
                />
              </FormControl>
            </div>
            <Typography variant="body1" sx={{ mx: 1, py: 1 }}>
              Overhead
            </Typography>
            <div >
              <FormControl sx={{ m: 1, width: 300 }} variant="outlined" size="small">
                <InputLabel htmlFor="outlined-adornment-password">Overhead</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  error={data.overHead === '' && error}
                  name="overHead"
                  value={data.overHead !== undefined ? data.overHead : ""}
                  onChange={(e) => onInputChange(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <div>
                        %
                      </div>
                    </InputAdornment>
                  }
                  inputProps={{ min: 1 }}

                  label="Overhead"
                  type='number'
                />
              </FormControl>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6">
              Payment Terms
            </Typography>
            <div>
              <FormControl fullWidth sx={{ mt: 3, mb: 3, width: 300 }} size='small'>
                <InputLabel id="demo-simple-select-label">Select Payment</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPaymentOption}
                  // value={tenantData.payments.length>=1? "Part Payment" : "Full Payment"  }
                  onChange={(e: any) => handleChange(e, "payment")}
                  label="Select Payment"
                  error={selectedPaymentOption === ''}
                >
                  <MenuItem value="Full Payment">Full Payment</MenuItem>
                  <MenuItem value="Part Payment">Part Payment</MenuItem>
                </Select>
              </FormControl>
              {selectedPaymentOption === "Part Payment" ?
                <div>
                  <Typography variant="h6">
                    <Table sx={{ border: '1px solid #000' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', backgroundColor: '#e3e1e1' }}
                          >
                            Payment Name
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000', backgroundColor: '#e3e1e1' }}
                          >
                            Percentage
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentTerms?.map((payment: any, index: number) => {
                          const isNameError = (!payment.name || payment.name == "") && error;
                          const isValueError = (!payment.value || payment.value == "") && error;

                          return (
                            <TableRow key={index}>
                              <TableCell sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                <TextField
                                  name="name"
                                  label="Payment Name"
                                  size="small"
                                  fullWidth
                                  value={payment?.name}
                                  onChange={(e) => inputPaymentChange(e, index)}
                                  error={isNameError}
                                  helperText={isNameError ? "Payment name is required" : ""}
                                  required
                                />
                              </TableCell>
                              <TableCell sx={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                <Stack direction="row">
                                  <FormControl variant="outlined" size="small" fullWidth error={isValueError}>
                                    <InputLabel>Set Percentage</InputLabel>
                                    <OutlinedInput
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <div>%</div>
                                        </InputAdornment>
                                      }
                                      fullWidth
                                      label="Set Percentage"

                                      name="value"
                                      value={payment?.value === undefined ? "" : payment?.value}
                                      onChange={(e) => inputPaymentChange(e, index)}
                                      inputProps={{ min: 1 }}
                                      type="number"
                                      required
                                    />
                                    {isValueError && (
                                      <FormHelperText>Percentage value is required</FormHelperText>
                                    )}
                                  </FormControl>
                                  <IconButton onClick={() => handlePaymentRemoveItem(index)}>
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}



                      </TableBody>
                    </Table>
                    <Button onClick={() => addItem("payment")}>Add Row</Button>
                  </Typography>
                </div>
                :
                <></>
              }
            </div>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, boxShadow: 0, border: '1px solid #ccc' }}>
            <Typography variant="h6">
              Tax Terms
            </Typography>
            <div>
              <FormControl fullWidth sx={{ mt: 3, mb: 3, width: 300 }} size='small' error={error && errorTab.length !== 0}>
                <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={(e:any) => handleChange(e, "tax")}
                  label="Select Option"
                >
                  <MenuItem value="invoice"><Typography color={errorTab.includes("invoice") ? 'error' : "inherit"}>Invoice</Typography></MenuItem>
                  <MenuItem value="po"><Typography color={errorTab.includes("po") ? 'error' : "inherit"}>PO</Typography></MenuItem>
                </Select>
              </FormControl>
              <div>
                <Typography variant="h6">
                  <Table sx={{ border: '1px solid lightgrey' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}
                        >
                          Tax Name
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}
                        >
                          Percentage
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedData?.map((tax: any, index: any) => {
                        return (
                          <TableRow>
                            <TableCell sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}>
                              <TextField
                                label="Tax Name"
                                id="outlined-size-small"
                                size="small"
                                fullWidth
                                value={tax?.name}
                                error={tax?.name === '' && error}
                                name="name"
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                            <TableCell sx={{ borderBottom: '1px solid lightgrey', borderRight: '1px solid lightgrey' }}>
                              <Stack direction={'row'}>
                                <FormControl variant="outlined" size='small' fullWidth error={(tax?.percentage === '' && error)}>
                                  <InputLabel htmlFor="outlined-adornment-password">Percentage</InputLabel>
                                  <OutlinedInput
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <div>
                                          %
                                        </div>
                                      </InputAdornment>
                                    }
                                    fullWidth
                                    name='percentage'
                                    value={tax?.percentage}
                                    onChange={(e) => handleInputChange(e, index)}
                                    inputProps={{ min: 1 }}
                                    label="percentage"
                                    type='number'
                                    required
                                  />
                                </FormControl>
                                {index !== 0 && <IconButton onClick={() => handleToolRemoveItem(index)}>
                                  <DeleteRoundedIcon />
                                </IconButton>}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2} // Adjust spacing between the buttons
                    sx={{ padding: 2 }} // Optional padding for the container
                  >
                    <Button onClick={() => addItem("tax")}>Add Row</Button>
                  </Stack>
                </Typography>
              </div>
            </div>
          </Card>
        </Grid> */}
        <Grid item xs={12} md={8}>
          <Grid container justifyContent="flex-end">
            <Box m={2} className="poFooter">
              <Button
                className="sucessButton"
                variant="contained"
                size="medium"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        open={openDialogs}
        onClose={handleClose}
        success={success}
        error={!success}
        Content={success ? " Updated Successfully" : " Error Couldnot Update "} />

      <PaymentTermWarning
        open={paymentTermWarning}
        onClose={handlePaymentWarning}
        Content={paymentTermContent}
      />

      <Dialog open={noChange} >
        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="NoChangeButton" onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box >
  )
};

export default Paymentsettings;
