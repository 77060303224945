
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select, TextField,
    FormControlLabel, Switch,
    Stack,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import './BillList.scss'
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getBillCount, getBillList, getBillLists } from '../../../services/BillService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import dayjs from 'dayjs';
import UploadSlip from '../../deliverySlip/UploadSlip';
import ReviewBill from '../ReviewBill';
import { UserContext } from '../../../hooks/UserSession';
import CustomDialog from '../../../pages/Dialog';
import { useState } from 'react';
import { jsonToCSV } from 'react-papaparse';
import { exportbill } from '../../../services/ExportService';



interface Data {
    id: number;
    billNo: string;
    billdate: string;
    billUploadeddate: string;
    category: string;
    billAmount: number;
    status: string;
}

function createData(
    id: number,
    billNo: string,
    billdate: string,
    billUploadeddate: string,
    category: string,
    billAmount: number,
    status: string,
): Data {
    return {
        id,
        billNo,
        billdate,
        billUploadeddate,
        category,
        billAmount,
        status,
    };
}
const rows = [
    createData(1, 'Cupcake', "09/07/2000", "09/07/2000", '', 3.7, "Due Later Due in 10 days"),
    createData(2, 'Donut', "09/07/2000", "09/07/2000", '', 25.0, "Due Later Due in 10 days"),
    createData(3, 'Eclair', "09/07/2000", "09/07/2000", '', 16.0, "Due Later Due in 10 days"),
    createData(4, 'Frozen yoghurt', "09/07/2000", "09/07/2000", '', 6.0, "Due Later Due in 10 days"),
    createData(5, 'Gingerbread', "09/07/2000", "09/07/2000", '', 16.0, "Due Later Due in 10 days"),
    createData(6, 'Honeycomb', "09/07/2000", "09/07/2000", '', 3.2, "Due Later Due in 10 days"),
    createData(7, 'Ice cream sandwich', "09/07/2000", "09/07/2000", '', 9.0, "Due Later Due in 10 days"),
    createData(8, 'Jelly Bean', "09/07/2000", "09/07/2000", '', 0.0, "Due Later Due in 10 days"),
    createData(9, 'KitKat', "09/07/2000", "09/07/2000", '', 26.0, "Due Later Due in 10 days"),
    createData(10, 'Lollipop', "09/07/2000", "09/07/2000", '', 0.2, "Due Later Due in 10 days"),
    createData(11, 'Marshmallow', "09/07/2000", "09/07/2000", '', 0, "Due Later Due in 10 days"),
    createData(12, 'Nougat', "09/07/2000", "09/07/2000", '', 19.0, "Due Later Due in 10 days"),
    createData(13, 'Oreo', "09/07/2000", "09/07/2000", '', 18.0, "Due Later Due in 10 days"),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'billNo',
        numeric: false,
        disablePadding: true,
        label: 'BILL No.',
    },
    {
        id: 'billdate',
        numeric: true,
        disablePadding: false,
        label: 'BILL DATE',
    },
    {
        id: 'billUploadeddate',
        numeric: true,
        disablePadding: false,
        label: 'BILL UPLOADED DATE',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'CATEGORY',
    },
    {
        id: 'billAmount',
        numeric: true,
        disablePadding: false,
        label: 'BILL AMOUNT',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'STATUS',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                //active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell >ACTION</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const BillList: React.FC<any> = (props) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('billNo');
    const [selected, setSelected] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const navigate = useNavigate();

    const [billList, setBillList] = React.useState([] as any[]);
    const { accessToken } = useOidcAccessToken();
    const [openBillList, setOpenBillList] = React.useState(false);
    const [openReviewBill, setOpenReviewBill] = React.useState(false);
    const { userSessionDetails, setUserDetails } = React.useContext(UserContext);
    const [role, setRole] = React.useState('');
    const [receiptId, setReceiptId] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [billView, setBillView] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [successExport, setSuccessExport] = React.useState(false);
    const [emptyData, setEmptyData] = useState<boolean>(false);

        const handleClose = () => {
            setOpenDialogs(false);
            setSuccessExport(false);
          }

    React.useEffect(() => {
        console.log(props)
        setRole(userSessionDetails?.role?.name);
        const fetData = async () => {
            const response = await getBillLists(props.props.id, "Bill", page, rowsPerPage, accessToken);
            console.log(response);
            setBillList(response.data);
            const res = await getBillCount(props.props.id, "Bill", accessToken);
            setCount(res.data)
        }
        fetData();
    }, [])

    const billPreviewView = (rowData: any) => {
        setBillView(true);
        setReceiptId(rowData);
        setOpenBillList(true);
    };

    const billFormView = (rowData: any) => {
        console.log(rowData)
        setReceiptId(rowData)
        setOpenReviewBill(true);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = billList.map((row) => row.receiptId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const billForm = (rowData: any) => {
        navigate("/bill", { state: { rowData } });

    };
    const billAddForm = () => {
        navigate("/bill");

    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const handleDownload = (data: any) => {
        const blob = new Blob([jsonToCSV(data)], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'bill.csv';
        link.click();
    };
                
    const exportbills = async () => {
        console.log(selected)
        const response = await exportbill(selected, accessToken);
        if (response.status === 200) {
            if (response?.data?.length !== 0 && response?.data !== null) {
            handleDownload(response.data)
            setOpenDialogs(true);
            setSuccessExport(true);
            } else {
            setEmptyData(true);
            setOpenDialogs(true);
            setSuccessExport(true);
            }
        } else {
            setOpenDialogs(true);
            setSuccessExport(false);
        }
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const SearchBar = () => (
        <div className='searchBar'>
            <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>

                <Grid item xs={2}>
                    <label><small>Status</small></label>
                    <Select
                        fullWidth
                        // value={status}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                    // onChange={handleChange}
                    >
                        <MenuItem value="All">
                            All
                        </MenuItem>
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="Un Paid">Un Paid</MenuItem>
                        {/* <MenuItem value="Due">Due</MenuItem>
                        <MenuItem value="Over Due">Over Due</MenuItem> */}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <>
            <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Box sx={{ width: '100%', }}>
                         <Grid item xs={12} className="alignEnd" sx={{ paddingBottom: '5px,10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={exportbills}
                                disabled={selected?.length === 0}
                            >
                                Export Bill
                            </Button>
                            </Grid>

                            <CustomDialog
                                        open={openDialogs}
                                        onClose={handleClose}
                                        success={successExport}
                                        error={!successExport}
                                        Content={successExport ? emptyData ? "There is no Data to Export" : "Exported Successfully" : "Error Couldn't Export"} />
                                       

                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            {/* <SearchBar /> */}
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected?.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={billList?.length}
                                    />
                                    {billList?.length === 0 ?

                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography align="center">No Bills</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        :
                                        <TableBody>
                                            {billList?.map((row, index) => {
                                                const isItemSelected = isSelected(row.receiptId);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow

                                                        hover
                                                        onClick={(event) => handleClick(event, row.receiptId)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.receiptId}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <CustomCheckbox
                                                                className='check'
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.billNo}
                                                        </TableCell>
                                                        <TableCell >{dayjs(row.billDate).format("MMM DD, YYYY")}</TableCell>
                                                        <TableCell >{dayjs(row.billUploadedDate).format("MMM DD, YYYY")}</TableCell>
                                                        <TableCell >{row.category}</TableCell>
                                                        <TableCell >${Number(row.total).toFixed(2)}</TableCell>
                                                        <TableCell >
                                                            {row.status === true ? "Paid" : "Unpaid"}
                                                        </TableCell>
                                                        <TableCell >
                                                            <Stack direction={'row'}>

                                                                <IconButton>
                                                                    <RemoveRedEyeOutlinedIcon onClick={() => billPreviewView(row?.receiptId)} />
                                                                </IconButton>
                                                                {(role === "purchaseOfficer" || role === "admin") && row?.billStatus === null ?
                                                                    <IconButton>
                                                                        <EditIcon onClick={() => billFormView(row.receiptId)} />
                                                                    </IconButton>
                                                                    : <></>}

                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                {openBillList && <UploadSlip open={openBillList} billDetails={receiptId} projectId={props?.props?.id} handleClose={() => setOpenBillList(false)} billView={billView} />}
                {openReviewBill && <ReviewBill open={openReviewBill} billDetails={receiptId} projectId={props?.props?.id} handleClose={() => setOpenReviewBill(false)} />}
            </Grid>
        </>

    );
}


export default BillList;